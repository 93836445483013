import axios from "axios";
import { AUTH_SERVER_URL } from "./config";

export const bUpsertInvoice = (invoiceData) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${AUTH_SERVER_URL}/invoice`,
        { invoiceData },
        { withCredentials: true }
      )
      .then(
        (response) => {
          return resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
  });
};

export const bGetInvoices = () => {
  return new Promise((resolve, reject) => {
    axios.get(`${AUTH_SERVER_URL}/invoice`, { withCredentials: true }).then(
      (response) => {
        return resolve(response.data);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

export const bGetInvoice = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${AUTH_SERVER_URL}/invoice/${id}`, { withCredentials: true })
      .then(
        (response) => {
          return resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
  });
};

export const bDeleteInvoice = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${AUTH_SERVER_URL}/invoice/${id}`, { withCredentials: true })
      .then(
        (response) => {
          return resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
  });
};

export const bGeneratePdf = (invoiceId) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${AUTH_SERVER_URL}/invoice/generate`,
        { invoiceId },
        { withCredentials: true }
      )
      .then(
        (response) => {
          return resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
  });
};

export const bDownloadPdf = (invoiceId) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${AUTH_SERVER_URL}/invoice/download`,
        { invoiceId },
        { withCredentials: true, responseType: "blob" }
      )
      .then(
        (response) => {
          return resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
  });
};
