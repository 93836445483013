const lightTheme = {
  name: "light",
  status: {
    colors: {
      success: "#10ac84",
      info: "#60a3bc",
      warning: "#e58e26",
      error: "#b71540",
    },
  },
  colors: {
    primary: "#444795",
    primaryDark: "#1f1f47",
    primaryHover: "#31336b",
    danger: "#e17055",
    dangerHover: "#ffbe76",
    info: "#f5f6fa",
    infoHover: "#dcdde1",
    gray: "#a5b1c2",
    lightGray: "#f5f6fa",
    darkGray: "#535c68",
    link: "#0984e3",
    white: "#ffffff",
    mainBackground: "#130f40",
    green: "#6ab04c",
    darkGreen: "#218c74",
    error: "#b71540",

    secondaryFg: "#000000",
    primaryFg: "#ffffff",
    themedButton: "#f5f6fa",
    price: "#2c3e50",
    discountedPrice: "#444795",
    secondaryHeading: "#aaaaaa",
    dashboardBg: "#ffffff",
    dashboardFg: "#000000",
    sectionBg: "#f5f6fa77",
    sectionBorder: "#f5f6fa",
    menuBg: "#f5f6fa",
    menuFg: "#000000",
    menuBorder: "#eeeeee",
    explanation: "#777777",
    inputBg: "#ffffff",
    inputBgDisabled: "#f5f6fa",
    inputFg: "#000000",
    inputBorder: "#cccccc",
    inputFocusBorder: "#777777",
    tableRowOdd: "#ecf0f1",
    tableRowEven: "#ffffff",
    tableRowHover: "#e4f1fe",
    tableBorder: "#cccccc",
    modalBg: "#ffffff",
    modalFg: "#000000",
    modalFooterBg: "#f5f6fa",
    buttonOutline: "#3b3b9877",

    // controlButton: "#dcdde1",
    // controlButtonIcon: "#30336b",
    // controlButtonText: "#ffffff",
    // controlButtonHover: "#10ac84",
    // controlButtonBorder: "#f5f6fa",
    // controlButtonDanger: "#ee5253",

    controlButton: "#30336b",
    controlButtonIcon: "#ffffff",
    controlButtonText: "#ffffff",
    controlButtonHover: "#10ac84",
    controlButtonBorder: "#130f40",
    controlButtonDanger: "#ee5253",
  },
  buttons: {
    borderRadiusSmall: "16px",
    borderRadiusLarge: "32px",
  },
  modal: {
    backgroundColor: "#686de0",
    background:
      "linear-gradient(70deg, #686de0 0%, #686de0 calc(20% + 1px), #4834d4 calc(20% + 1px), #4834d4 100%)",
    backgroundWithTransparency:
      "linear-gradient(70deg, #686de077 0%, #686de077 calc(20% + 1px), #4834d477 calc(20% + 1px), #4834d477 100%)",
    borderRadius: "16px",
  },
  shadows: {
    text: {
      small:
        "0 0 1px #000000, 0 0 1px #000000, 0 0 1px #000000, 0 0 1px #000000;",
      medium:
        "0 0 2px #000000, 0 0 2px #000000, 0 0 2px #000000, 0 0 2px #000000;",
    },
  },
  input: {
    borderRadius: "16px",
  },
  login: {
    // background: "linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);",
    background:
      "linear-gradient(157deg, rgba(72,52,212,1) 0%, rgba(104,109,224,1) 20%, rgba(245,246,250,1) 82%, rgba(255,234,167,1) 100%)",
  },
};

const darkTheme = {
  name: "dark",
  status: {
    colors: {
      success: "#10ac84",
      info: "#60a3bc",
      warning: "#e58e26",
      error: "#b71540",
    },
  },
  colors: {
    info: "#444795",
    primaryDark: "#1f1f47",
    infoHover: "#1f1f47",
    danger: "#e17055",
    dangerHover: "#ffbe76",
    primary: "#f5f6fa",
    primaryHover: "#dcdde1",
    gray: "#a5b1c2",
    lightGray: "#f5f6fa",
    darkGray: "#535c68",
    link: "#0984e3",
    white: "#ffffff",
    mainBackground: "#130f40",
    green: "#6ab04c",
    darkGreen: "#218c74",
    error: "#b71540",

    themedButton: "#31336b",
    secondaryFg: "#ffffff",
    primaryFg: "#000000",
    price: "#ffffff",
    discountedPrice: "#ffbe76",
    secondaryHeading: "#ffffff",
    dashboardBg: "#18222c",
    dashboardFg: "#ffffff",
    sectionBg: "#2C3E50",
    sectionBorder: "#2C3E50",
    sectionFg: "#ffffff",
    menuFg: "#ffffff",
    menuBg: "#2C3E50",
    menuBorder: "#2C3E50",
    explanation: "#f5f6fa",
    inputBg: "#34495E",
    inputBgDisabled: "#2C3E50",
    inputFg: "#ffffff",
    inputBorder: "#34495E",
    inputFocusBorder: "#40407a",
    tableRowOdd: "#304458",
    tableRowEven: "#3a526a",
    tableRowHover: "#130f40",
    tableBorder: "#2C3E50",
    modalFg: "#ffffff",
    modalFooterBg: "#34495E",
    modalBg: "#2C3E50",
    buttonOutline: "#f5f6fa77",

    controlButton: "#30336b",
    controlButtonIcon: "#ffffff",
    controlButtonText: "#ffffff",
    controlButtonHover: "#10ac84",
    controlButtonBorder: "#130f40",
    controlButtonDanger: "#ee5253",
  },
  buttons: {
    borderRadiusSmall: "16px",
    borderRadiusLarge: "32px",
  },
  modal: {
    backgroundColor: "#686de0",
    background:
      "linear-gradient(70deg, #686de0 0%, #686de0 calc(20% + 1px), #4834d4 calc(20% + 1px), #4834d4 100%)",
    backgroundWithTransparency:
      "linear-gradient(70deg, #686de077 0%, #686de077 calc(20% + 1px), #4834d477 calc(20% + 1px), #4834d477 100%)",
    borderRadius: "16px",
  },
  shadows: {
    text: {
      small:
        "0 0 1px #000000, 0 0 1px #000000, 0 0 1px #000000, 0 0 1px #000000;",
      medium:
        "0 0 2px #000000, 0 0 2px #000000, 0 0 2px #000000, 0 0 2px #000000;",
    },
  },
  input: {
    borderRadius: "16px",
  },
  login: {
    // background: "linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);",
    background:
      "linear-gradient(157deg, rgba(72,52,212,1) 0%, rgba(104,109,224,1) 20%, rgba(245,246,250,1) 82%, rgba(255,234,167,1) 100%)",
  },
};

export { lightTheme, darkTheme };
