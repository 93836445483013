import axios from "axios";
import { AUTH_SERVER_URL } from "./config";

export const bPostIdTokenToSessionLogin = (idToken, sessionToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${AUTH_SERVER_URL}/auth/sessionLogin`,
        {
          idToken,
          csrfToken: sessionToken,
        },
        { withCredentials: true }
      )
      .then(
        (response) => {
          return resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
  });
};

export const bCreateUser = (idToken, sessionToken, name, referralId) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${AUTH_SERVER_URL}/auth/signUp`,
        {
          idToken,
          csrfToken: sessionToken,
          name,
          referralId,
        },
        { withCredentials: true }
      )
      .then(
        (response) => {
          return resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
  });
};

export const bDeleteAccount = () => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${AUTH_SERVER_URL}/auth/deleteAccount`,
        {},
        { withCredentials: true }
      )
      .then(
        (response) => {
          return resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
  });
};

export const bGetPing = () => {
  return new Promise((resolve, reject) => {
    axios.get(`${AUTH_SERVER_URL}/auth/ping`, { withCredentials: true }).then(
      (response) => {
        return resolve(response.data);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

export const bGetSignOut = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${AUTH_SERVER_URL}/auth/sessionLogout`, { withCredentials: true })
      .then(
        (response) => {
          return resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
  });
};
