import React, { useMemo } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";
import {
  Button,
  DangerButton,
  noSelect,
  SecondaryButton,
} from "../../lib/components";
import "./Modal.scss";
import { media } from "../mixins";
import FocusTrap from "focus-trap-react";

const Overlay = styled.div`
  position: fixed;
  z-index: ${({ overlayIndex }) => (overlayIndex ? overlayIndex : 121)};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
`;

const ModalTitle = styled.div`
  font-size: 21px;
  padding: 20px 20px 10px 20px;
  ${noSelect};
`;

const ModalContainer = styled(motion.div)`
  position: fixed;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${({ theme }) => theme.colors.modalBg};
  color: ${({ theme }) => theme.colors.modalFg};
  border-radius: 8px;
  overflow: hidden;
  z-index: ${({ modalIndex }) => modalIndex || 122};
  min-width: 400px;
  max-width: 100%;
  //box-shadow: 0 0 5px #cccccc;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);

  ${media.belowMedium} {
    min-width: 90vw;
  }
`;

const ModalContent = styled.div`
  padding: 10px 20px 30px 20px;
  max-height: calc(100vh - 260px);
  font-size: 13px;
  ${noSelect};
  overflow-x: hidden;

  ${media.belowMedium} {
    max-height: calc(100vh - 320px);
  }
`;

const ModalFooter = styled.div`
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.modalFooterBg};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  button {
    margin-left: 5px;
    text-transform: lowercase;
  }
`;

const getModalWidth = (size) => {
  switch (size) {
    case "large":
      return "60vw";
    case "small":
    default:
      return "400px";
  }
};
const Modal = React.forwardRef(
  (
    {
      hideHeader,
      hideFooter,
      hideOverlay,
      title,
      content,
      onClose,
      onSave,
      onConfirm,
      cancelLabel,
      confirmLabel,
      onDecline,
      onCancel,
      danger,
      secondary,
      isVisible,
      children,
      size,
      style,
      overlayIndex = 121,
      modalIndex = 122,
    },
    ref
  ) => {
    const { t } = useTranslation();

    const footer = useMemo(() => {
      const buttons = [];

      if (onCancel) {
        buttons.push(
          <SecondaryButton key="cancel-button" onClick={onCancel}>
            {cancelLabel || t("cancel")}
          </SecondaryButton>
        );
      }

      if (onSave) {
        buttons.push(
          <Button key="save-button" onClick={onSave} className="modal-button">
            {t("save")}
          </Button>
        );
      }

      if (onConfirm) {
        buttons.push(
          danger ? (
            <DangerButton key="confirm-button" onClick={onConfirm}>
              {confirmLabel || t("yes")}
            </DangerButton>
          ) : (
            <Button key="confirm-button" onClick={onConfirm}>
              {confirmLabel || t("yes")}
            </Button>
          )
        );
      }

      if (onDecline) {
        buttons.push(
          <SecondaryButton key="decline-button" onClick={onDecline}>
            {t("no")}
          </SecondaryButton>
        );
      }

      if (onClose) {
        if (secondary) {
          buttons.push(
            <SecondaryButton key="close-button" onClick={onClose}>
              {t("close")}
            </SecondaryButton>
          );
        } else {
          buttons.push(
            <Button key="close-button" onClick={onClose}>
              {t("close")}
            </Button>
          );
        }
      }

      return buttons;
    }, [onCancel, onSave, onConfirm, onDecline, onClose, confirmLabel]);

    return (
      <>
        {isVisible && !hideOverlay && (
          <Overlay
            onClick={onClose ? onClose : onCancel}
            overlayIndex={overlayIndex}
          />
        )}
        <AnimatePresence>
          {isVisible && (
            <FocusTrap
              focusTrapOptions={{
                clickOutsideDeactivates: true,
                escapeDeactivates: !!(onCancel || onClose),
                onPostDeactivate: onCancel || onClose,
                fallbackFocus: "#fallback-focus",
              }}
            >
              <div>
                <div
                  id="fallback-focus"
                  tabIndex={-1}
                  style={{ width: 0, height: 0 }}
                />
                <ModalContainer
                  ref={ref}
                  className={"modal-container"}
                  initial={{ opacity: 0, top: 0 }}
                  animate={{ opacity: 1, top: "70px" }}
                  exit={{ opacity: 0, top: 0 }}
                  style={{ ...style }}
                  modalIndex={modalIndex}
                >
                  {!hideHeader && <ModalTitle tabIndex={0}>{title}</ModalTitle>}
                  <ModalContent>
                    <div>{content}</div>
                    <div>{children}</div>
                  </ModalContent>
                  {!hideFooter && <ModalFooter>{footer}</ModalFooter>}
                </ModalContainer>
              </div>
            </FocusTrap>
          )}
        </AnimatePresence>
      </>
    );
  }
);

export default Modal;
