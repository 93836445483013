import styled from "styled-components";
import { media } from "../mixins";

export const HideBelowMedium = styled.div`
  ${media.belowMedium} {
    display: none !important;
  }
`;

export const HideBelowLarge = styled.div`
  ${media.belowLarge} {
    display: none;
  }
`;

export const ShowBelowMedium = styled.div`
  display: none;
  ${media.belowMedium} {
    display: block !important;
  }
`;

export const ShowBelowLarge = styled.div`
  display: none;
  ${media.belowLarge} {
    display: block !important;
  }
`;
