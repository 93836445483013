import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Modal from "../../components/modal/Modal";
import { useTranslation } from "react-i18next";
import { useInvoiceOutlet } from "../../context/invoice";

const NavigatePrompt = (props) => {
  const { when, onOK, onCancel, title } = props;
  const { t } = useTranslation();

  const history = useHistory();
  const { setInvoice } = useInvoiceOutlet();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState("");

  useEffect(() => {
    if (when) {
      history.block((prompt) => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return "true";
      });
    } else {
      history.block(() => {});
    }

    return () => {
      history.block(() => {});
    };
  }, [history, when]);

  const handleOK = useCallback(async () => {
    if (onOK) {
      const canRoute = await Promise.resolve(onOK());
      setInvoice(null);
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
  }, [currentPath, history, onOK]);

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onCancel());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
    setShowPrompt(false);
  }, [currentPath, history, onCancel]);

  return showPrompt ? (
    <Modal
      title={title}
      isVisible={showPrompt}
      onConfirm={handleOK}
      onCancel={handleCancel}
      danger
    >
      {t("unsaved_changes_prompt")}
    </Modal>
  ) : null;
};

export default NavigatePrompt;
