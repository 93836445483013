import commonStrings from "./commonStrings";

const template = {
  locales: ["en", "ro"],
  variables: [
    {
      name: "accent",
      default: "#4a69bd",
      type: "color",
    },
    {
      name: "additionalInfo",
      title: "additional invoice info",
      default: "",
      type: "text",
    },
  ],
  strings: commonStrings,
  globalStyles: `
    * {
      font-family: 'PT Sans' !important;
    }
`,
  styles: {
    itemRow: {
      fontSize: 13,
    },
    itemRowCell: {
      paddingTop: 5,
      paddingBottom: 5,
    },
    itemDescriptionHeaderCell: {
      fontSize: 13,
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "#777777",
      textAlign: "left",
      borderBottomWidth: 2,
      borderBottomStyle: "solid",
      borderBottomColor: "{variables__accent}",
      widthWithTax: "40%",
      widthWithoutTax: "55%",
      whiteSpace: "nowrap",
      lineHeight: 13,
    },
    itemUnitCostHeaderCell: {
      fontSize: 13,
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "#777777",
      textAlign: "left",
      borderBottomWidth: 2,
      borderBottomStyle: "solid",
      borderBottomColor: "{variables__accent}",
      widthWithTax: "12%",
      widthWithoutTax: "15%",
      whiteSpace: "nowrap",
    },

    itemQtyHeaderCell: {
      fontSize: 13,
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "#777777",
      textAlign: "left",
      borderBottomWidth: 2,
      borderBottomStyle: "solid",
      borderBottomColor: "{variables__accent}",
      widthWithTax: "12%",
      widthWithoutTax: "15%",
      whiteSpace: "nowrap",
    },

    itemAmountHeaderCell: {
      fontSize: 13,
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "#777777",
      textAlign: "left",
      borderBottomWidth: 2,
      borderBottomStyle: "solid",
      borderBottomColor: "{variables__accent}",
      widthWithTax: "12%",
      widthWithoutTax: "15%",
      whiteSpace: "nowrap",
    },

    itemTaxHeaderCell: {
      fontSize: 13,
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "#777777",
      textAlign: "left",
      borderBottomWidth: 2,
      borderBottomStyle: "solid",
      borderBottomColor: "{variables__accent}",
      width: "12%",
      whiteSpace: "nowrap",
    },
    itemAmountWithTaxHeaderCell: {
      fontSize: 13,
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "#777777",
      textAlign: "left",
      borderBottomWidth: 2,
      borderBottomStyle: "solid",
      borderBottomColor: "{variables__accent}",
      width: "12%",
      whiteSpace: "nowrap",
    },
    mainContainer: {
      top: 0,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      paddingLeft: 60,
      textAlign: "center",
      fontSize: 48,
      fontWeight: "bold",
      letterSpacing: 4,
      color: "#ffffff",
      textTransform: "uppercase",
    },
    topRectangle: {
      position: "absolute",
      top: 0,
      width: "100%",
      left: 0,
      minHeight: 150,
      backgroundColor: "{variables__accent}",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingLeft: 40,
      paddingRight: 40,
    },
    invoiceSeriesWrapper: {
      position: "absolute",
      left: 400,
      top: 260,
    },
    invoiceNumberWrapper: {
      position: "absolute",
      left: 530,
      top: 260,
    },
    invoiceNumberTitle: {
      textTransform: "uppercase",
      color: "#777777",
      fontWeight: "bold",
      fontSize: 14,
    },
    invoiceNumber: {
      textTransform: "uppercase",
      color: "#000000",
      fontSize: 14,
    },
    dateOfIssueWrapper: {
      position: "absolute",
      left: 400,
      top: 210,
    },
    dateOfIssueTitle: {
      textTransform: "uppercase",
      color: "#777777",
      fontWeight: "bold",
      fontSize: 14,
    },
    dateOfIssue: {
      textTransform: "uppercase",
      color: "#000000",
      fontSize: 14,
    },
    dueDateWrapper: {
      position: "absolute",
      left: 530,
      top: 210,
    },
    dueDateTitle: {
      textTransform: "uppercase",
      color: "#777777",
      fontWeight: "bold",
      fontSize: 14,
    },
    dueDate: {
      textTransform: "uppercase",
      color: "#000000",
      fontSize: 14,
    },
    billedToWrapper: {
      position: "absolute",
      left: 76,
      top: 210,
      maxWidth: 280,
    },
    billedToTitle: {
      textTransform: "uppercase",
      color: "#777777",
      fontWeight: "bold",
      fontSize: 14,
    },
    billedTo: {
      textTransform: "uppercase",
      color: "#000000",
      fontSize: 14,
      maxWidth: 400,
    },
    sellerWrapper: {
      // position: "absolute",
      // right: 80,
      // top: 0,
      paddingTop: 20,
      paddingBottom: 20,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      maxWidth: 350,
      textAlign: "right",
    },
    sellerName: {
      textTransform: "uppercase",
      color: "#ffffff",
      fontWeight: "bold",
      fontSize: 14,
    },
    sellerInfo: {
      textTransform: "uppercase",
      color: "#ffffff",
      fontSize: 14,
      maxWidth: 400,
    },
    invoiceTotalWrapper: {
      position: "absolute",
      right: 100,
      top: 220,
    },
    invoiceTotalTitle: {
      fontSize: 13,
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "#777777",
      textAlign: "right",
    },
    invoiceTotalContent: {
      fontSize: 42,
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "{variables__accent}",
      textAlign: "right",
    },
    invoiceTotalInOtherCurrency: {
      fontSize: 16,
      display: "{variables__displayTotalInOtherCurrency}",
      textAlign: "right",
      displayCondition: (invoiceData) =>
        invoiceData?.config?.otherCurrency &&
        invoiceData?.config?.otherCurrency !== invoiceData?.config?.currency
          ? "block"
          : "none",
    },
    invoiceTotalInOtherCurrencySmall: {
      fontSize: 14,
      marginLeft: 95,
      display: "{variables__displayTotalInOtherCurrency}",
      displayCondition: (invoiceData) =>
        invoiceData?.config?.otherCurrency &&
        invoiceData?.config?.otherCurrency !== invoiceData?.config?.currency
          ? "block"
          : "none",
    },
    productTableWrapper: {
      position: "absolute",
      top: 400,
      left: 76,
      borderTopWidth: 2,
      borderTopStyle: "solid",
      borderTopColor: "{variables__accent}",
    },
    productTable: {
      width: 848,
      borderCollapse: "collapse",
    },
    bottomInfoWrapper: {
      marginTop: 50,
      position: "relative",
    },
    bottomLeftInfo: {
      position: "absolute",
      maxWidth: 400,
      fontSize: 14,
    },
    bottomLeftInfoTitle: {
      fontSize: 13,
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "#777777",
      textAlign: "left",
    },
    bottomLeftInfoContent: {
      fontSize: 42,
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "{variables__accent}",
      textAlign: "left",
    },
    bottomRightInfo: {
      position: "absolute",
      left: 500,
    },
    bottomRightInfoTitle: {
      fontSize: 13,
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "#777777",
      textAlign: "left",
      marginTop: 10,
    },
    bottomRightInfoTitleExchangeRate: {
      fontSize: 13,
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "#777777",
      textAlign: "left",
      marginTop: 10,
      display: "{variables__displayTotalInOtherCurrency}",
      displayCondition: (invoiceData) =>
        invoiceData?.config?.otherCurrency &&
        invoiceData?.config?.otherCurrency !== invoiceData?.config?.currency
          ? "block"
          : "none",
    },
    bottomRightInfoContent: {
      fontWeight: "normal",
      paddingLeft: 5,
    },
    footerLine: {
      position: "absolute",
      bottom: 20,
      left: 60,
      right: 60,
      height: 4,
      backgroundColor: "{variables__accent}",
    },
    additionalInfo: {
      whiteSpace: "pre-line",
      maxWidth: 50,
    },
    footer: {
      position: "absolute",
      bottom: 28,
      left: 60,
      right: 60,
      fontSize: 13,
      whiteSpace: "pre",
    },
  },
  template: `
  <div style="{styles__topRectangle}">
  <div style="{styles__mainContainer}">{invoiceTitle}</div>
  <div style="{styles__sellerWrapper}" id="seller">
    <div style="{styles__sellerName}">{sellerName}</div>
    <div style="{styles__sellerInfo}">
      <div>{sellerAddress}</div>
      <div>{sellerPhone}</div>
      <div style="white-space: pre-line">{sellerAdditionalInfo}</div>
    </div>
  </div>
</div>
<div style="{styles__invoiceSeriesWrapper}" id="invoice_series">
  <div style="{styles__invoiceNumberTitle}">{invoiceSeriesTitle}</div>
  <div style="{styles__invoiceNumber}">{invoiceSeries}</div>
</div>
<div style="{styles__invoiceNumberWrapper}" id="invoice_number">
  <div style="{styles__invoiceNumberTitle}">{invoiceNumberTitle}</div>
  <div style="{styles__invoiceNumber}">{invoiceNumber}</div>
</div>
<div style="{styles__dateOfIssueWrapper}" id="date_of_issue">
  <div style="{styles__dateOfIssueTitle}">{invoiceDateTitle}</div>
  <div style="{styles__dateOfIssue}">{invoiceDate}</div>
</div>
<div style="{styles__dueDateWrapper}" id="due_date">
  <div style="{styles__dueDateTitle}">{invoiceDueDateTitle}</div>
  <div style="{styles__dueDate}">{invoiceDueDate}</div>
</div>
<div style="{styles__billedToWrapper}" id="billed_to">
  <div style="{styles__billedToTitle}">{buyerName}</div>
  <div style="{styles__billedTo}">
    <div>{buyerAddress}</div>
    <div>{buyerPhone}</div>
    <div style="white-space: pre-line">{buyerAdditionalInfo}</div>
  </div>
</div>
<div style="{styles__invoiceTotalWrapper}">
  <div style="{styles__invoiceTotalTitle}">{invoiceTotalTitle}</div>
  <div style="{styles__invoiceTotalContent}">{invoiceTotal}</div>
  <div style="{styles__invoiceTotalInOtherCurrency}">
    {invoiceTotalInOtherCurrency}
  </div>
</div>
<div style="{styles__productTableWrapper}">
  <table style="{styles__productTable}" id="products_table">
    <thead>
      <tr>
        <th style="{styles__itemDescriptionHeaderCell}">{descriptionHeader}</th>
        <th style="{styles__itemUnitCostHeaderCell}">{unitCostHeader}</th>
        <th style="{styles__itemQtyHeaderCell}">{qtyHeader}</th>
        <th style="{styles__itemAmountHeaderCell}">{amountHeader}</th>
        <th style="{styles__itemTaxHeaderCell}" class="hide-for-zero-tax-rate">
          {taxHeader}
        </th>
        <th
          style="{styles__itemAmountWithTaxHeaderCell}"
          class="hide-for-zero-tax-rate"
        >
          {amountWTaxHeader}
        </th>
      </tr>
    </thead>
    <tbody>
      {invoiceItems}
    </tbody>
  </table>
  {addItemButton}
  <div style="{styles__bottomInfoWrapper}">
    <div style="{styles__bottomLeftInfo}">
      <span style="{styles__additionalInfo}">{variables__additionalInfo}</span>
    </div>
    <div style="{styles__bottomRightInfo}">
      <div style="{styles__bottomRightInfoTitle}">
        {invoiceSubtotalTitle}:
        <span style="{styles__bottomRightInfoContent}">
          {invoiceSubtotal}
        </span>
      </div>
      <div style="{styles__bottomRightInfoTitle}">
        {invoiceTaxRateTitle}:
        <span style="{styles__bottomRightInfoContent}">
          {invoiceTaxRate}%
        </span>
      </div>
      <div style="{styles__bottomRightInfoTitle}">
        {invoiceTaxTitle}:
        <span style="{styles__bottomRightInfoContent}"> {invoiceTax} </span>
      </div>
      <div style="{styles__bottomRightInfoTitle}">
        {invoiceTotalTitle}:
        <span style="{styles__bottomRightInfoContent}">
          {invoiceTotal}
          <div style="{styles__invoiceTotalInOtherCurrencySmall}">
            {invoiceTotalInOtherCurrency}
          </div>
        </span>
      </div>
      <div style="{styles__bottomRightInfoTitleExchangeRate}">
        {exchangeRateTitle}:
        <span style="{styles__bottomRightInfoContent}">
          {exchangeRateString}
        </span>
      </div>
    </div>
  </div>
</div>
<div style="{styles__footer}">{footer}</div>
<div style="{styles__footerLine}"></div>
`,
  itemRow:
    "<tr\n" +
    "style={styles__itemRow}\n" +
    ">\n" +
    "    <td style={styles__itemRowCell}>\n" +
    "    {itemName}\n" +
    "    </td>\n" +
    "    <td style={styles__itemRowCell}>\n" +
    "    {itemUnitCost}\n" +
    "    </td>\n" +
    "    <td style={styles__itemRowCell}>\n" +
    "    {itemQuantity}\n" +
    "    </td>\n" +
    "    <td style={styles__itemRowCell}>\n" +
    "    {itemTotalWithoutTax}\n" +
    "    </td>\n" +
    "    <td\n" +
    "        style={styles__itemRowCell}\n" +
    '        class="hide-for-zero-tax-rate"\n' +
    "    >\n" +
    "    {itemTax}\n" +
    "    </td>\n" +
    "    <td\n" +
    "        style={styles__itemRowCell}\n" +
    '        class="hide-for-zero-tax-rate"\n' +
    "    >\n" +
    "    {itemTotalWithTax}\n" +
    "    </td>\n" +
    "</tr>\n",
};

export default template;
