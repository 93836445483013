import styled from "styled-components";

const FooterWrapper = styled.div`
  width: 100%;
  background-color: #2c3e50;
  min-height: 20px;
`;

const Footer = () => {
  return <FooterWrapper />;
};

export default Footer;
