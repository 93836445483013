import axios from "axios";
import { AUTH_SERVER_URL } from "./config";

export const bGetInventoryCustomers = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${AUTH_SERVER_URL}/inventory/customer`, { withCredentials: true })
      .then(
        (response) => {
          return resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
  });
};

export const bDeleteInventoryCustomer = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${AUTH_SERVER_URL}/inventory/customer/${id}`, {
        withCredentials: true,
      })
      .then(
        (response) => {
          return resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
  });
};

export const bGetInventoryProducts = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${AUTH_SERVER_URL}/inventory/product`, { withCredentials: true })
      .then(
        (response) => {
          return resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
  });
};

export const bUpdateInventoryProduct = (id, data) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${AUTH_SERVER_URL}/inventory/product/${id}`, data, {
        withCredentials: true,
      })
      .then(
        (response) => {
          return resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
  });
};

export const bDeleteInventoryProduct = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${AUTH_SERVER_URL}/inventory/product/${id}`, {
        withCredentials: true,
      })
      .then(
        (response) => {
          return resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
  });
};
