import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useLocation } from "react-router-dom";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithPopup,
  updateProfile,
  getAdditionalUserInfo,
  sendPasswordResetEmail,
} from "firebase/auth";
import FocusTrap from "focus-trap-react";
import i18n from "i18next";
import { motion, AnimatePresence } from "framer-motion";
import { toast } from "react-toastify";
import styled from "styled-components";
import "./Login.scss";
import {
  bgKeyFrames,
  Button,
  Col,
  DangerButton,
  FlexCol,
  GoogleButton,
  GoogleButtonBackground,
  Input,
  Row,
  SecondaryButton,
  Separator,
  TextButton,
} from "../../lib/components";
import getFirebase, { auth, googleProvider } from "../../firebase";
import { getCookie } from "../../lib/utils";
import { bCreateUser, bPostIdTokenToSessionLogin } from "../../backend/auth";
import { bGetProfile } from "../../backend/profile";
import logoImg from "../../assets/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { setProfile } from "../../redux/profileSlice";
import { media } from "../../components/mixins";
import { Centered } from "../home/screens/subscription/Subscription";
import { AiOutlineClose } from "react-icons/ai";

const LoginTitle = styled.div`
  font-size: 17px;
`;

const Line = styled.div`
  height: 1px;
  background-color: #cccccc;
  border-bottom: 0;
  margin: 10px 0;
  width: 100%;
  padding: 0;
`;

const LoginLabel = styled.div`
  font-size: 13px;
  margin: 20px 0 10px 0;
`;

const CloseButton = styled.div`
  position: absolute;
  right: 4px;
  top: 4px;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: solid 1px #ffffff;
  line-height: 24px;

  &:hover {
    border: solid 1px #ffffff;
    background-color: rgba(0, 0, 0, 0.3);
    color: #ffffff;
    animation: ${bgKeyFrames} 0.1s ease-in;
  }
`;

const TermsLink = styled.a`
  color: ${({ theme }) => theme.colors.link};
  font-weight: bold;
  text-decoration: none;
`;

const LinkButton = styled.a`
  margin-left: 8px;
  text-decoration: underline;
  cursor: pointer;
  color: #777777;
  font-size: 13px;
`;

const LoginModal = styled(motion.div)`
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateY(-50%) translateX(-50%);
  background-color: ${({ theme }) => theme.colors.modalBg};
  color: ${({ theme }) => theme.colors.modalFg};
  border-radius: 8px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  z-index: 1011;
  width: 450px;
  padding: 20px;

  transition: height 0.3s cubic-bezier(0.03, 0.94, 0.92, 0.76);
  overflow: hidden;

  ${media.belowMedium} {
    transform: translateY(-380px) translateX(-50%);
    width: 98vw;
  }
`;

const ReferredInfo = styled.div`
  color: #ffffff;
  border-radius: 8px;
  border: solid 1px #2575fc77;
  padding: 8px;
  background-image: linear-gradient(to right, #6a11cb 0%, #2575fc 100%);
  font-size: 14px;
  text-align: center;
  position: absolute;
  top: 4px;
  left: 4px;
  right: 4px;
  z-index: 111111;
`;

let showWelcomeTimeout;

const Login = ({ onClose, isVisible, inEditor, user }) => {
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const referralId = searchParams.get("referralId");

  const [showWelcome, setShowWelcome] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [isNewUser, setIsNewUser] = useState(false);
  const [isSignUp, setIsSignUp] = useState(!!referralId);
  const [email, setEmail] = useState("");
  const theme = useSelector((state) => state.settings.theme);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    if (user) {
      showWelcomeTimeout = setTimeout(() => {
        setShowWelcome(true);
      }, 500);

      setTimeout(() => {
        onClose(false);
      }, 2500);
    } else {
      clearTimeout(showWelcomeTimeout);
      setShowWelcome(false);
      // setTermsAgreed(false);
    }
  }, [user]);

  const retrieveProfileWithToken = (user) => {
    return user.getIdToken().then((idToken) => {
      const csrfToken = getCookie("csrfToken");
      return bPostIdTokenToSessionLogin(idToken, csrfToken).then((result) => {
        bGetProfile().then((profile) => {
          if (profile !== "Invalid token.") {
            i18n.changeLanguage(profile.language);
            dispatch(setProfile(profile));
          } else {
            console.log("invalid token");
          }
        });
      });
    });
  };

  const logInWithGoogle = () => {
    signInWithPopup(auth, googleProvider)
      .then((result) => {
        return attemptSignUpFromFirebaseCredential(result, false);
      })
      .catch((err) => {
        console.log(err);
        toast(err.message);
      });
  };

  const attemptSignUpFromFirebaseCredential = (result, isSignUp, name) => {
    return result.user.getIdToken().then((idToken) => {
      const csrfToken = getCookie("csrfToken");

      const additionalUserInfo = getAdditionalUserInfo(result);
      if (additionalUserInfo.isNewUser) {
        setIsNewUser(true);
        return bCreateUser(idToken, csrfToken, name, referralId).then(() => {
          if (!isSignUp) {
            setTimeout(() => {
              toast(t("you_didnt_have_an_account"));
            }, 1500);
          }

          retrieveProfileWithToken(result.user);
        });
      }
      if (isSignUp) {
        setTimeout(() => {
          toast(t("you_already_have_an_account"));
        }, 1500);
      }

      bGetProfile().then((profile) => {
        if (profile !== "Invalid token.") {
          i18n.changeLanguage(profile.language);
          dispatch(setProfile(profile));
        } else {
          console.log("invalid token");
        }
      });
    });
  };

  const signUpWithGoogle = () => {
    signInWithPopup(auth, googleProvider)
      .then((result) => {
        return attemptSignUpFromFirebaseCredential(result, true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onLogin = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const object = {};
    data.forEach((value, key) => (object[key] = value));

    if (
      (!isSignUp && (!object.email.trim() || !object.password.trim())) ||
      (isSignUp &&
        (!object.email.trim() ||
          !object.password.trim() ||
          !object.passwordRepeat.trim() ||
          !object.name.trim()))
    ) {
      toast(
        "One of the required fields is missing. Please check your credentials and try again.",
        { type: "warning" }
      );
      return;
    }

    if (isSignUp && object.password !== object.passwordRepeat) {
      toast("The two passwords do not match.", { type: "warning" });
      return;
    }

    if (isSignUp) {
      createUserWithEmailAndPassword(auth, object.email, object.password)
        .then((result) => {
          updateProfile(auth.currentUser, { displayName: object.name }).then(
            () => {
              return attemptSignUpFromFirebaseCredential(
                result,
                true,
                object.name
              );
            }
          );
        })
        .catch((err) => {
          console.log(err);
          toast(t(err.code), { type: "warning" });
        });
    } else {
      signInWithEmailAndPassword(auth, object.email, object.password)
        .then((result) => {
          return attemptSignUpFromFirebaseCredential(result, false);
        })
        .catch((err) => {
          console.log(err);
          toast(t(err.code), { type: "warning" });
        });
    }
  };

  const forgotPassword = () => {
    if (!email || !email.trim()) {
      toast("Please enter a valid email in order to reset your password. 🔑", {
        type: "warning",
      });
      return;
    }

    sendPasswordResetEmail(auth, email)
      .then(() => {
        toast(
          "If a user with the provided email address was found, a password recovery email was sent. 🔑"
        );
      })
      .catch((e) => {
        if (e.code === "auth/invalid-email") {
          toast(
            "Please enter a valid email in order to reset your password. 🔑",
            {
              type: "warning",
            }
          );
          return;
        }
        if (e.code === "auth/user-not-found") {
          toast(
            "If a user with the provided email address was found, a password recovery email was sent. 🔑"
          );
          return;
        }
        toast(
          "An error has occurred. Please try again and, if the error persists, contact us.",
          { type: "warning" }
        );
      });
  };

  return (
    <>
      {isVisible && !!referralId && (
        <ReferredInfo>
          You were referred by a friend! When you subscribe to a premium
          subscription, you will receive a 20% discount on your first
          subscription payment.
        </ReferredInfo>
      )}

      <AnimatePresence>
        {isVisible && (
          <FocusTrap
            focusTrapOptions={{
              escapeDeactivates: false,
              fallbackFocus: "#fallback-focus",
            }}
          >
            <form key="login-form" onSubmit={onLogin}>
              <div
                id="fallback-focus"
                tabIndex={-1}
                style={{ width: 0, height: 0 }}
              />
              <>
                <motion.div
                  className={
                    inEditor ? "login-overlay-in-editor" : "login-overlay"
                  }
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  key={"key-overlay"}
                />
                <LoginModal
                  style={{
                    height: termsAgreed
                      ? isSignUp
                        ? "630px"
                        : "470px"
                      : "130px",
                  }}
                  initial={{ opacity: 0, top: -400 }}
                  animate={{ opacity: 1, top: 400 }}
                  exit={{ opacity: 0, top: -400 }}
                  key={"key-login-form"}
                >
                  <Row style={{ marginBottom: "20px", height: 32 }}>
                    <FlexCol flex={1} style={{ justifyContent: "flex-start" }}>
                      <LoginTitle>
                        {isSignUp ? t("title_sign_up") : t("title_log_in")}
                      </LoginTitle>
                    </FlexCol>
                    <FlexCol flex={0}>
                      {inEditor && (
                        <TextButton
                          dark={theme !== "dark"}
                          type="button"
                          onClick={onClose}
                        >
                          <AiOutlineClose />
                        </TextButton>
                      )}
                    </FlexCol>
                  </Row>
                  <AnimatePresence>
                    {!user && (
                      <div
                        key="accept-terms"
                        style={{ display: "flex", gap: 8 }}
                      >
                        <input
                          type="checkbox"
                          id="terms-checkbox"
                          checked={termsAgreed}
                          onChange={(e) => setTermsAgreed(e.target.checked)}
                        />
                        <label
                          htmlFor="terms-checkbox"
                          style={{ fontSize: "13px" }}
                        >
                          <Trans
                            i18nKey="terms_read"
                            components={{
                              linkTerms: (
                                <TermsLink
                                  href="https://www.visualinvoicer.com/terms-of-use"
                                  target="_blank"
                                />
                              ),
                              linkPrivacy: (
                                <TermsLink
                                  href="https://www.visualinvoicer.com/privacy-policy"
                                  target="_blank"
                                />
                              ),
                            }}
                          />
                        </label>
                      </div>
                    )}
                    <Separator />

                    {!user && termsAgreed && (
                      <motion.div
                        key="form-with-terms-agreed"
                        style={{ display: "flex", flexDirection: "column" }}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                      >
                        <AnimatePresence>
                          {isSignUp && (
                            <motion.div
                              key="name-input"
                              initial={{ height: 0 }}
                              animate={{ height: 82 }}
                              exit={{ height: 0 }}
                            >
                              <LoginLabel>{t("name")}</LoginLabel>
                              <Input type={"text"} name="name" />
                            </motion.div>
                          )}
                        </AnimatePresence>
                        <LoginLabel>{t("email")}</LoginLabel>
                        <Input
                          type={"email"}
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <LoginLabel>
                          {t("password")}
                          {!isSignUp && (
                            <LinkButton onClick={forgotPassword} tabIndex={0}>
                              forgot password?
                            </LinkButton>
                          )}
                        </LoginLabel>
                        <Input
                          type={"password"}
                          name="password"
                          {...(isSignUp
                            ? { autocomplete: "new-password" }
                            : {})}
                        />
                        <AnimatePresence>
                          {isSignUp && (
                            <motion.div
                              key="repeat-password-input"
                              initial={{ height: 0 }}
                              animate={{ height: 82 }}
                              exit={{ height: 0 }}
                            >
                              <LoginLabel>{t("repeat_password")}</LoginLabel>
                              <Input type={"password"} name="passwordRepeat" />
                            </motion.div>
                          )}
                        </AnimatePresence>
                        <Separator />
                        <Separator />

                        {!isSignUp && (
                          <Button type="submit" disabled={!termsAgreed}>
                            {t("log_in")}
                          </Button>
                        )}
                        {isSignUp && (
                          <Button type="submit" disabled={!termsAgreed}>
                            {t("sign_up")}
                          </Button>
                        )}

                        <Separator />
                        <Line />
                        {!isSignUp && (
                          <GoogleButton
                            type="button"
                            disabled={!termsAgreed}
                            dark
                            onClick={logInWithGoogle}
                          >
                            <GoogleButtonBackground />
                            {t("log_in_with_google")}
                          </GoogleButton>
                        )}
                        {isSignUp && (
                          <GoogleButton
                            type="button"
                            disabled={!termsAgreed}
                            dark
                            onClick={signUpWithGoogle}
                          >
                            <GoogleButtonBackground />
                            {t("sign_up_with_google")}
                          </GoogleButton>
                        )}
                        <Separator />
                        {!isSignUp && (
                          <TextButton
                            dark={theme !== "dark"}
                            underline
                            type="button"
                            disabled={!termsAgreed}
                            onClick={() => setIsSignUp(true)}
                          >
                            {t("sign_up_instead")}
                          </TextButton>
                        )}
                        {isSignUp && (
                          <TextButton
                            dark={theme !== "dark"}
                            underline
                            type="button"
                            disabled={!termsAgreed}
                            onClick={() => setIsSignUp(false)}
                          >
                            {t("log_in_instead")}
                          </TextButton>
                        )}
                      </motion.div>
                    )}
                  </AnimatePresence>
                  <AnimatePresence>
                    {showWelcome && (
                      <motion.div
                        key="welcome-text"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="welcome-text"
                      >
                        {isNewUser ? t("welcome") : t("welcome_back")},
                        <div className="welcome-text-name">
                          {user ? user.displayName : ""} 🙂
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </LoginModal>

                <motion.div
                  className="login-logo"
                  initial={{ bottom: -48 }}
                  animate={{ bottom: 12 }}
                  exit={{ bottom: -48 }}
                >
                  <img src={logoImg} />
                </motion.div>
              </>
            </form>
          </FocusTrap>
        )}
      </AnimatePresence>
    </>
  );
};

export default Login;
