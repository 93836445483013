import {
  CenteredRow,
  Col,
  CollapsibleCol,
  Input,
  RoundButton,
  ScreenWrapper,
  SecondaryButton,
  SecondaryHeading,
  Section,
  Separator,
} from "../../../lib/components";
import {
  Table,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableWrapper,
} from "../../editor/components";
import { BiLock } from "react-icons/bi";
import { IoCopyOutline } from "react-icons/io5";
import { MdDeleteOutline } from "react-icons/md";
import {
  getFormattedAmount,
  getFormattedDate,
  isObjectEmpty,
} from "../../../lib/utils";
import i18n from "i18next";
import Loader from "../../../components/loader/Loader";
import React, { useEffect, useMemo, useState } from "react";
import Modal from "../../../components/modal/Modal";
import {
  bDeleteInvoice,
  bGetInvoice,
  bGetInvoices,
} from "../../../backend/invoice";
import { useHistory, useLocation } from "react-router-dom";
import { useInvoiceOutlet } from "../../../context/invoice";
import { useTranslation } from "react-i18next";
import { bGetCustomers } from "../../../backend/customer";
import { useAuthState } from "react-firebase-hooks/auth";
import getFirebase, { auth } from "../../../firebase";
import styled from "styled-components";
import { useSelector } from "react-redux";
import featureFlags from "../../../lib/featureFlags";
import Tooltip from "../../../components/primitives/Tooltip";
import { toast } from "react-toastify";
import { media } from "../../../components/mixins";

const itemsPerPage = 10;

const PagingRow = styled(CenteredRow)`
  margin-top: 10px;
  padding-top: 10px;
  border-top: solid 1px ${({ theme }) => theme.colors.tableBorder};

  button {
    margin-left: 10px;
  }

  * {
    white-space: nowrap;
  }
`;

const Info = styled.div`
  font-size: 13px;
  color: #aaaaaa !important;
`;

const NoEntries = styled.div`
  padding: 8px;
  text-align: center;
  font-size: 14px;
`;

const SearchWrapper = styled.div`
  min-width: 200px;
  margin-left: 20px;
  flex: 0;

  input {
    border-radius: 16px;
    padding: 1px 8px;
  }
`;

const HeaderWithSearch = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: solid 1px ${({ theme }) => theme.colors.tableBorder};

  ${media.belowMedium} {
    flex-direction: column;
    ${SecondaryHeading} {
      font-size: 18px;
      text-align: left;
      width: 100%;
    }

    ${SearchWrapper} {
      flex: 1;
      margin-left: 0;
      width: 100%;
    }
  }
`;

const Invoices = () => {
  const [user, loading, error] = useAuthState(auth);

  const [invoices, setInvoices] = useState([]);

  const [allInvoices, setAllInvoices] = useState([]);

  const [invoicePage, setInvoicePage] = useState(1);

  const [invoicesLoaded, setInvoicesLoaded] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [invoiceFilter, setInvoiceFilter] = useState("");

  const [showDeleteInvoiceModal, setShowDeleteInvoiceModal] = useState(false);

  const history = useHistory();
  const { invoice, setInvoice } = useInvoiceOutlet();
  const { t } = useTranslation();
  const profile = useSelector((state) => state.profile.profile);

  const enrichData = () => {
    const enrichedInvoices = allInvoices.map((i) => ({
      ...i,
      translatedUpdatedAt: getFormattedDate(i.updatedAt, i18n.language),
      translatedDueDate: getFormattedDate(i.dueDate, i18n.language),
    }));

    if (enrichedInvoices.length) {
      setAllInvoices(enrichedInvoices);
    }
  };

  const retrieveData = () => {
    bGetInvoices()
      .then((i) => {
        setInvoices(i);
        setAllInvoices(i);
        setInvoicesLoaded(true);
        setTimeout(() => {
          enrichData();
        }, 1);
      })
      .catch(() => {
        setInvoicesLoaded(true);
      });
  };

  useEffect(() => {
    setInvoice(null);

    if (user) {
      retrieveData();
    }
  }, [user]);

  useEffect(() => {
    if (invoicesLoaded) {
      enrichData();
    }
  }, [i18n.language, invoicesLoaded]);

  useEffect(() => {
    const f = invoiceFilter.trim().toLowerCase();
    if (!f || f === "") {
      setInvoices(allInvoices);
      return;
    }

    setInvoicePage(1);
    setInvoices(
      allInvoices.filter((c) => {
        return (
          (c.buyerName && c.buyerName?.toLowerCase().indexOf(f) !== -1) ||
          (c.number && c.number?.toLowerCase().indexOf(f) !== -1) ||
          (c.translatedDueDate && c.translatedDueDate?.indexOf(f) !== -1) ||
          (c.translatedUpdatedAt && c.translatedUpdatedAt?.indexOf(f) !== -1)
        );
      })
    );
  }, [invoiceFilter]);

  const deleteInvoice = (id) => {
    setShowDeleteInvoiceModal(false);
    bDeleteInvoice(id).then(() => {
      const updatedInvoices = invoices.filter((i) => i.id !== id);
      const updatedAllInvoices = allInvoices.filter((i) => i.id !== id);
      setInvoices(updatedInvoices);
      setAllInvoices(updatedAllInvoices);
    });
  };

  const prepareDeleteInvoice = (e, id) => {
    e.stopPropagation();
    setShowDeleteInvoiceModal(id);
  };

  const pagedInvoices = invoices.slice(
    (invoicePage - 1) * itemsPerPage,
    Math.min(invoicePage * itemsPerPage, invoices.length)
  );

  const duplicateInvoice = (e, invoice) => {
    e.preventDefault();
    e.stopPropagation();
    toast("Duplicating invoice...");

    const newInvoice = {
      ...invoice,
      data: {
        ...invoice.data,
        id: null,
        content: {
          ...invoice.data.content,
          seller:
            profile && !isObjectEmpty(profile.branding.seller)
              ? profile.branding.seller
              : invoice.data.content.seller,
        },
      },
      permalink: null,
      id: null,
    };

    setInvoice(newInvoice);
    history.push("/editor", {
      isNewInvoice: true,
    });
  };

  return (
    <ScreenWrapper>
      <Section>
        <HeaderWithSearch>
          <SecondaryHeading>{t("invoice_history")}</SecondaryHeading>
          <SearchWrapper>
            <Input
              placeholder={t("filter")}
              onChange={(e) => setInvoiceFilter(e.target.value)}
            />
          </SearchWrapper>
        </HeaderWithSearch>

        <TableWrapper>
          <Table>
            <thead>
              <TableHeader>
                <TableHeaderCell width="20%" mobileWidth="40%">
                  {t("buyer")}
                </TableHeaderCell>
                <TableHeaderCell width="16%" mobileWidth="32%">
                  {t("number")}
                </TableHeaderCell>
                <TableHeaderCell hideMobile width="16%">
                  {t("amount")}
                </TableHeaderCell>
                <TableHeaderCell hideMobile width="16%">
                  {t("due_date")}
                </TableHeaderCell>
                <TableHeaderCell hideMobile width="16%">
                  {t("last_modified")}
                </TableHeaderCell>
                <TableHeaderCell width="16%" style={{ width: "auto" }} />
              </TableHeader>
            </thead>
            <tbody>
              {pagedInvoices.map((i, key) => (
                <TableRow
                  key={"invoice-" + key}
                  tabIndex={0}
                  onKeyPress={(e) => {
                    const keyCode = e.keyCode || e.which;
                    if (keyCode === 13) {
                      setInvoice(i);
                      history.push("/editor");
                    }
                  }}
                  onClick={() => {
                    setInvoice(i);
                    history.push("/editor");
                  }}
                >
                  <TableCell width="20%" mobileWidth="40%">
                    {i.buyerName || t("no_name")}
                  </TableCell>
                  <TableCell
                    width="16%"
                    mobileWidth="32%"
                    style={{ textAlign: "left" }}
                  >
                    {i.number || "-"}
                  </TableCell>
                  <TableCell hideMobile width="16%">
                    {getFormattedAmount(i.amount, i.currency)}
                  </TableCell>
                  <TableCell hideMobile width="16%">
                    {getFormattedDate(i.dueDate, i18n.language)}
                  </TableCell>
                  <TableCell hideMobile width="16%">
                    {getFormattedDate(i.updatedAt, i18n.language)}
                  </TableCell>
                  <TableCell
                    width="16%"
                    style={{
                      width: "auto",
                      flex: 1,
                      gap: 8,
                      justifyContent: "flex-end",
                    }}
                  >
                    {i.permalink && i.permalink.template && (
                      <>
                        <Tooltip text={t("invoice_locked")}>
                          <BiLock />
                        </Tooltip>

                        {/*
                        <Tooltip text={t("download")}>
                          <RoundButton
                            status="info"
                            light
                            small
                            onClick={(e) => prepareDeleteInvoice(e, i.id)}
                          >
                            <FiDownload />
                          </RoundButton>
                        </Tooltip>
*/}
                      </>
                    )}

                    <Tooltip text={t("duplicate")}>
                      <RoundButton
                        status="info"
                        small
                        light
                        onClick={(e) => duplicateInvoice(e, i)}
                        disable={isLoading}
                      >
                        <IoCopyOutline />
                        {/*{t("delete")}*/}
                      </RoundButton>
                    </Tooltip>
                    <Tooltip text={t("delete")}>
                      <RoundButton
                        status="error"
                        small
                        onClick={(e) => prepareDeleteInvoice(e, i.id)}
                      >
                        <MdDeleteOutline />
                        {/*{t("delete")}*/}
                      </RoundButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </tbody>
          </Table>
          {!invoicesLoaded && <Loader small />}
          {invoicesLoaded && invoices.length === 0 && (
            <NoEntries>{t("no_entries")}</NoEntries>
          )}
        </TableWrapper>
        {invoices.length > 0 && (
          <PagingRow>
            <Col>
              <div>
                {itemsPerPage * (invoicePage - 1) + 1}-
                {pagedInvoices.length + itemsPerPage * (invoicePage - 1)}{" "}
                {t("of")} {invoices.length}
              </div>
            </Col>
            <CollapsibleCol>
              {invoicePage > 1 && (
                <SecondaryButton
                  onClick={() => setInvoicePage(invoicePage - 1)}
                >
                  {t("previous_page")}
                </SecondaryButton>
              )}
              {invoicePage * itemsPerPage < invoices.length && (
                <SecondaryButton
                  onClick={() => setInvoicePage(invoicePage + 1)}
                >
                  {t("next_page")}
                </SecondaryButton>
              )}
            </CollapsibleCol>
          </PagingRow>
        )}
      </Section>

      <Modal
        title={t("confirm_delete_invoice_title")}
        isVisible={!!showDeleteInvoiceModal}
        onConfirm={() => deleteInvoice(showDeleteInvoiceModal)}
        onCancel={() => setShowDeleteInvoiceModal(false)}
        danger
      >
        {t("confirm_delete_invoice")}
      </Modal>
    </ScreenWrapper>
  );
};

export default Invoices;
