import styled, { keyframes } from "styled-components";
import { media } from "../components/mixins";
import googleLogo from "../assets/GoogleLogo.svg";

export const noSelect = `
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
  `;

const brightnessKeyFrames = keyframes`
  from {
    filter: brightness(1);
  }
  to {
    filter: brightness(0.9);
  }
`;

export const bgKeyFrames = keyframes`
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;

export const RoundButton = styled.div.attrs({ role: "button", tabIndex: 0 })`
  border: 0;
  border-radius: 14px;
  font-size: 20px;
  font-weight: bold;
  width: 28px;
  min-width: 28px;
  max-width: 28px;
  height: 28px;
  min-height: 28px;
  max-height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${({ status, theme }) =>
    status ? theme.colors[status] : "#30336b"};
  color: ${({ light }) => (light ? "#000000" : "#ffffff")};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  white-space: nowrap;
  //filter: brightness(1) ${({ disabled }) => (disabled ? "opacity(0.7)" : "")};
  filter: brightness(1);

  svg {
    stroke: ${({ light }) => (light ? "#000000" : "#ffffff")};
    fill: ${({ light }) => (light ? "#000000" : "#ffffff")};
    width: 20px;
    height: 20px;
  }

  ${noSelect}

  &:hover {
    animation: ${brightnessKeyFrames} 0.1s ease-in;
    filter: brightness(0.9);
  }

  &:active {
    filter: brightness(0.8);
  }
`;

export const Button = styled.button`
  border: solid 1px #00000000;
  border-radius: 4px;
  padding: ${({ small }) => (small ? "4px 8px" : "8px 16px")};
  font-size: ${({ small }) => (small ? "12px" : "14px")};
  width: ${({ block }) => (block ? "100%" : "inherit")};
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primaryFg};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  white-space: nowrap;
  ${({ disabled }) => (disabled ? "opacity(0.7)" : "")};
  filter: brightness(1);

  :active {
    outline: solid 2px ${({ theme }) => theme.colors.buttonOutline} !important;
  }

  ${({ outline }) =>
    outline &&
    `
  border-width: 1px;
  border-style: solid;
  border-color: #30336b;
  background-color: #ffffff00 !important;
  
  &:hover {
    background-color: #ffffff99 !important;  
  }`}

  ${noSelect}

  &:hover {
    animation: ${brightnessKeyFrames} 0.1s ease-in;
    filter: brightness(0.9);
  }

  &:active {
    filter: brightness(0.8);
  }
`;

export const DangerButton = styled(Button)`
  background-color: #ee5253;
  color: #ffffff;
  ${({ outline }) =>
    outline &&
    `
  color: #ee5253;
  border-color: #ee5253;`}
`;

export const GoogleButton = styled(Button)`
  background-color: #db4437;
  color: #ffffff;
  border: solid 1px #db4437;
  overflow: hidden;
  position: relative;
`;

export const GoogleButtonBackground = styled.div`
  background-image: url(${googleLogo});
  background-size: 24px;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #ffffff;
  height: 33px;
  width: 33px;
  border-radius: 4px 0 0 4px;
`;

export const SecondaryButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.info};
  color: ${({ theme }) => theme.colors.secondaryFg};
`;

export const TextButton = styled(Button)`
  background-color: rgba(0, 0, 0, 0);
  animation: background-color ease-in 1s;
  color: ${({ dark }) => (dark ? "#000000" : "#ffffff")};
  ${({ block }) => (block ? "width: 100%;" : "")}
  ${({ underline }) => (underline ? "text-decoration: underline;" : "")}

  &:hover {
    transition: all 0.1s ease-in;
    background-color: #ffffff11;
  }
`;

export const ThemedButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.themedButton};
  animation: background-co§or ease-in 1s;
  color: ${({ theme }) => theme.colors.dashboardFg};
  ${({ block }) => (block ? "width: 100%;" : "")}

  &:hover {
    transition: all 0.1s ease-in;
    background-color: #ffffff11;
  }
`;

export const SectionTitle = styled.div`
  display: block;
  font-size: 17px;
  padding: 8px 0;
  border-bottom: solid 1px ${({ theme }) => theme.colors.tableBorder};
  margin-bottom: 16px;
`;

export const DashboardSectionTitle = styled.div`
  display: block;
  font-size: 17px;
  padding: 8px 0;
  margin-bottom: 16px;
`;

export const Input = styled.input`
  width: ${({ width }) => (width ? `${width}px` : "100%")};
  border: solid 1px ${({ theme }) => theme.colors.inputBorder};
  font-size: 14px;
  height: 36px;
  border-radius: 4px;
  box-sizing: border-box;
  transition: all 0.1s ease-in-out;
  background-color: ${({ theme }) => theme.colors.inputBg};
  color: ${({ theme }) => theme.colors.inputFg};

  :disabled {
    background-color: ${({ theme }) => theme.colors.inputBgDisabled};
  }

  &:focus-visible {
    outline: 0 !important;
    border-color: ${({ theme }) => theme.colors.inputFocusBorder};
  }
`;

export const Select = styled.select`
  width: ${({ width }) => (width ? `${width}px` : "100%")};
  border: solid 1px ${({ theme }) => theme.colors.inputBorder};
  font-size: 14px;
  height: 36px;
  border-radius: 4px;
  box-sizing: border-box;
  transition: all 0.1s ease-in-out;
  background-color: ${({ theme }) => theme.colors.inputBg};
  color: ${({ theme }) => theme.colors.inputFg};

  &:focus-visible {
    outline: 0 !important;
    border-color: ${({ theme }) => theme.colors.inputFocusBorder};
  }
`;

export const Textarea = styled.textarea`
  width: 100%;
  border: solid 1px ${({ theme }) => theme.colors.inputBorder};
  font-size: 14px;
  min-height: 80px;
  border-radius: 4px;
  box-sizing: border-box;
  transition: all 0.1s ease-in-out;
  background-color: ${({ theme }) => theme.colors.inputBg};
  color: ${({ theme }) => theme.colors.inputFg};
  resize: none;

  &:focus-visible {
    outline: 0 !important;
    border-color: ${({ theme }) => theme.colors.inputFocusBorder};
  }
`;

export const Slider = styled.input.attrs(({ type }) => ({
  type: type || "range",
}))`
  width: 100%;
  border: solid 1px #cccccc;
  font-size: 14px;
  border-radius: 4px;
  box-sizing: border-box;
  transition: all 0.1s ease-in-out;
`;

export const Separator = styled.div`
  width: 100%;
  height: ${({ size }) => (size ? `${size}px` : "10px")};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  ${({ gap }) => (gap ? `gap: ${gap}px;` : "")}
`;

export const FlexRow = styled(Row)`
  ${media.belowLarge} {
    flex-direction: column;
  }
`;

export const CenteredRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Col = styled.div`
  flex: 1;
`;

export const FlexCol = styled.div`
  flex: ${({ flex }) => flex};
  display: flex;
  flex-direction: ${({ dir }) => (dir ? dir : "row")};
  align-items: center;
`;

export const CollapsibleCol = styled.div`
  flex: 0;
`;

export const CenteredCol = styled.div`
  flex: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Initials = styled.div`
  font-size: 13px;
  font-weight: bold;
  border-radius: 50%;
  background-color: #ffffff;
  color: #30336b;
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  cursor: default;
  user-select: none;
  text-transform: uppercase;
`;

export const Explanation = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.explanation};
  padding: 8px;
  margin-bottom: 8px;
`;

export const InfoBar = styled.div`
  background-color: ${({ theme }) => theme.colors.infoHover};
  color: ${({ theme }) => theme.colors.explanation};
  border-radius: 8px;
  padding: 8px;
`;

export const InfoBarPrimary = styled.div`
  background-color: #30336b;
  color: #ffffff;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 14px;

  a {
    color: #ffffff;
  }
`;

export const InfoBarDanger = styled.div`
  background-color: ${({ theme }) => theme.colors.danger};
  color: #ffffff;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 14px;

  a {
    color: #ffffff;
  }
`;

export const ScreenHeading = styled.div`
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 10px;
  text-transform: capitalize;
`;

export const SecondaryHeading = styled.div`
  font-size: 22px;
  color: ${({ theme }) => theme.colors.secondaryHeading} !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  flex: 1;
  margin-bottom: 16px;
`;

export const Section = styled.div`
  padding: 16px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.sectionBg};
  border: solid 1px ${({ theme }) => theme.colors.sectionBorder};
  color: ${({ theme }) => theme.colors.sectionFg};
  width: 100%;
`;

export const SectionText = styled.div`
  line-height: 1.75;
`;

export const SectionInfo = styled.div`
  color: #777777;
  line-height: 1.75;
  font-size: 14px;
`;

export const CheckoutSection = styled.div`
  padding: 16px;
  border-radius: 8px;
  background-color: ${({ theme }) =>
    theme.name === "light" ? theme.colors.sectionBg : "#7373ad"};
  border: solid 1px
    ${({ theme }) =>
      theme.name === "light" ? theme.colors.sectionBorder : "#000000"};
  color: ${({ theme }) =>
    theme.name === "light" ? theme.colors.sectionFg : "#000000"};
  width: 100%;

  ${({ theme }) =>
    theme.name === "dark" &&
    "background-image: linear-gradient(45deg, #93a5cf 0%, #e4efe9 100%);"}

  ${SecondaryHeading} {
    color: ${({ theme }) =>
      theme.name === "light"
        ? theme.colors.secondaryHeading
        : "#000000 !important"};
  }

  a {
    color: ${({ theme }) =>
      theme.name === "light" ? theme.colors.link : "#777777 !important"};
  }
`;

export const ScreenWrapper = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
`;
