import React from "react";
import styled from "styled-components";

const TabsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  ${({ inCard }) =>
    inCard &&
    `
  background-color: #ffffff11;
  margin: -8px -16px 0 -16px;
  padding: 8px 16px;`};

  @media only screen and (max-width: 900px) {
    gap: 8px;
  }
`;

const Tab = styled.div`
  padding: ${({ small }) => (small ? "8px 12px" : "8px 16px")};
  cursor: pointer;

  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.primary : theme.colors.gray};
  border: solid 2px
    ${({ selected, disabled, theme }) =>
      selected
        ? theme.colors.primary
        : disabled
        ? theme.colors.darkGray
        : theme.colors.gray};
  color: ${({theme}) => theme.colors.primaryFg};
  border-radius: ${({ small }) => (small ? "8px" : "32px")};
  transition: all 0.15s ease-in;
  font-size: ${({ small }) => (small ? "13px" : "15px")};

  &:hover {
    filter: brightness(1.1);
  }

  @media only screen and (max-width: 900px) {
    font-size: 14px;
  }
`;

export const StickyTabsWrapper = styled.div`
  position: sticky;
  top: 0;
  background-color: #f5f6fa;
  z-index: 11;
  padding: 10px 0;
`;

const Tabs = ({ tabs, selected, onSelect, small, ...props }) => {
  return (
    <TabsWrapper {...props}>
      {tabs?.map((t, key) => (
        <Tab
          key={t.label}
          onClick={() => onSelect(key)}
          selected={selected === key}
          small={small}
        >
          {t.label}
        </Tab>
      ))}
    </TabsWrapper>
  );
};

export default Tabs;
