import commonStrings from "./commonStrings";

const template = {
  locales: ["en", "ro"],
  variables: [
    {
      name: "accent",
      default: "#4a69bd",
      type: "color",
    },
    {
      name: "additionalInfo",
      title: "additional invoice info",
      default: "",
      type: "text",
    },
  ],
  strings: {
    en: {
      invoiceTitle: "Invoice",
      invoiceSeriesTitle: "Series",
      invoiceNumberTitle: "Number",
      invoiceDateTitle: "Date of Issue",
      invoiceDueDateTitle: "Due Date",
      sellerTitle: "Seller",
      billedToTitle: "Billed To",
      descriptionHeader: "Description",
      unitCostHeader: "Unit Cost",
      qtyHeader: "Qty",
      amountHeader: "Amount",
      taxHeader: "Tax",
      amountWTaxHeader: "Amount + Tax",
      invoiceTotalTitle: "Invoice total",
      invoiceSubtotalTitle: "Subtotal",
      invoiceTaxRateTitle: "Tax rate",
      invoiceTaxTitle: "Tax",
      exchangeRateTitle: "Exchange rate",
    },
    ro: {
      invoiceTitle: "Factura",
      invoiceSeriesTitle: "Serie",
      invoiceNumberTitle: "Numar",
      invoiceDateTitle: "Data emiterii",
      invoiceDueDateTitle: "Termen de plata",
      billedToTitle: "Client",
      sellerTitle: "Furnizor",
      descriptionHeader: "Descriere",
      unitCostHeader: "Cost unit.",
      qtyHeader: "Cant.",
      amountHeader: "Pret",
      taxHeader: "TVA",
      amountWTaxHeader: "Pret cu TVA",
      invoiceTotalTitle: "Total factura",
      invoiceSubtotalTitle: "Total fara TVA",
      invoiceTaxRateTitle: "TVA (%)",
      invoiceTaxTitle: "TVA",
      exchangeRateTitle: "Curs valutar",
    },
    fr: commonStrings.fr,
    de: commonStrings.de,
    ja: commonStrings.ja,
  },
  globalStyles: `
    * {
      font-family: 'Lato' !important;
    }
`,
  styles: {
    itemRow: {
      fontSize: 13,
    },
    itemRowCell: {
      paddingTop: 5,
      paddingBottom: 5,
    },
    itemDescriptionHeaderCell: {
      fontSize: 13,
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "{variables__accent}",
      textAlign: "left",
      borderBottomWidth: 2,
      borderBottomStyle: "solid",
      borderBottomColor: "{variables__accent}",
      widthWithTax: "40%",
      widthWithoutTax: "55%",
      whiteSpace: "nowrap",
      lineHeight: 13,
    },
    itemUnitCostHeaderCell: {
      fontSize: 13,
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "{variables__accent}",
      textAlign: "left",
      borderBottomWidth: 2,
      borderBottomStyle: "solid",
      borderBottomColor: "{variables__accent}",
      widthWithTax: "12%",
      widthWithoutTax: "15%",
      whiteSpace: "nowrap",
    },

    itemQtyHeaderCell: {
      fontSize: 13,
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "{variables__accent}",
      textAlign: "left",
      borderBottomWidth: 2,
      borderBottomStyle: "solid",
      borderBottomColor: "{variables__accent}",
      widthWithTax: "12%",
      widthWithoutTax: "15%",
      whiteSpace: "nowrap",
    },

    itemAmountHeaderCell: {
      fontSize: 13,
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "{variables__accent}",
      textAlign: "left",
      borderBottomWidth: 2,
      borderBottomStyle: "solid",
      borderBottomColor: "{variables__accent}",
      widthWithTax: "12%",
      widthWithoutTax: "15%",
      whiteSpace: "nowrap",
    },

    itemTaxHeaderCell: {
      fontSize: 13,
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "{variables__accent}",
      textAlign: "left",
      borderBottomWidth: 2,
      borderBottomStyle: "solid",
      borderBottomColor: "{variables__accent}",
      width: "12%",
      whiteSpace: "nowrap",
    },
    itemAmountWithTaxHeaderCell: {
      fontSize: 13,
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "{variables__accent}",
      textAlign: "left",
      borderBottomWidth: 2,
      borderBottomStyle: "solid",
      borderBottomColor: "{variables__accent}",
      width: "12%",
      whiteSpace: "nowrap",
    },
    mainContainer: {
      top: 0,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      paddingLeft: 60,
      textAlign: "center",
      fontSize: 48,
      fontWeight: "bold",
      letterSpacing: 4,
      color: "#ffffff",
      textTransform: "uppercase",
    },
    invoiceNumberWrapper: {
      position: "absolute",
      left: 400,
      top: 260,
    },
    invoiceNumberTitle: {
      textTransform: "uppercase",
      color: "#777777",
      fontWeight: "bold",
      fontSize: 14,
    },
    invoiceNumber: {
      textTransform: "uppercase",
      color: "#000000",
      fontSize: 14,
    },
    dateOfIssueWrapper: {
      position: "absolute",
      left: 400,
      top: 210,
    },
    dateOfIssueTitle: {
      textTransform: "uppercase",
      color: "#777777",
      fontWeight: "bold",
      fontSize: 14,
    },
    dateOfIssue: {
      textTransform: "uppercase",
      color: "#000000",
      fontSize: 14,
    },
    dueDateWrapper: {
      position: "absolute",
      left: 550,
      top: 210,
    },
    dueDateTitle: {
      textTransform: "uppercase",
      color: "#777777",
      fontWeight: "bold",
      fontSize: 14,
    },
    dueDate: {
      textTransform: "uppercase",
      color: "#000000",
      fontSize: 14,
    },
    billedToWrapper: {
      position: "absolute",
      left: 76,
      top: 210,
      maxWidth: 280,
    },
    billedToTitle: {
      textTransform: "uppercase",
      color: "#777777",
      fontWeight: "bold",
      fontSize: 14,
    },
    billedTo: {
      textTransform: "uppercase",
      color: "#000000",
      fontSize: 14,
      maxWidth: 400,
    },
    sellerWrapper: {
      // position: "absolute",
      // right: 80,
      // top: 0,
      paddingTop: 20,
      paddingBottom: 20,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      maxWidth: 350,
      textAlign: "right",
    },
    invoiceTotalWrapper: {
      position: "absolute",
      right: 100,
      top: 220,
    },
    invoiceTotalTitle: {
      fontSize: 13,
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "#777777",
      textAlign: "right",
    },
    invoiceTotalContent: {
      fontSize: 42,
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "{variables__accent}",
      textAlign: "right",
    },
    invoiceTotalInOtherCurrency: {
      fontSize: 16,
      display: "{variables__displayTotalInOtherCurrency}",
      textAlign: "right",
      displayCondition: (invoiceData) =>
        invoiceData?.config?.otherCurrency &&
        invoiceData?.config?.otherCurrency !== invoiceData?.config?.currency
          ? "block"
          : "none",
    },
    invoiceTotalInOtherCurrencySmall: {
      fontSize: 14,
      marginLeft: 95,
      display: "{variables__displayTotalInOtherCurrency}",
      displayCondition: (invoiceData) =>
        invoiceData?.config?.otherCurrency &&
        invoiceData?.config?.otherCurrency !== invoiceData?.config?.currency
          ? "block"
          : "none",
    },
    footerLine: {
      position: "absolute",
      bottom: 20,
      left: 20,
      right: 20,
      height: 4,
      backgroundColor: "{variables__accent}",
    },

    invoiceTitle: {
      textTransform: "uppercase",
      fontWeight: "bold",
      color: "{variables__accent}",
      marginTop: 30,
      marginLeft: 60,
      fontSize: 36,
      marginBottom: 4,
    },
    topRectangle: {
      top: 70,
      width: "100%",
      minHeight: 50,
      backgroundColor: "{variables__accent}",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingLeft: 65,
      paddingRight: 65,
    },
    topRectangleLabel: {
      fontSize: 12,
      color: "#ffffff",
      marginRight: 4,
    },
    topRectangleValue: {
      fontSize: 12,
      color: "#ffffff",
      fontWeight: "bold",
    },
    topRectangleSmallSeparator: {
      width: 10,
    },
    topRectangleSeparator: {
      flex: 1,
    },
    sellerBuyerWrapper: {
      display: "flex",
      flexDirection: "row",
      marginTop: 20,
      // width: 800
    },
    sellerBuyerColumn: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
    },
    sellerBuyerTitle: {
      textTransform: "uppercase",
      fontWeight: "bold",
      fontSize: 12,
      color: "{variables__accent}",
      marginBottom: 6,
    },
    sellerName: {
      textTransform: "uppercase",
      fontWeight: "bold",
      fontSize: 12,
    },
    sellerInfo: {
      textTransform: "uppercase",
      fontSize: 12,
      maxWidth: 400,
    },
    mainContent: {
      paddingLeft: 60,
      marginTop: 10,
      width: 940,
    },
    productTableWrapper: {
      marginTop: 40,
      width: 878,
      borderTopWidth: 2,
      borderTopStyle: "solid",
      borderTopColor: "{variables__accent}",
    },
    productTable: {
      width: 880,
      borderCollapse: "collapse",
    },
    bottomInfoRow: {
      marginTop: 50,
      display: "flex",
      flexDirection: "row",
    },
    bottomLeftInfo: {
      borderTopWidth: 4,
      borderTopStyle: "solid",
      borderTopColor: "{variables__accent}",
      flex: 1.4,
    },
    bottomLeftInfoTitle: {
      fontSize: 13,
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "#777777",
      textAlign: "left",
    },
    bottomLeftInfoContent: {
      fontSize: 42,
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "{variables__accent}",
      textAlign: "left",
    },
    bottomRightInfo: {
      borderTopWidth: 4,
      borderTopStyle: "solid",
      borderTopColor: "{variables__accent}",
      flex: 1,
      backgroundColor: "{variables__accent}55",
      paddingBottom: 20,
      paddingLeft: 20,
      paddingTop: 10,
      paddingRight: 20,
    },
    bottomRightInfoTitle: {
      fontSize: 14,
      textAlign: "left",
      marginTop: 10,
    },
    invoiceTotal: {
      fontWeight: "bold",
      paddingLeft: 5,
      fontSize: 19,
      paddingBottom: 10,
    },

    bottomRightInfoTitleExchangeRate: {
      fontSize: 14,
      textAlign: "left",
      marginTop: 10,
      display: "{variables__displayTotalInOtherCurrency}",
      displayCondition: (invoiceData) =>
        invoiceData?.config?.otherCurrency &&
        invoiceData?.config?.otherCurrency !== invoiceData?.config?.currency
          ? "block"
          : "none",
    },
    bottomRightInfoContent: {
      fontWeight: "bold",
      paddingLeft: 5,
    },
    additionalInfo: {
      marginTop: 16,
      whiteSpace: "pre-line",
      fontSize: 13,
      paddingBottom: 8,
      borderBottomWidth: 2,
      borderBottomStyle: "solid",
      borderBottomColor: "{variables__accent}",
      displayCondition: (invoiceData) =>
        !!invoiceData?.config?.userVariables?.additionalInfo ? "block" : "none",
    },
    footer: {
      position: "absolute",
      bottom: 28,
      left: 20,
      right: 20,
      fontSize: 13,
      whiteSpace: "pre-line",
      paddingTop: 8,
      displayCondition: (invoiceData) =>
        invoiceData?.config?.footer ||
        invoiceData?.config?.userVariables?.additionalInfo
          ? "block"
          : "none",
    },
  },
  template: `

<div style="{styles__invoiceTitle}">{invoiceTitle}</div>
<div style="{styles__topRectangle}">
  <div style="{styles__topRectangleLabel}">{invoiceSeriesTitle}</div>
  <div style="{styles__topRectangleValue}" id="invoice_series">{invoiceSeries}</div>
  <div style="{styles__topRectangleSmallSeparator}"></div>
  <div style="{styles__topRectangleLabel}">{invoiceNumberTitle}</div>
  <div style="{styles__topRectangleValue}" id="invoice_number">{invoiceNumber}</div>
  <div style="{styles__topRectangleSmallSeparator}"></div>
  <div style="{styles__topRectangleLabel}">{invoiceDateTitle}</div>
  <div style="{styles__topRectangleValue}" id="date_of_issue">{invoiceDate}</div>
  <div style="{styles__topRectangleSeparator}"></div>
  <div style="{styles__topRectangleLabel}">{invoiceDueDateTitle}</div>
  <div style="{styles__topRectangleValue}" id="due_date">{invoiceDueDate}</div>
</div>

<div style="{styles__mainContent}">
<div style="{styles__sellerBuyerWrapper}">
  <div style="{styles__sellerBuyerColumn}" id="seller">
    <div style="{styles__sellerBuyerTitle}">{sellerTitle}</div>
    <div style="{styles__sellerName}">{sellerName}</div>
    <div style="{styles__sellerInfo}">
      <div>{sellerAddress}</div>
      <div>{sellerPhone}</div>
      <div style="white-space: pre-line">{sellerAdditionalInfo}</div>
    </div>
  </div>
  <div style="{styles__sellerBuyerColumn}" id="billed_to">
    <div style="{styles__sellerBuyerTitle}">{billedToTitle}</div>
    <div style="{styles__sellerName}">{buyerName}</div>
    <div style="{styles__sellerInfo}">
      <div>{buyerAddress}</div>
      <div>{buyerPhone}</div>
      <div style="white-space: pre-line">{buyerAdditionalInfo}</div>
    </div>
  </div>
</div>

<div style="{styles__productTableWrapper}">
  <table style="{styles__productTable}" id="products_table">
    <thead>
      <tr>
        <th style="{styles__itemDescriptionHeaderCell}">{descriptionHeader}</th>
        <th style="{styles__itemUnitCostHeaderCell}">{unitCostHeader}</th>
        <th style="{styles__itemQtyHeaderCell}">{qtyHeader}</th>
        <th style="{styles__itemAmountHeaderCell}">{amountHeader}</th>
        <th style="{styles__itemTaxHeaderCell}" class="hide-for-zero-tax-rate">
          {taxHeader}
        </th>
        <th
          style="{styles__itemAmountWithTaxHeaderCell}"
          class="hide-for-zero-tax-rate"
        >
          {amountWTaxHeader}
        </th>
      </tr>
    </thead>
    <tbody>
      {invoiceItems}
    </tbody>
  </table>
  {addItemButton}
  <div style="{styles__bottomInfoRow}">
    <div style="{styles__bottomLeftInfo}">
      <div style="{styles__bottomRightInfoTitleExchangeRate}">
        {exchangeRateTitle}:
        <span style="{styles__bottomRightInfoContent}">
          {exchangeRateString}
        </span>
      </div>
    </div>
    <div style="{styles__bottomRightInfo}">
      <div style="{styles__bottomRightInfoTitle}">
        {invoiceSubtotalTitle}:
        <span style="{styles__bottomRightInfoContent}">
          {invoiceSubtotal}
        </span>
      </div>
      <div style="{styles__bottomRightInfoTitle}">
        {invoiceTaxRateTitle}:
        <span style="{styles__bottomRightInfoContent}">
          {invoiceTaxRate}%
        </span>
      </div>
      <div style="{styles__bottomRightInfoTitle}">
        {invoiceTaxTitle}:
        <span style="{styles__bottomRightInfoContent}"> {invoiceTax} </span>
      </div>
      <div style="{styles__bottomRightInfoTitle}">
        {invoiceTotalTitle}:
        <span style="{styles__bottomRightInfoContent}">
          <span style="{styles__invoiceTotal}">{invoiceTotal}</span>
          <div style="{styles__invoiceTotalInOtherCurrencySmall}">
            {invoiceTotalInOtherCurrency}
          </div>
        </span>
      </div>
    </div>
  </div>
  

  <div style="{styles__footer}">
    <div style="{styles__additionalInfo}">{variables__additionalInfo}</div>
    {footer}
  </div>

</div>
</div>
<div style="{styles__footerLine}"></div>
`,
  itemRow:
    "<tr\n" +
    "style={styles__itemRow}\n" +
    ">\n" +
    "    <td style={styles__itemRowCell}>\n" +
    "    {itemName}\n" +
    "    </td>\n" +
    "    <td style={styles__itemRowCell}>\n" +
    "    {itemUnitCost}\n" +
    "    </td>\n" +
    "    <td style={styles__itemRowCell}>\n" +
    "    {itemQuantity}\n" +
    "    </td>\n" +
    "    <td style={styles__itemRowCell}>\n" +
    "    {itemTotalWithoutTax}\n" +
    "    </td>\n" +
    "    <td\n" +
    "        style={styles__itemRowCell}\n" +
    '        class="hide-for-zero-tax-rate"\n' +
    "    >\n" +
    "    {itemTax}\n" +
    "    </td>\n" +
    "    <td\n" +
    "        style={styles__itemRowCell}\n" +
    '        class="hide-for-zero-tax-rate"\n' +
    "    >\n" +
    "    {itemTotalWithTax}\n" +
    "    </td>\n" +
    "</tr>\n",
};

export default template;
