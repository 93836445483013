import {
  Input,
  ScreenWrapper,
  SecondaryHeading,
  Section,
  Separator,
} from "../../../lib/components";
import {
  TableWrapper,
  TemplateContainer,
  TemplateDate,
  TemplateGrid,
  TemplateHover,
  TemplateHoverContainer,
  TemplateImage,
  TemplateName,
  TemplatePreview,
  TemplateTitle,
} from "../../editor/components";
import { templatesList } from "../../../lib/templates";
import {
  emptyInvoiceData,
  FREE_ACCOUNT_INVOICE_LIMIT,
} from "../../../lib/constants";
import { FiZoomIn } from "react-icons/fi";
import { IoIosAdd } from "react-icons/io";
import { templates } from "../../../lib/dummy";
import { default as defaultTemplate } from "../../../assets/defaultTemplate.png";
import { format } from "date-fns";
import { getFormattedDate, isObjectEmpty } from "../../../lib/utils";
import i18n from "i18next";
import Loader, { LoaderWithWrapper } from "../../../components/loader/Loader";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Modal from "../../../components/modal/Modal";
import { useHistory } from "react-router-dom";
import { useInvoiceOutlet } from "../../../context/invoice";
import { useTranslation } from "react-i18next";
import { bGetCustomers } from "../../../backend/customer";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../firebase";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { media } from "../../../components/mixins";
import { toast } from "react-toastify";
import useIsFreeAccountLimitReached from "../../../hooks/useIsFreeAccountLimitReached";

const Info = styled.div`
  font-size: 13px;
  color: #aaaaaa !important;
`;

const NoEntries = styled.div`
  padding: 8px;
  text-align: center;
  font-size: 14px;
`;

const SearchWrapper = styled.div`
  min-width: 200px;
  margin-left: 20px;
  flex: 0;

  input {
    border-radius: 16px;
    padding: 1px 8px;
  }
`;

const HeaderWithSearch = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: solid 1px ${({ theme }) => theme.colors.tableBorder};

  ${media.belowMedium} {
    flex-direction: column;
    ${SecondaryHeading} {
      font-size: 18px;
      text-align: left;
      width: 100%;
    }

    ${SearchWrapper} {
      flex: 1;
      margin-left: 0;
      width: 100%;
    }
  }
`;

const Dashboard = () => {
  const [user, loading, error] = useAuthState(auth);
  const [customers, setCustomers] = useState([]);
  const [allCustomers, setAllCustomers] = useState([]);
  const [customersLoaded, setCustomersLoaded] = useState(false);
  const [seeAllCustomers, setSeeAllCustomers] = useState(false);
  const [customerFilter, setCustomerFilter] = useState("");
  const [showPreviewTemplateModal, setShowPreviewTemplateModal] =
    useState(false);

  const history = useHistory();
  const { invoice, setInvoice } = useInvoiceOutlet();
  const { t } = useTranslation();
  const profile = useSelector((state) => state.profile.profile);
  const subscriptionLoaded = useSelector(
    (state) => state.profile.subscriptionLoaded
  );

  const isCreateDisabled = useIsFreeAccountLimitReached();

  const enrichData = () => {
    const enrichedCustomers = allCustomers.map((c) => ({
      ...c,
      translatedUpdatedAt: getFormattedDate(c.updatedAt, i18n.language),
    }));

    if (enrichedCustomers.length) {
      setAllCustomers(enrichedCustomers);
    }
  };

  const retrieveData = () => {
    bGetCustomers()
      .then((c) => {
        setCustomers(c);
        setAllCustomers(c);
        setCustomersLoaded(true);
        setTimeout(() => {
          enrichData();
        }, 1);
      })
      .catch(() => {
        setCustomersLoaded(true);
      });
  };

  useEffect(() => {
    setInvoice(null);

    if (user) {
      retrieveData();
    }
  }, [user]);

  useEffect(() => {
    if (customersLoaded) {
      enrichData();
    }
  }, [i18n.language, customersLoaded]);

  useEffect(() => {
    const f = customerFilter.trim().toLowerCase();
    if (!f || f === "") {
      setCustomers(allCustomers);
      setSeeAllCustomers(false);
      return;
    }
    console.log(customers);

    setCustomers(
      allCustomers.filter((c) => {
        return (
          (c.buyerName && c.buyerName?.toLowerCase().indexOf(f) !== -1) ||
          (c.translatedUpdatedAt && c.translatedUpdatedAt?.indexOf(f) !== -1)
        );
      })
    );
  }, [customerFilter]);

  const initialInvoiceData = useMemo(
    () => ({
      data: {
        ...emptyInvoiceData,
        config: {
          ...emptyInvoiceData.config,
          series: "",
          currency: "",
          language: i18n.language,
          template: profile ? profile.branding.template : "Simple",
          userVariables: {
            accent: profile ? profile.branding.accent : "#0099ff",
            additionalInfo: "",
            displayTotalInOtherCurrency: "none",
            sellerFontSize: 18,
          },
        },
        content: {
          ...emptyInvoiceData.content,
          seller:
            profile && !isObjectEmpty(profile.branding.seller)
              ? profile.branding.seller
              : { name: "Seller Info" },
          buyer: { name: "Buyer Info" },
        },
      },
    }),
    [profile]
  );

  const handleNewInvoice = useCallback(
    (template) => {
      if (!subscriptionLoaded) {
        return;
      }

      if (isCreateDisabled) {
        toast(
          `You have reached the limit of free invoices in the last thirty days. To create more, upgrade your subscription.`
        );
        return;
      }

      setInvoice({
        ...initialInvoiceData,
        data: {
          ...initialInvoiceData.data,
          config: {
            ...initialInvoiceData.data.config,
            template: template.name,
          },
        },
      });
      history.push("/editor", {
        isNewInvoice: true,
      });
    },
    [isCreateDisabled, subscriptionLoaded]
  );

  const handleNewInvoiceForCustomer = useCallback(
    (c) => {
      if (!subscriptionLoaded) {
        return;
      }

      if (isCreateDisabled) {
        toast(
          `You have reached the limit of free invoices in the last thirty days. To create more, upgrade your subscription.`
        );
        return;
      }

      setInvoice({
        data: {
          config: {
            currency: c.data.config.currency,
            series: c.data.config.series,
            number: "",
            taxRate: 0,
            date: format(new Date(), "yyyy-MM-dd"),
          },
          content: {
            ...c.data.content,
          },
          items: [],
        },
      });
      history.push("/editor", {
        isNewInvoice: true,
        a: 3,
      });
    },
    [isCreateDisabled, subscriptionLoaded]
  );

  const previewTemplate = (e, template) => {
    e.preventDefault();
    e.stopPropagation();
    setShowPreviewTemplateModal(template);
  };

  if (!subscriptionLoaded) {
    return <LoaderWithWrapper />;
  }

  return (
    <ScreenWrapper>
      <Section>
        <SecondaryHeading>{t("create_a_new_invoice")}</SecondaryHeading>
        <Info>{t("you_can_change_template_later")}</Info>
        <Separator />
        <TableWrapper>
          <TemplateGrid style={{ justifyContent: "flex-start" }}>
            {templatesList.map((t, key) => (
              <TemplateContainer
                key={"template" + t.name + key}
                onClick={() => handleNewInvoice(t)}
              >
                <TemplateImage src={t.thumbnail} />
                <TemplateName>{t.name}</TemplateName>
                <TemplateHoverContainer>
                  <TemplateHover onClick={(e) => previewTemplate(e, t)}>
                    <FiZoomIn />
                  </TemplateHover>
                  <TemplateHover blue>
                    <IoIosAdd />
                  </TemplateHover>
                </TemplateHoverContainer>
              </TemplateContainer>
            ))}
            <TemplateContainer
              onClick={() => {
                setInvoice({
                  ...initialInvoiceData,
                  data: {
                    ...initialInvoiceData.data,
                    config: {
                      ...initialInvoiceData.data.config,
                      template:
                        templatesList[
                          Math.floor(Math.random() * templates.length)
                        ].name,
                    },
                  },
                });
                history.push("/editor", {
                  isNewInvoice: true,
                });
              }}
            >
              <TemplateImage src={defaultTemplate} />
              <TemplateName>{t("random")}</TemplateName>
              <TemplateHoverContainer>
                <TemplateHover>
                  <IoIosAdd />
                </TemplateHover>
              </TemplateHoverContainer>
            </TemplateContainer>
          </TemplateGrid>
        </TableWrapper>
      </Section>
      <Separator size={40} />
      <Section>
        <HeaderWithSearch>
          <SecondaryHeading>
            {t("create_again_for_a_previous_customer")}
          </SecondaryHeading>
          <SearchWrapper>
            <Input
              placeholder={t("filter")}
              onChange={(e) => setCustomerFilter(e.target.value)}
            />
          </SearchWrapper>
        </HeaderWithSearch>

        {customersLoaded && customers.length > 0 && (
          <TemplateGrid
            style={{ justifyContent: "flex-start", flexWrap: "wrap" }}
          >
            {(seeAllCustomers
              ? customers.filter((c) => c.buyerName)
              : customers.filter((c) => c.buyerName).slice(0, 3)
            ).map((c, key) => (
              <TemplateContainer
                key={"customer-" + key}
                onClick={() => handleNewInvoiceForCustomer(c)}
                withBorder
                style={{ maxWidth: "calc(50% - 8px)" }}
              >
                <TemplateTitle className="template-title">
                  {c.buyerName}
                  <TemplateDate>
                    {getFormattedDate(c.updatedAt, i18n.language)}
                  </TemplateDate>
                </TemplateTitle>
                <TemplateHoverContainer>
                  <TemplateHover>
                    <IoIosAdd />
                  </TemplateHover>
                </TemplateHoverContainer>
              </TemplateContainer>
            ))}

            {!seeAllCustomers &&
              customers.filter((c) => c.buyerName).length > 3 && (
                <TemplateContainer
                  onClick={() => setSeeAllCustomers(true)}
                  withBorder
                >
                  <TemplateTitle className="template-title">
                    {t("see_all_customers")}
                  </TemplateTitle>
                  <TemplateHover style={{ display: "none" }}>
                    <IoIosAdd />
                  </TemplateHover>
                </TemplateContainer>
              )}
          </TemplateGrid>
        )}

        {!customersLoaded && <Loader small />}
        {customersLoaded && customers.length === 0 && (
          <NoEntries>{t("no_entries")}</NoEntries>
        )}
      </Section>

      <Modal
        title={t("preview_template")}
        isVisible={!!showPreviewTemplateModal}
        onClose={() => setShowPreviewTemplateModal(false)}
      >
        <TemplatePreview src={showPreviewTemplateModal?.preview} />
      </Modal>
    </ScreenWrapper>
  );
};

export default Dashboard;
