import "./init";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { init } from "@amplitude/analytics-browser";
import * as Sentry from "@sentry/react";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n/config";
import getFirebase from "./firebase";
import { InvoiceContext } from "./context/invoice";
import { store } from "./redux/store";
import { Provider } from "react-redux";

getFirebase();
init("fb5f11cfbfd90f13dbc8e56bffcbe3d2");

Sentry.init({
  dsn: "https://3ff82a242c3c3f7775036ae8e4f215ff@o4506305553367040.ingest.sentry.io/4506523338014720",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (import.meta.env.MODE === "production") {
  console.info = () => {};
  console.log = () => {};
  console.debug = () => {};
  console.group = () => {};
}

const documentHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty("--doc-height", `${window.innerHeight}px`);
};
window.addEventListener("resize", documentHeight);
documentHeight();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router
        getUserConfirmation={() => {
          /* Empty callback to block the default browser prompt */
        }}
      >
        <InvoiceContext>
          <App />
        </InvoiceContext>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
