import axios from "axios";
import { AUTH_SERVER_URL } from "./config";

export const bGetProfile = () => {
  return new Promise((resolve, reject) => {
    axios.get(`${AUTH_SERVER_URL}/profile`, { withCredentials: true }).then(
      (response) => {
        return resolve(response.data);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

export const bUpdateProfile = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${AUTH_SERVER_URL}/profile`, data, { withCredentials: true })
      .then(
        (response) => {
          return resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
  });
};

export const bCreateFlag = (flagName, flagValue = true) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(
        `${AUTH_SERVER_URL}/profile/flags`,
        { flags: { [flagName]: flagValue } },
        { withCredentials: true }
      )
      .then(
        (response) => {
          return resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
  });
};
