import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  invoiceName: null,
  isInvoiceEdited: false,
};

export const editorSlice = createSlice({
  name: "editor",
  initialState,
  reducers: {
    setInvoiceName: (state, action) => {
      state.invoiceName = action.payload;
    },
    setIsInvoiceEdited: (state, action) => {
      state.isInvoiceEdited = action.payload;
    },
  },
});

export const { setInvoiceName, setIsInvoiceEdited } = editorSlice.actions;

export default editorSlice.reducer;
