const KEY = "profile";
const THEME_KEY = "theme";

export const getProfile = () => {
  return JSON.parse(localStorage.getItem(KEY) || "{}");
};

export const setProfile = (profile) => {
  return localStorage.setItem(KEY, JSON.stringify(profile));
};

export const getThemeFromStorage = () => {
  return localStorage.getItem(THEME_KEY);
};

export const saveThemeToStorage = (theme) => {
  return localStorage.setItem(THEME_KEY, theme);
};
