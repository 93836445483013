import React, { useEffect, useState } from "react";
import { HexColorPicker } from "react-colorful";
import {
  Button,
  FlexCol,
  FlexRow,
  Input,
  Row,
  Col,
  ScreenHeading,
  ScreenWrapper,
  SecondaryHeading,
  Section,
  Separator,
  Textarea,
  Explanation,
} from "../../../lib/components";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { templatesList } from "../../../lib/templates";
import {
  TableWrapper,
  TemplateCheck,
  TemplateContainer,
  TemplateGrid,
  TemplateHover,
  TemplateHoverContainer,
  TemplateImage,
  TemplateName,
  TemplatePreview,
} from "../../editor/components";
import { BiCheck } from "react-icons/bi";
import { FiZoomIn } from "react-icons/fi";
import Modal from "../../../components/modal/Modal";
import { bUpdateProfile } from "../../../backend/profile";
import Spinner from "../../../components/primitives/Spinner";
import { setProfile } from "../../../redux/profileSlice";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { track } from "../../../lib/analytics";
import * as events from "events";
import userEvents from "../../../lib/events";
import { getTextColorBasedOnBgColor } from "../../../lib/utils";
import { Centered } from "./subscription/Subscription";

const Branding = () => {
  const profile = useSelector((state) => state.profile.profile);
  const [accent, setAccent] = useState(profile.branding.accent);
  const [template, setTemplate] = useState(profile.branding.template);
  const [seller, setSeller] = useState(profile.branding.seller || {});
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const [showPreviewTemplateModal, setShowPreviewTemplateModal] =
    useState(false);

  useEffect(() => {
    window.onbeforeunload = function (e) {
      return "Are you sure you want to leave? You are in the middle of something.";
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const previewTemplate = (e, t) => {
    e.preventDefault();
    e.stopPropagation();
    setShowPreviewTemplateModal(t);
  };

  const { t } = useTranslation();

  const saveBranding = () => {
    if (!seller.name || !seller.name.trim()) {
      toast(t("non_empty_seller_name"), { type: "error" });
      return;
    }

    track(userEvents.BRANDING_UPDATED);
    setIsLoading(true);
    bUpdateProfile({
      branding: {
        accent,
        template,
        seller,
      },
    })
      .then(({ disableNameUpdate }) => {
        toast("Branding settings saved successfully! 🎨");
        dispatch(
          setProfile({
            ...profile,
            ...(disableNameUpdate ? { canUpdateName: false } : {}),
            branding: {
              accent,
              template,
              seller,
            },
          })
        );
        setIsLoading(false);
      })
      .catch((error) => {
        const code = error.response?.data?.error?.code || "unknown_error";
        setIsLoading(false);
        toast(code, { type: "warning" });
      });
  };

  return (
    <ScreenWrapper>
      <Row>
        <FlexCol flex={1}>
          <ScreenHeading>{t("branding")}</ScreenHeading>
        </FlexCol>
        <FlexCol flex={0}>
          <Button disabled={isLoading} onClick={saveBranding}>
            {isLoading ? <Spinner size={14} /> : "save"}
          </Button>
        </FlexCol>
      </Row>
      <Separator size={16} />

      <Section>
        <SecondaryHeading>{t("your_company_info")}</SecondaryHeading>

        <Row>
          <FlexCol flex={3}>{t("name")}</FlexCol>
          <FlexCol flex={7}>
            <Input
              name={"seller.name"}
              defaultValue={seller.name}
              onChange={(e) => setSeller({ ...seller, name: e.target.value })}
              disabled={!profile.canUpdateName}
            />
          </FlexCol>
        </Row>
        <Row>
          <FlexCol flex={3} />
          <FlexCol flex={7}>
            <Explanation>
              <Trans
                i18nKey={"you_can_only_change_name_once_every_60_days"}
                components={{
                  contactLink: (
                    <a
                      href="https://www.visualinvoicer.com/contact"
                      target="_blank"
                    />
                  ),
                }}
              />
            </Explanation>
          </FlexCol>
        </Row>
        <Separator />
        <Row>
          <FlexCol flex={3}>{t("address")}</FlexCol>
          <FlexCol flex={7}>
            <Textarea
              name={"seller.address"}
              defaultValue={seller.address}
              onChange={(e) =>
                setSeller({ ...seller, address: e.target.value })
              }
            />
          </FlexCol>
        </Row>
        <Separator />
        <Row>
          <FlexCol flex={3}>{t("phone_number")}</FlexCol>
          <FlexCol flex={7}>
            <Input
              name={"seller.phone"}
              defaultValue={seller.phone}
              onChange={(e) => setSeller({ ...seller, phone: e.target.value })}
            />
          </FlexCol>
        </Row>
        <Separator />
        <Row>
          <FlexCol flex={3}>{t("additional_info")}</FlexCol>
          <FlexCol flex={7}>
            <Textarea
              name={"seller.additionalInfo"}
              defaultValue={seller.additionalInfo}
              onChange={(e) =>
                setSeller({ ...seller, additionalInfo: e.target.value })
              }
            />
          </FlexCol>
        </Row>
      </Section>
      <Separator size={20} />
      <FlexRow gap={16}>
        <Col flex={1}>
          <Section>
            <SecondaryHeading style={{ marginBottom: 0 }}>
              {t("preferred_template")}
            </SecondaryHeading>
            <TableWrapper>
              <TemplateGrid
                style={{ justifyContent: "flex-start", paddingTop: 16 }}
              >
                {templatesList.map((t, key) => (
                  <TemplateContainer
                    key={t.name}
                    onClick={() => {
                      setTemplate(t.name);
                    }}
                    highlighted={t.name === template}
                  >
                    {t.name === template && (
                      <TemplateCheck>
                        <FontAwesomeIcon icon={faCheckCircle} />
                      </TemplateCheck>
                    )}

                    <TemplateImage src={t.thumbnail} />
                    <TemplateName>{t.name}</TemplateName>
                    <TemplateHoverContainer>
                      <TemplateHover onClick={(e) => previewTemplate(e, t)}>
                        <FiZoomIn />
                      </TemplateHover>
                      {t.name !== template && (
                        <TemplateHover blue>
                          <BiCheck />
                        </TemplateHover>
                      )}
                    </TemplateHoverContainer>
                  </TemplateContainer>
                ))}
              </TemplateGrid>
            </TableWrapper>
          </Section>
        </Col>
        <FlexCol flex={0}>
          <Section>
            <SecondaryHeading>{t("accent_color")}</SecondaryHeading>
            <Centered>
              <HexColorPicker
                color={accent}
                onChange={setAccent}
                style={{ width: "100%", minWidth: "200px", height: "240px" }}
              />
            </Centered>
            <Separator size={20} />
            <Input
              value={accent}
              onChange={(e) => setAccent(e.target.value)}
              style={{
                backgroundColor: accent,
                color: getTextColorBasedOnBgColor(accent),
              }}
            />
          </Section>
        </FlexCol>
      </FlexRow>

      <Modal
        title={t("preview_template")}
        isVisible={!!showPreviewTemplateModal}
        onClose={() => setShowPreviewTemplateModal(false)}
      >
        <TemplatePreview src={showPreviewTemplateModal?.preview} />
      </Modal>
    </ScreenWrapper>
  );
};

export default Branding;
