import { createSlice } from "@reduxjs/toolkit";
import { subscriptionStatus } from "../lib/constants";

const initialState = {
  list: [],
};

export const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    setClients: (state, action) => {
      state.profile = action.payload;
    },
  },
});

export const { setClients } = clientsSlice.actions;

export default clientsSlice.reducer;
