import React from "react";
import { v4 as uuidv4 } from "uuid";
import {
  getFormattedAmount,
  getFormattedDate,
  getInvoiceSubTotal,
  getInvoiceTax,
  getInvoiceTotal,
} from "./utils";

import dummy1 from "../assets/dummy1.png";
import dummy2 from "../assets/dummy2.png";
import dummy3 from "../assets/dummy3.png";
import { format } from "date-fns";

export const templates = [
  {
    name: "Template 1",
    preview: dummy1,
  },
  {
    name: "Template 2",
    preview: dummy2,
  },
  {
    name: "Template 3",
    preview: dummy3,
  },
];

export const previousCustomers = [
  {
    name: "Colorcode",
    lastCreatedDate: "2021-01-01T10:00:00",
  },
  {
    name: "Wertis Software",
    lastCreatedDate: "2021-01-01T10:00:00",
  },
  {
    name: "Abc Software",
    lastCreatedDate: "2021-01-01T10:00:00",
  },
  {
    name: "Something Computers",
    lastCreatedDate: "2021-01-01T10:00:00",
  },
];

const translations = {
  en: {
    invoice: "Invoice",
    invoice_no: "Invoice number",
    seller: "Seller",
    address: "Address:",
    phone: "Phone #:",
    fax: "Fax:",
    date: "Date of issue",
    due_date: "Due Date",
    bill_to: "Billed to",
    description: "Description",
    unit_cost: "Unit Cost",
    qty: "Qty",
    amount: "Amount",
    amountWTax: "Amount + Tax",
    total: "Invoice total",
    subtotal: "Subtotal",
    tax_rate: "Tax rate",
    tax: "Tax",
  },
};

export const getInvoiceHtml = (
  zoom,
  invoiceData,
  onEditItems = () => {},
  onEditConfig = () => {},
  extraItems = null,
  language = "en"
) => {
  return [
    <div
      style={{
        position: "absolute",
        top: 72 * zoom,
        left: 72 * zoom,
        fontSize: 72 * zoom,
        fontWeight: "bold",
        textTransform: "uppercase",
        letterSpacing: 4 * zoom,
      }}
      key={"title"}
    >
      {translations[language].invoice}
    </div>,
    <div
      style={{
        position: "absolute",
        left: 76 * zoom,
        top: 180 * zoom,
      }}
      key={"inv_number"}
      className={"editable"}
      onClick={() => onEditConfig("invoice_number")}
    >
      <div
        style={{
          textTransform: "uppercase",
          color: "#777777",
          fontWeight: "bold",
          fontSize: zoom * 14,
          marginBottom: 5 * zoom,
        }}
      >
        {translations[language].invoice_no}
      </div>

      <div
        style={{
          textTransform: "uppercase",
          color: "#000000",
          fontSize: zoom * 14,
        }}
      >
        {invoiceData.config.series + invoiceData.config.number}
      </div>
    </div>,
    <div
      style={{
        position: "absolute",
        left: 250 * zoom,
        top: 180 * zoom,
      }}
      key={"date_of_issue"}
      className={"editable"}
      onClick={() => onEditConfig("date_of_issue")}
    >
      <div
        style={{
          textTransform: "uppercase",
          color: "#777777",
          fontWeight: "bold",
          fontSize: zoom * 14,
          marginBottom: 5 * zoom,
        }}
      >
        {translations[language].date}
      </div>

      <div
        style={{
          textTransform: "uppercase",
          color: "#000000",
          fontSize: zoom * 14,
        }}
      >
        {invoiceData.config.date}
      </div>
    </div>,
    <div
      style={{
        position: "absolute",
        left: 76 * zoom,
        top: 260 * zoom,
      }}
      key={"billed_to"}
      className={"editable"}
      onClick={() => onEditConfig("buyer")}
    >
      <div
        style={{
          textTransform: "uppercase",
          color: "#777777",
          fontWeight: "bold",
          fontSize: zoom * 14,
          whiteSpace: "pre-line",
        }}
      >
        {translations[language].bill_to}
      </div>

      <div
        style={{
          textTransform: "uppercase",
          color: "#000000",
          fontSize: zoom * 14,
          marginTop: 5 * zoom,
          maxWidth: 400 * zoom,
        }}
      >
        <div>{invoiceData.content.buyer.name}</div>
        <div>{invoiceData.content.buyer.address}</div>
        <div>{invoiceData.content.buyer.phone}</div>
        <div>{invoiceData.content.buyer.additionalInfo}</div>
      </div>
    </div>,
    <div
      style={{
        position: "absolute",
        left: 576 * zoom,
        top: 260 * zoom,
        maxWidth: 350 * zoom,
      }}
      key={"seller"}
      className={"editable"}
      onClick={() => onEditConfig("seller")}
    >
      <div
        style={{
          textTransform: "uppercase",
          color: "#777777",
          fontWeight: "bold",
          fontSize: zoom * 36,
          whiteSpace: "pre-line",
        }}
      >
        {invoiceData.content.seller.name}
      </div>

      <div
        style={{
          textTransform: "uppercase",
          color: "#000000",
          fontSize: zoom * 14,
          marginTop: 10 * zoom,
          marginBottom: 10 * zoom,
          maxWidth: 400 * zoom,
        }}
      >
        <div>{invoiceData.content.seller.address}</div>
        <div>{invoiceData.content.seller.phone}</div>
        <div>{invoiceData.content.seller.additionalInfo}</div>
      </div>
    </div>,

    <div
      style={{ position: "absolute", top: zoom * 460, left: 76 * zoom }}
      key={"products-table"}
      className={"editable"}
      onClick={onEditItems}
    >
      <table
        style={{
          width: zoom * 848,
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th
              style={{
                fontSize: 13 * zoom,
                fontWeight: "bold",
                textTransform: "uppercase",
                color: "#777777",
                textAlign: "left",
                borderBottomWidth: 2 * zoom,
                borderBottomStyle: "solid",
                width: invoiceData.config.taxRate ? "40%" : "55%",
              }}
            >
              {translations[language].description}
            </th>
            <th
              style={{
                fontSize: 13 * zoom,
                fontWeight: "bold",
                textTransform: "uppercase",
                color: "#777777",
                textAlign: "left",
                borderBottomWidth: 2 * zoom,
                borderBottomStyle: "solid",
                width: invoiceData.config.taxRate ? "12%" : "15%",
              }}
            >
              {translations[language].unit_cost}
            </th>
            <th
              style={{
                fontSize: 13 * zoom,
                fontWeight: "bold",
                textTransform: "uppercase",
                color: "#777777",
                textAlign: "left",
                borderBottomWidth: 2 * zoom,
                borderBottomStyle: "solid",
                width: invoiceData.config.taxRate ? "12%" : "15%",
              }}
            >
              {translations[language].qty}
            </th>
            <th
              style={{
                fontSize: 13 * zoom,
                fontWeight: "bold",
                textTransform: "uppercase",
                color: "#777777",
                textAlign: "left",
                borderBottomWidth: 2 * zoom,
                borderBottomStyle: "solid",
                width: invoiceData.config.taxRate ? "12%" : "15%",
              }}
            >
              {translations[language].amount}
            </th>
            {invoiceData.config.taxRate !== 0 && (
              <>
                <th
                  style={{
                    fontSize: 13 * zoom,
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    color: "#777777",
                    textAlign: "left",
                    borderBottomWidth: 2 * zoom,
                    borderBottomStyle: "solid",
                    width: "12%",
                  }}
                >
                  {translations[language].tax}
                </th>
                <th
                  style={{
                    fontSize: 13 * zoom,
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    color: "#777777",
                    textAlign: "left",
                    borderBottomWidth: 2 * zoom,
                    borderBottomStyle: "solid",
                    width: "12%",
                    whiteSpace: "nowrap",
                  }}
                >
                  {translations[language].amountWTax}
                </th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {invoiceData.items.map((item) => {
            return (
              <tr
                style={{
                  fontSize: 13 * zoom,
                }}
              >
                <td style={{ paddingTop: 5 * zoom, paddingBottom: 5 * zoom }}>
                  {item.name}
                </td>
                <td style={{ paddingTop: 5 * zoom, paddingBottom: 5 * zoom }}>
                  {getFormattedAmount(
                    item.unitCost,
                    invoiceData.config.currency
                  )}
                </td>
                <td style={{ paddingTop: 5 * zoom, paddingBottom: 5 * zoom }}>
                  {item.qty}
                </td>
                <td style={{ paddingTop: 5 * zoom, paddingBottom: 5 * zoom }}>
                  {getFormattedAmount(
                    item.unitCost * item.qty,
                    invoiceData.config.currency
                  )}
                </td>
                {invoiceData.config.taxRate !== 0 && (
                  <>
                    <td
                      style={{ paddingTop: 5 * zoom, paddingBottom: 5 * zoom }}
                    >
                      {getFormattedAmount(
                        (item.unitCost *
                          item.qty *
                          invoiceData.config.taxRate) /
                          100,
                        invoiceData.config.currency
                      )}
                    </td>
                    <td
                      style={{ paddingTop: 5 * zoom, paddingBottom: 5 * zoom }}
                    >
                      {getFormattedAmount(
                        item.unitCost *
                          item.qty *
                          (1 + invoiceData.config.taxRate / 100),
                        invoiceData.config.currency
                      )}
                    </td>
                  </>
                )}
              </tr>
            );
          })}
          {extraItems && extraItems.addItem && (
            <tr>
              <td colSpan={invoiceData.config.taxRate ? 6 : 4}>
                {extraItems.addItem}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div
        style={{
          marginTop: 40 * zoom,
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
          }}
        >
          <div
            style={{
              fontSize: 13 * zoom,
              fontWeight: "bold",
              textTransform: "uppercase",
              color: "#777777",
              textAlign: "left",
            }}
          >
            {translations[language].total}
          </div>
          <div
            style={{
              fontSize: 42 * zoom,
              fontWeight: "bold",
              textTransform: "uppercase",
              color: "#777777",
              textAlign: "left",
            }}
          >
            {getFormattedAmount(
              getInvoiceTotal(invoiceData),
              invoiceData.config.currency
            )}
          </div>
        </div>

        <div
          style={{
            position: "absolute",
            left: zoom * 500,
          }}
        >
          <div
            style={{
              fontSize: 13 * zoom,
              fontWeight: "bold",
              textTransform: "uppercase",
              color: "#777777",
              textAlign: "left",
              marginTop: 10 * zoom,
            }}
          >
            {translations[language].subtotal}:
            <span
              style={{
                fontWeight: "normal",
                paddingLeft: 5 * zoom,
              }}
            >
              {getFormattedAmount(
                getInvoiceSubTotal(invoiceData),
                invoiceData.config.currency
              )}
            </span>
          </div>
          <div
            style={{
              fontSize: 13 * zoom,
              fontWeight: "bold",
              textTransform: "uppercase",
              color: "#777777",
              textAlign: "left",
              marginTop: 10 * zoom,
            }}
          >
            {translations[language].tax_rate}:
            <span
              style={{
                fontWeight: "normal",
                paddingLeft: 5 * zoom,
              }}
            >
              {invoiceData.config.taxRate}%
            </span>
          </div>
          <div
            style={{
              fontSize: 13 * zoom,
              fontWeight: "bold",
              textTransform: "uppercase",
              color: "#777777",
              textAlign: "left",
              marginTop: 10 * zoom,
            }}
          >
            {translations[language].tax}:
            <span
              style={{
                fontWeight: "normal",
                paddingLeft: 5 * zoom,
              }}
            >
              {getFormattedAmount(
                getInvoiceTax(invoiceData),
                invoiceData.config.currency
              )}
            </span>
          </div>
          <div
            style={{
              fontSize: 13 * zoom,
              fontWeight: "bold",
              textTransform: "uppercase",
              color: "#777777",
              textAlign: "left",
              marginTop: 10 * zoom,
            }}
          >
            {translations[language].total}:
            <span
              style={{
                fontWeight: "normal",
                paddingLeft: 5 * zoom,
              }}
            >
              {getFormattedAmount(
                getInvoiceTotal(invoiceData),
                invoiceData.config.currency
              )}
            </span>
          </div>
        </div>
      </div>
    </div>,
  ];
};

export const invoiceData = {
  config: {
    series: "ABC",
    number: "0001",
    date: format(new Date(), "dd.MM.yyyy"),
    taxRate: 0,
    currency: "EUR",
    dueDate: format(new Date(), "dd.MM.yyyy"),
    language: "en",
    otherCurrency: "",
    userVariables: {
      accent: "#42468f",
      additionalInfo: "",
      displayTotalInOtherCurrency: "none",
      sellerFontSize: 18,
    },
  },

  content: {
    seller: {
      name: "Seller Inc.",
      address: "seller address here",
      phone: "555-55-55",
      additionalInfo: ``,
    },
    buyer: {
      name: "Buyer Inc.",
      address: "buyer address here",
      phone: "555-55-55",
      additionalInfo: ``,
    },
  },
  items: [],
};
