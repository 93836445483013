import { configureStore } from "@reduxjs/toolkit";
import clientsReducer from "./clientsSlice";
import checkoutReducer from "./checkoutSlice";
import editorReducer from "./editorSlice";
import profileReducer from "./profileSlice";
import settingsReducer from "./settingsSlice";
import inventoriesReducer from "./inventoriesSlice";

export const store = configureStore({
  reducer: {
    profile: profileReducer,
    checkout: checkoutReducer,
    clients: clientsReducer,
    editor: editorReducer,
    settings: settingsReducer,
    inventories: inventoriesReducer,
  },
});
