const commonStrings = {
  en: {
    invoiceTitle: "Invoice",
    invoiceSeriesTitle: "Invoice Series",
    invoiceNumberTitle: "Invoice Number",
    invoiceDateTitle: "Date of Issue",
    invoiceDueDateTitle: "Due Date",
    sellerTitle: "Seller",
    billedToTitle: "Billed To",
    descriptionHeader: "Description",
    unitCostHeader: "Unit Cost",
    qtyHeader: "Qty",
    amountHeader: "Amount",
    taxHeader: "Tax",
    amountWTaxHeader: "Amount + Tax",
    invoiceTotalTitle: "Invoice total",
    invoiceSubtotalTitle: "Subtotal",
    invoiceTaxRateTitle: "Tax rate",
    invoiceTaxTitle: "Tax",
    exchangeRateTitle: "Exchange rate",
  },
  fr: {
    invoiceTitle: "Facture",
    invoiceSeriesTitle: "Série de factures",
    invoiceNumberTitle: "Numéro de la facture",
    invoiceDateTitle: "Date d'émission",
    invoiceDueDateTitle: "Date d'échéance",
    sellerTitle: "Vendeur",
    billedToTitle: "Facturé à",
    descriptionHeader: "Description de la facture",
    unitCostHeader: "Coût unitaire",
    qtyHeader: "Qté",
    amountHeader: "Montant",
    taxHeader: "Taxe",
    amountWTaxHeader: "Montant + taxe",
    invoiceTotalTitle: "Total de la facture",
    invoiceSubtotalTitle: "Sous-total",
    invoiceTaxRateTitle: "Taux d'imposition",
    invoiceTaxTitle: "Taxe",
    exchangeRateTitle: "Taux de change",
  },
  de: {
    invoiceTitle: "Rechnung",
    invoiceSeriesTitle: "Rechnungsserie",
    invoiceNumberTitle: "Rechnungsnummer",
    invoiceDateTitle: "Datum der Ausstellung",
    invoiceDueDateTitle: "Fälligkeitsdatum",
    sellerTitle: "Verkäufer",
    billedToTitle: "Abgerechnet an",
    descriptionHeader: "Beschreibung",
    unitCostHeader: "Kosten pro Einheit",
    qtyHeader: "Menge",
    amountHeader: "Betrag",
    taxHeader: "Steuer",
    amountWTaxHeader: "Betrag + Steuer",
    invoiceTotalTitle: "Rechnungssumme",
    invoiceSubtotalTitle: "Zwischensumme",
    invoiceTaxRateTitle: "Steuersatz",
    invoiceTaxTitle: "Steuer",
    exchangeRateTitle: "Umrechnungskurs",
  },
  ja: {
    invoiceTitle: "インボイス",
    invoiceSeriesTitle: "インボイスシリーズ",
    invoiceNumberTitle: "インボイス番号",
    invoiceDateTitle: "発行日",
    invoiceDueDateTitle: "期限日",
    sellerTitle: "出品者",
    billedToTitle: "請求先",
    descriptionHeader: "商品説明",
    unitCostHeader: "単位費用",
    qtyHeader: "数量",
    amountHeader: "金額",
    taxHeader: "税金",
    amountWTaxHeader: "金額＋消費税",
    invoiceTotalTitle: "インボイス合計",
    invoiceSubtotalTitle: "小計",
    invoiceTaxRateTitle: "税率",
    invoiceTaxTitle: "税金",
    exchangeRateTitle: "為替レート",
  },
  ro: {
    invoiceTitle: "Factură",
    invoiceNumberTitle: "Număr factură",
    invoiceDateTitle: "Data emiterii",
    invoiceDueDateTitle: "Termen de plată",
    billedToTitle: "Client",
    sellerTitle: "Furnizor",
    descriptionHeader: "Descriere",
    unitCostHeader: "Cost unit.",
    qtyHeader: "Cant.",
    amountHeader: "Preț",
    taxHeader: "TVA",
    amountWTaxHeader: "Preț cu TVA",
    invoiceTotalTitle: "Total factură",
    invoiceSubtotalTitle: "Total fără TVA",
    invoiceTaxRateTitle: "TVA (%)",
    invoiceTaxTitle: "TVA",
    exchangeRateTitle: "Curs valutar",
  },
};

export default commonStrings;
