import React, { useEffect, useState } from "react";
import { signOut as firebaseSignOut } from "firebase/auth";
import {
  DangerButton,
  FlexCol,
  InfoBar,
  Input,
  Row,
  ScreenHeading,
  ScreenWrapper,
  SecondaryHeading,
  Section,
  Separator,
} from "../../../lib/components";
import { useTranslation } from "react-i18next";
import Modal from "../../../components/modal/Modal";
import { toast } from "react-toastify";
import { useAuthState } from "react-firebase-hooks/auth";
import getFirebase, { auth } from "../../../firebase";
import { bDeleteAccount, bGetSignOut } from "../../../backend/auth";
import { useHistory } from "react-router-dom";
import { clearProfile } from "../../../redux/profileSlice";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../components/primitives/Spinner";

const Account = () => {
  const [user] = useAuthState(auth);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const subscriptionType = useSelector(
    (state) => state.profile.subscriptionType
  );

  const { t } = useTranslation();
  const history = useHistory();

  const doDeleteAccount = () => {
    setIsLoading(true);
    bDeleteAccount().then(() => {
      toast("Account deleted successfully.");
      firebaseSignOut(auth).then(() => {
        setIsLoading(false);
        dispatch(clearProfile());
        history.push("/account-deleted");
      });
    });
  };

  useEffect(() => {
    window.onbeforeunload = function (e) {
      return "Are you sure you want to leave? You are in the middle of something.";
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  return (
    <ScreenWrapper>
      <Row>
        <FlexCol flex={1}>
          <ScreenHeading>{t("account_settings")}</ScreenHeading>
        </FlexCol>
      </Row>
      <Separator size={16} />

      <Section>
        <SecondaryHeading>{t("your_account_info")}</SecondaryHeading>
        <div>
          <Row gap={16}>
            <FlexCol flex={0}>{t("Email")}</FlexCol>
            <FlexCol flex={4}>
              <Input value={user.email} disabled />
            </FlexCol>
          </Row>
        </div>
      </Section>

      <Separator size={40} />

      <Section>
        <SecondaryHeading>{t("delete_your_account")}</SecondaryHeading>
        <div>
          Note that, once you delete your account,{" "}
          <strong>
            all your invoices and information will be deleted irreversibly.
          </strong>
        </div>
        <Separator size={32} />
        <div>
          <DangerButton
            onClick={
              subscriptionType !== "free"
                ? () => {}
                : () => setShowDeleteModal(true)
            }
            disabled={subscriptionType !== "free"}
          >
            delete account
          </DangerButton>
        </div>
        {subscriptionType !== "free" && (
          <>
            <Separator />
            <InfoBar>
              If you want to delete your account, you need to cancel your
              subscription first.
            </InfoBar>
          </>
        )}
      </Section>

      <Modal
        title={t("delete_your_account")}
        isVisible={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={() => doDeleteAccount()}
        confirmLabel={
          isLoading ? <Spinner size={14} /> : t("yes_delete_account")
        }
        danger
      >
        <div>
          Are you sure you want to delete your account?{" "}
          <strong>You cannot reverse this action.</strong>
        </div>
      </Modal>
    </ScreenWrapper>
  );
};

export default Account;
