import styled from "styled-components";
import { useState } from "react";
import { shakeY } from "../animations";

const TooltipText = styled.span`
  display: ${({ touched }) => (touched ? "none" : "inline-block")};
  filter: opacity(${({ touched }) => (touched ? "0" : "1")});
  background-color: black;
  color: #ffffff;
  text-align: center;
  padding: 8px;
  border-radius: 8px;
  font-size: 13px;
  min-width: 140px;
  left: 0;
  bottom: 0;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1111111111;
  transition: filter 0.15s ease-in;

  animation-name: ${shakeY};
  animation-duration: 2s;
  animation-iteration-count: infinite;

  &:hover {
    display: ${({ touched }) => (touched ? "none" : "inline-block")};
    filter: opacity(1);
  }
`;

const TooltipTextWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  transform: translateY(-100%) translateY(-10px);
`;

const TooltipWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const ForcedTooltip = ({ children, text, ...props }) => {
  const [touched, setTouched] = useState(false);
  if (!text) {
    return children;
  }

  return (
    <TooltipWrapper onMouseOver={() => setTouched(true)} {...props}>
      {children}
      <TooltipTextWrapper>
        <TooltipText touched={touched}>{text}</TooltipText>
      </TooltipTextWrapper>
    </TooltipWrapper>
  );
};

export default ForcedTooltip;
