import React from "react";
import "./Loader.scss";

const Loader = ({ size, small }) => {
  return (
    <div
      className="sk-folding-cube"
      style={
        size
          ? { width: size, height: size }
          : small
          ? { width: "20px", height: "20px" }
          : { width: "40px", height: "40px" }
      }
    >
      <div className="sk-cube1 sk-cube" />
      <div className="sk-cube2 sk-cube" />
      <div className="sk-cube4 sk-cube" />
      <div className="sk-cube3 sk-cube" />
    </div>
  );
};

export const LoaderWithWrapper = ({ size }) => {
  return (
    <div className={"loader-with-wrapper"}>
      <Loader size={size} />
    </div>
  );
};

export default Loader;
