import { format, parseISO } from "date-fns";
import { enUS, ro, de, ja, fr } from "date-fns/locale";
const dateLocales = { ro: ro, en: enUS, de: de, fr: fr, ja: ja };
const dateFormats = {
  ro: "do MMMM yyyy",
  en: "MMMM do, yyyy",
  de: "MMMM do, yyyy",
  fr: "MMMM do, yyyy",
  ja: "MMMM do, yyyy",
};

export const getCookie = (cname) => {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const getFormattedAmount = (amount, currency) => {
  try {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency || "USD",
    });

    return formatter.format(amount);
  } catch (e) {
    return `${currency} ${amount}`;
  }
};

export const getInvoiceTotal = (invoiceData) => {
  if (invoiceData.items.length === 0) {
    return 0;
  }

  return (
    invoiceData.items.reduce(
      (total, item) =>
        total + (parseFloat(item.qty) > 0 ? item.qty : 1) * item.unitCost,
      0
    ) *
    (1 + invoiceData.config.taxRate / 100)
  );
};

export const getInvoiceTax = (invoiceData) => {
  return getInvoiceTotal(invoiceData) - getInvoiceSubTotal(invoiceData);
};

export const getInvoiceSubTotal = (invoiceData) => {
  if (invoiceData.items.length === 0) {
    return 0;
  }

  return invoiceData.items.reduce(
    (total, item) =>
      total + (parseFloat(item.qty) > 0 ? item.qty : 1) * item.unitCost,
    0
  );
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const getFormattedDate = (dateString, locale = "en") => {
  try {
    return format(parseISO(dateString), dateFormats[locale], {
      locale: dateLocales[locale] || dateLocales["en"],
    });
  } catch {
    return dateString;
  }
};

export const getInitials = (name) => {
  if (!name) {
    return "";
  }

  return name
    .split(" ")
    .map((n) => n[0])
    .join("")
    .substr(0, 2);
};

export const isObjectEmpty = (obj) => {
  if (!obj) {
    return false;
  }

  return Object.keys(obj).length === 0;
};

export const watermarkHtml2 = `
<div style="position: absolute; z-index: 1111; bottom: 0; left: 0; right: 0; height: 14px; width: 448px; background-color: #2c3e50; color: #ffffff; display: flex; flex-direction: row; align-items: center; justify-content: center; font-size: 8px;">This invoice was generated using VisualInvoicer - www.visualinvoicer.com</div>
`;

export const watermarkHtml = `

`;

export const formatDateString = (d, language) =>
  format(new Date(d), "dd MMMM yyyy", {
    locale: dateLocales[language || "en"],
  });

export const getTextColorBasedOnBgColor = (
  bgColor,
  lightColor = "#fff",
  darkColor = "#000"
) => {
  const color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
  const r = parseInt(color.substring(0, 2), 16); // hexToR
  const g = parseInt(color.substring(2, 4), 16); // hexToG
  const b = parseInt(color.substring(4, 6), 16); // hexToB
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? darkColor : lightColor;
};
