import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import styled from "styled-components";
import colors from "../../lib/colors";
import componentColors from "../../lib/component-colors";
import { noSelect } from "../../lib/components";
import Spinner, { SpinnerWrapper } from "../../components/primitives/Spinner";
import { media, mediaQuery } from "../../components/mixins";

export const Page = styled.div`
  background-color: #ffffff;
  width: 63vw;
  max-width: 1000px;
  border-radius: 4px;
  box-shadow: 0 0 5px ${colors.shadowGrey};
  margin: 40px auto 40px auto;
  min-height: ${({ pageHeight }) => pageHeight}px;
  position: relative;
  overflow: auto;

  ${media.belowMedium} {
    width: 90vw;
  }
`;

export const ControlButtonIconWrapper = styled.div``;

export const DisabledControlButton = styled.button`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  background-color: ${({ theme }) => theme.colors.controlButton};
  color: ${({ theme }) => theme.colors.controlButtonText};
  border: 0;
  flex: 1;
  border-right: solid 1px ${({ theme }) => theme.colors.controlButtonBorder};
  font-weight: 400;
  min-width: 110px;
  font-size: 12px;
  position: relative;
`;

export const ControlButton = styled.button`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  background-color: ${({ theme }) => theme.colors.controlButton};
  color: ${({ theme }) => theme.colors.controlButtonIcon};
  border: 0;
  flex: 1;
  border-right: solid 1px ${({ theme }) => theme.colors.controlButtonBorder};
  font-weight: 400;
  min-width: 110px;
  font-size: 12px;
  position: relative;

  ${noSelect};

  :disabled {
    background-color: red !important;
  }

  svg {
    width: 20px !important;
    height: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: all 100ms ease-in;
  }

  .star {
    display: block !important;
    svg {
      width: 16px !important;
      height: 16px;
    }
  }

  div {
    margin-top: 8px;
    white-space: nowrap;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 100ms ease-in;
    color: ${({ theme }) => theme.colors.controlButtonText};
  }

  transition: all 100ms ease-in;

  &:hover,
  &:focus {
    //transform: scale(1.1);
    //border-radius: 8px;
    //border-right: 0;
    //z-index: 11111;

    background-color: ${({ hover }) =>
      hover === "danger"
        ? componentColors.controlButtonDanger
        : componentColors.controlButtonHover};
    cursor: pointer;

    svg {
      top: 40%;
      fill: #ffffff !important;
      stroke: #ffffff !important;
      color: #ffffff !important;
    }

    .star {
      opacity: 0 !important;
    }

    div {
      display: block;
      opacity: 1;
    }
  }

  &:active {
    filter: brightness(0.9);
  }

  &:last-child {
    border-right: 0;
  }

  ${media.belowMedium} {
    padding: 0 10px;
    min-width: 0;
    font-size: 11px;

    div {
      margin-top: 4px;
    }

    svg {
      width: 14px !important;
      height: 14px;
    }
  }
`;

export const TableWrapper = ({ children }) => {
  return <SimpleBar>{children}</SimpleBar>;
};

export const Table = styled.table`
  display: table;
  width: 100%;
  //background-color: #ffffff;
  border-spacing: 1px;
`;

export const TableHeader = styled.tr`
  display: flex;
  width: auto;
  clear: both;
  font-size: 14px;
  font-weight: bold;
  border-bottom: solid 1px ${({ theme }) => theme.colors.tableBorder};
`;

export const VisibleOnHover = styled.div`
  display: none;
`;

export const TableDiv = styled.div`
  .header {
    padding-bottom: 10px;
    border-bottom: solid 2px ${({ theme }) => theme.colors.tableBorder};
  }
  .row {
    padding: 10px 5px;
  }
  .row:nth-child(even) {
    background: ${({ theme }) => theme.colors.tableRowEven};
  }
  .row:nth-child(odd) {
    background: ${({ theme }) => theme.colors.tableRowOdd};
  }
`;

export const TableRow = styled.tr`
  display: flex;
  width: auto;
  clear: both;

  &:hover {
    background-color: ${({ theme }) => theme.colors.tableRowHover} !important;
    filter: brightness(0.9);
    ${({ clickable }) => clickable && "cursor: pointer;"}

    ${VisibleOnHover} {
      display: block;
    }
  }

  &:active {
    filter: brightness(0.8);
  }

  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.colors.tableRowOdd};
  }

  &:nth-child(even) {
    background-color: ${({ theme }) => theme.colors.tableRowEven};
  }
`;

export const TableHeaderCell = styled.th`
  float: left;
  //display: table-column;
  padding: 12px 8px;
  text-align: left;

  ${media.belowLarge} {
    font-size: 12px;
    ${({ hideMobile }) => hideMobile && "display: none;"}
    ${({ mobileWidth }) => mobileWidth && `width: ${mobileWidth} !important;`}
  }
`;

export const TableCell = styled.td`
  float: left;
  //display: table-column;
  padding: 12px 8px;
  height: 44px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;

  ${media.belowLarge} {
    font-size: 12px;
    ${({ hideMobile }) => hideMobile && "display: none;"}
    ${({ mobileWidth }) => mobileWidth && `width: ${mobileWidth} !important;`}
  }
`;

export const LastTableCell = styled(TableCell)`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const TemplateGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  gap: 16px;

  ${({ responsive }) =>
    responsive &&
    `
    ${media.belowMedium} {
      flex-direction: column; 
    }
  `}
`;

export const TemplateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  ${({ theme, withBorder }) =>
    withBorder &&
    `.template-title { border: solid 1px ${theme.colors.tableBorder};}`}

  div {
    display: flex;
  }

  ${({ highlighted }) =>
    highlighted &&
    `
  img {border: solid 1px #00b894 !important;}`}
  &:hover {
    cursor: pointer;

    button {
      filter: opacity(0.4);
    }

    img,
    .template-title {
      border: solid 1px #00b894;
      filter: brightness(0.9) !important;
    }
  }

  &:active {
    img {
      filter: brightness(0.8);
    }
  }
`;

export const TemplateCheck = styled.div`
  position: absolute;
  top: -12px;
  right: -12px;
  color: #00b894;
  z-index: 1;

  svg {
    width: 24px !important;
    height: 24px;
    background-color: #ffffff;
    border-radius: 50%;
  }
`;

export const TemplateImage = styled.img`
  border: solid 1px ${({ theme }) => theme.colors.tableBorder};
  border-radius: 4px;
  width: 180px;
  height: 257px;
  position: relative;
`;

export const TemplatePreview = styled.img`
  border: solid 1px ${({ theme }) => theme.colors.tableBorder};
  border-radius: 4px;
  width: 80vw;
  max-width: 600px;
  position: relative;
`;

export const TemplateTitle = styled.div`
  border: solid 1px ${({ theme }) => theme.colors.dashboardBg};
  background-color: ${({ theme }) => theme.colors.dashboardBg};
  border-radius: 4px;
  width: 164px;
  height: 241px;
  position: relative;
  font-size: 17px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 10px;
`;

export const TemplateDate = styled.div`
  margin-top: 10px;
  font-size: 12px;
`;

export const TemplateName = styled.div`
  margin-top: 10px;
`;

export const TemplateHoverContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  //display: none;
`;

export const TemplateHover = styled.button`
  font-size: 30px;
  color: #ffffff;
  background-color: ${({ blue }) => (blue ? "#42468f" : "#777777")};
  width: 48px;
  height: 48px;
  line-height: 24px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  filter: opacity(0);
  transition: all 0.15s ease-in;
  border: 0;
  cursor: pointer;
  padding: 0;

  &:focus {
    filter: opacity(0.4);
  }

  &:hover {
    filter: opacity(1) !important;
  }
`;

export const Watermark = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 14px;
  width: 448px;
  background-color: #2c3e50;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 8px;
`;

export const ExportGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
`;

export const ExportType = styled.div`
  min-width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 16px;

  h2 {
    text-align: center;
    margin-bottom: 0;
    color: ${({ theme }) => theme.colors.price};
  }

  button {
    margin-top: 12px;
  }
`;

export const ExportDescription = styled.div`
  font-size: 12px;
  margin: 0 10px 20px 10px;
  text-align: center;
`;

export const ExportPrice = styled.div`
  font-size: 18px;
  text-align: center;
  color: ${({ theme }) => theme.colors.price};
`;
