import { format } from "date-fns";
import { getFormattedAmount } from "./utils";

export const emptyInvoiceData = {
  config: {
    series: "",
    number: "",
    date: format(new Date(), "yyyy-MM-dd"),
    taxRate: 0,
    currency: "EUR",
    dueDate: format(new Date(), "yyyy-MM-dd"),
  },
  content: {
    seller: {
      name: "",
    },
    buyer: {
      name: "",
    },
  },
  items: [],
};

export const subscriptionStatus = {
  active: "active",
  trialing: "trialing",
  past_due: "past_due",
  paused: "paused",
  deleted: "deleted",
};

export const SUBSCRIPTION_TYPE = {
  FREE: "free",
  PREMIUM: "premium",
};

export const HOMEPAGE = "https://www.visualinvoicer.com";

export const PRICE = {
  PREMIUM: {
    price: "9.90",
    unit: "$",
    formattedPrice: getFormattedAmount(9.9, "USD"),
  },
};

export const REFERRER_DISCOUNT = 4; // USD, user who sends their referral link
export const REFERRED_DISCOUNT = 20; // percentage, user who uses the referral link

export const FREE_ACCOUNT_INVOICE_LIMIT = 3;
