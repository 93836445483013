import {
  track as amplitudeTrack,
  identify as amplitudeIdentify,
  Identify,
} from "@amplitude/analytics-browser";

export const pageView = (title, url) => {
  track("Page View", { title, url });
};

export const identify = (userId) => {
  if (!import.meta.env.PROD) {
    console.group();
    console.log("==== Identify ====");
    console.log("User id: " + userId);
    console.groupEnd();
    return;
  }

  const identifyObj = new Identify();
  identifyObj.set("userId", userId);
  amplitudeIdentify(identifyObj);
};

export const track = (event, props) => {
  if (!import.meta.env.PROD) {
    console.group();
    console.log("==== Tracking ====");
    console.log("Event name: " + event);
    console.log(props);
    console.groupEnd();
    return;
  }

  amplitudeTrack(event, props);
};
