import React, { useEffect, useState } from "react";
import { signOut as firebaseSignOut } from "firebase/auth";
import copyToClipboard from "copy-to-clipboard";
import {
  Button,
  DangerButton,
  FlexCol,
  Input,
  Row,
  ScreenHeading,
  ScreenWrapper,
  SecondaryHeading,
  Section,
  SectionText,
  SectionTitle,
  Separator,
} from "../../../lib/components";
import { useTranslation } from "react-i18next";
import Modal from "../../../components/modal/Modal";
import { toast } from "react-toastify";
import { useAuthState } from "react-firebase-hooks/auth";
import getFirebase, { auth } from "../../../firebase";
import { bDeleteAccount, bGetSignOut } from "../../../backend/auth";
import { useHistory } from "react-router-dom";
import { clearProfile } from "../../../redux/profileSlice";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../components/primitives/Spinner";
import { REFERRER_DISCOUNT } from "../../../lib/constants";

const SIGN_UP_URL = "https://app.visualinvoicer.com/";

const Account = () => {
  const [user] = useAuthState(auth);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const profile = useSelector((state) => state.profile.profile);
  const referralInfo = useSelector(
    (state) => state.profile.profile.referralCode
  );
  const { t } = useTranslation();
  const history = useHistory();

  const doDeleteAccount = () => {
    setIsLoading(true);
    bDeleteAccount().then(() => {
      toast("Account deleted successfully.");
      bGetSignOut().then(() => {
        firebaseSignOut(auth).then(() => {
          setIsLoading(false);
          dispatch(clearProfile());
          history.push("/account-deleted");
        });
      });
    });
  };

  const computeTotalReferralsValue = () => {
    if (!referralInfo || !referralInfo.usages) {
      return 0;
    }

    return referralInfo.usages * REFERRER_DISCOUNT;
  };

  return (
    <ScreenWrapper>
      <Row>
        <FlexCol flex={1}>
          <ScreenHeading>{t("refer_a_friend")}</ScreenHeading>
        </FlexCol>
      </Row>
      <Separator size={16} />

      <Section>
        <SectionText>
          By referring a friend, they receive a 20% discount on their first
          premium subscription invoice.
        </SectionText>
        <Separator />
        <SectionText>
          Additionally, when they subscribe to premium, you will also receive
          a 20% discount (USD 4) on your next subscription invoice (with a
          maximum combined discount of 100% and granted you already have a
          premium subscription).
        </SectionText>
      </Section>

      <Separator size={16} />

      <Section>
        <SecondaryHeading>{t("your_referral_link")}</SecondaryHeading>
        <div>
          <Row gap={16}>
            <FlexCol flex={4}>
              <Input
                defaultValue={`${SIGN_UP_URL}?referralId=${profile.referralCode.referralCode}`}
                readOnly
              />
            </FlexCol>
            <FlexCol flex={0}>
              <Button
                onClick={() => {
                  copyToClipboard(
                    `${SIGN_UP_URL}?referralId=${profile.referralCode.referralCode}`
                  );
                  toast("Referral link copied successfully! 🚀");
                }}
              >
                copy to clipboard
              </Button>
            </FlexCol>
          </Row>
        </div>
      </Section>

      <Separator size={16} />

      <Section>
        <SectionTitle>Successful referrals</SectionTitle>
        <Row>
          <FlexCol flex={1}>
            <h2 style={{ margin: 0 }}>
              {referralInfo.usages}{" "}
              {referralInfo.usages !== 1 ? "referrals" : "referral"}
            </h2>
          </FlexCol>
          {/*
          <FlexCol flex={0}>
            <h2 style={{ margin: 0, whiteSpace: "nowrap" }}>
              USD {computeTotalReferralsValue()}
            </h2>
          </FlexCol>
*/}
        </Row>
      </Section>
    </ScreenWrapper>
  );
};

export default Account;
