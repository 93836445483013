import { useSelector } from "react-redux";
import { FREE_ACCOUNT_INVOICE_LIMIT } from "../lib/constants";

const useIsFreeAccountLimitReached = () => {
  const subscriptionType = useSelector(
    (state) => state.profile.subscriptionType
  );
  const subscriptionLoaded = useSelector(
    (state) => state.profile.subscriptionLoaded
  );
  const invoicesLastMonth = useSelector(
    (state) => state.profile.invoicesLastMonth
  );

  return (
    subscriptionLoaded &&
    subscriptionType === "free" &&
    invoicesLastMonth >= FREE_ACCOUNT_INVOICE_LIMIT
  );
};

export default useIsFreeAccountLimitReached;
