import { fetchAndActivate, getValue } from "firebase/remote-config";
import { remoteConfig } from "../firebase";

remoteConfig.settings.minimumFetchIntervalMillis = 60000;

remoteConfig.defaultConfig = {
  enableCardSubscription: false,
  enableLanguageDropdown: false,
  enableReferrals: true,
  enableSinglePurchase: false,
};

fetchAndActivate(remoteConfig);

const featureFlags = {
  get enableCardSubscription() {
    return getValue(remoteConfig, "enableCardSubscription").asBoolean();
  },
  get enableLanguageDropdown() {
    return getValue(remoteConfig, "enableLanguageDropdown").asBoolean();
  },
  get enableReferrals() {
    return getValue(remoteConfig, "enableReferrals").asBoolean();
  },
  get enableSinglePurchase() {
    return getValue(remoteConfig, "enableSinglePurchase").asBoolean();
  },
};

export default featureFlags;
