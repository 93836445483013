import commonStrings from "./commonStrings";

const template = {
  locales: ["en", "ro"],
  variables: [
    {
      name: "accent",
      default: "#4a69bd",
      type: "color",
    },
    {
      name: "sellerFontSize",
      title: "seller/buyer font size",
      min: 18,
      max: 32,
      step: 1,
      default: 18,
      type: "slider",
    },
    {
      name: "additionalInfo",
      title: "additional invoice info",
      default: "",
      type: "text",
    },
  ],
  strings: commonStrings,
  styles: {
    itemRow: {
      fontSize: 13,
    },
    itemRowCell: {
      paddingTop: 5,
      paddingBottom: 5,
    },
    itemDescriptionHeaderCell: {
      fontSize: 13,
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "#777777",
      textAlign: "left",
      borderBottomWidth: 2,
      borderBottomStyle: "solid",
      widthWithTax: "40%",
      widthWithoutTax: "55%",
      whiteSpace: "nowrap",
    },
    itemUnitCostHeaderCell: {
      fontSize: 13,
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "#777777",
      textAlign: "left",
      borderBottomWidth: 2,
      borderBottomStyle: "solid",
      widthWithTax: "12%",
      widthWithoutTax: "15%",
      whiteSpace: "nowrap",
    },

    itemQtyHeaderCell: {
      fontSize: 13,
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "#777777",
      textAlign: "left",
      borderBottomWidth: 2,
      borderBottomStyle: "solid",
      widthWithTax: "12%",
      widthWithoutTax: "15%",
      whiteSpace: "nowrap",
    },

    itemAmountHeaderCell: {
      fontSize: 13,
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "#777777",
      textAlign: "left",
      borderBottomWidth: 2,
      borderBottomStyle: "solid",
      widthWithTax: "12%",
      widthWithoutTax: "15%",
      whiteSpace: "nowrap",
    },

    itemTaxHeaderCell: {
      fontSize: 13,
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "#777777",
      textAlign: "left",
      borderBottomWidth: 2,
      borderBottomStyle: "solid",
      width: "12%",
      whiteSpace: "nowrap",
    },
    itemAmountWithTaxHeaderCell: {
      fontSize: 13,
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "#777777",
      textAlign: "left",
      borderBottomWidth: 2,
      borderBottomStyle: "solid",
      width: "12%",
      whiteSpace: "nowrap",
    },
    mainContainer: {
      position: "absolute",
      top: 72,
      left: 72,
      fontSize: 72,
      fontWeight: "bold",
      textTransform: "uppercase",
      letterSpacing: 4,
    },
    invoiceTitle: {
      color: "{variables__accent}",
    },
    invoiceNumberWrapper: {
      position: "absolute",
      left: 76,
      top: 180,
    },
    invoiceNumberTitle: {
      textTransform: "uppercase",
      color: "#777777",
      fontWeight: "bold",
      fontSize: 14,
      marginBottom: 5,
    },
    invoiceNumber: {
      textTransform: "uppercase",
      color: "#000000",
      fontSize: 14,
    },
    dateOfIssueWrapper: {
      position: "absolute",
      left: 250,
      top: 180,
    },
    dateOfIssueTitle: {
      textTransform: "uppercase",
      color: "#777777",
      fontWeight: "bold",
      fontSize: 14,
      marginBottom: 5,
    },
    dateOfIssue: {
      textTransform: "uppercase",
      color: "#000000",
      fontSize: 14,
    },
    dueDateWrapper: {
      position: "absolute",
      left: 420,
      top: 180,
    },
    dueDateTitle: {
      textTransform: "uppercase",
      color: "#777777",
      fontWeight: "bold",
      fontSize: 14,
      marginBottom: 5,
    },
    dueDate: {
      textTransform: "uppercase",
      color: "#000000",
      fontSize: 14,
    },
    billedToWrapper: {
      position: "absolute",
      left: 76,
      top: 260,
    },
    billedToTitle: {
      textTransform: "uppercase",
      color: "#777777",
      fontWeight: "bold",
      fontSize: 14,
      whiteSpace: "pre-line",
    },
    billedTo: {
      textTransform: "uppercase",
      color: "#000000",
      fontSize: 14,
      maxWidth: 400,
    },
    sellerWrapper: {
      position: "absolute",
      left: 576,
      top: 260,
      maxWidth: 350,
    },
    sellerName: {
      textTransform: "uppercase",
      color: "#777777",
      fontWeight: "bold",
      fontSize: 22,
      marginBottom: 10,
    },
    variableSellerName: {
      fontSize: "{variables__sellerFontSize}",
    },
    sellerInfo: {
      textTransform: "uppercase",
      color: "#000000",
      fontSize: 14,
      marginBottom: 10,
      maxWidth: 400,
    },
    productTableWrapper: {
      position: "absolute",
      top: 500,
      left: 76,
    },
    productTable: {
      width: 848,
      borderCollapse: "collapse",
    },
    bottomInfoWrapper: {
      marginTop: 40,
      position: "relative",
    },
    bottomLeftInfo: {
      position: "absolute",
    },
    bottomLeftInfoTitle: {
      fontSize: 13,
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "#777777",
      textAlign: "left",
    },
    bottomLeftInfoContent: {
      fontSize: 42,
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "#777777",
      textAlign: "left",
    },
    bottomRightInfo: {
      position: "absolute",
      left: 500,
    },
    bottomRightInfoTitle: {
      fontSize: 13,
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "#777777",
      textAlign: "left",
      marginTop: 10,
    },
    bottomRightInfoTitleExchangeRate: {
      fontSize: 13,
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "#777777",
      textAlign: "left",
      marginTop: 10,
      display: "{variables__displayTotalInOtherCurrency}",
      displayCondition: (invoiceData) =>
        invoiceData?.config?.otherCurrency &&
        invoiceData?.config?.otherCurrency !== invoiceData?.config?.currency
          ? "block"
          : "none",
    },
    invoiceTotalInOtherCurrency: {
      fontSize: 16,
      display: "{variables__displayTotalInOtherCurrency}",
      displayCondition: (invoiceData) =>
        invoiceData?.config?.otherCurrency &&
        invoiceData?.config?.otherCurrency !== invoiceData?.config?.currency
          ? "block"
          : "none",
    },
    invoiceTotalInOtherCurrencySmall: {
      marginLeft: 120,
      display: "{variables__displayTotalInOtherCurrency}",
      displayCondition: (invoiceData) =>
        invoiceData?.config?.otherCurrency &&
        invoiceData?.config?.otherCurrency !== invoiceData?.config?.currency
          ? "block"
          : "none",
    },
    bottomRightInfoContent: {
      fontWeight: "normal",
      paddingLeft: 5,
    },
    additionalInfo: {
      whiteSpace: "pre",
      fontSize: 14,
      paddingTop: 40,
    },
    footer: {
      position: "absolute",
      bottom: 48,
      left: 60,
      right: 60,
      fontSize: 13,
      whiteSpace: "pre",
      paddingBottom: 8,
      borderBottomWidth: 2,
      borderBottomStyle: "solid",
      borderBottomColor: "#777777",
    },
  },
  template:
    "<div\n" +
    "      style={styles__mainContainer}\n" +
    "    >\n" +
    "      <span style={styles__invoiceTitle}>{invoiceTitle}</span>\n" +
    "    </div>\n" +
    "    <div\n" +
    "      style={styles__invoiceNumberWrapper}\n" +
    '      id="invoice_number"\n' +
    "    >\n" +
    "      <div\n" +
    "        style={styles__invoiceNumberTitle}\n" +
    "      >\n" +
    "        {invoiceNumberTitle}\n" +
    "      </div>\n" +
    "\n" +
    "      <div\n" +
    "        style={styles__invoiceNumber}\n" +
    "      >\n" +
    "        {invoiceSeriesAndNumber}\n" +
    "      </div>\n" +
    "    </div>\n" +
    "    <div\n" +
    "      style={styles__dateOfIssueWrapper}\n" +
    '      id="date_of_issue"\n' +
    "    >\n" +
    "      <div\n" +
    "        style={styles__dateOfIssueTitle}\n" +
    "      >\n" +
    "        {invoiceDateTitle}\n" +
    "      </div>\n" +
    "      <div\n" +
    "        style={styles__dateOfIssue}\n" +
    "      >\n" +
    "        {invoiceDate}\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div\n" +
    "      style={styles__dueDateWrapper}\n" +
    '      id="due_date"\n' +
    "    >\n" +
    "      <div\n" +
    "        style={styles__dueDateTitle}\n" +
    "      >\n" +
    "        {invoiceDueDateTitle}\n" +
    "      </div>\n" +
    "\n" +
    "      <div\n" +
    "        style={styles__dueDate}\n" +
    "      >\n" +
    "        {invoiceDueDate}\n" +
    "      </div>\n" +
    "    </div>\n" +
    "    <div\n" +
    "      style={styles__billedToWrapper}\n" +
    '      id="billed_to"\n' +
    "    >\n" +
    "      <div\n" +
    "        style={styles__billedToTitle}\n" +
    "      >\n" +
    "        {billedToTitle}\n" +
    "      </div>\n" +
    "\n" +
    "      <div\n" +
    "        style={styles__billedTo}\n" +
    "      >\n" +
    "      <div\n" +
    "        style={styles__sellerName}\n" +
    "      >\n" +
    "        <span style={styles__variableSellerName}>{buyerName}</span></div>\n" +
    "        <div>{buyerAddress}</div>\n" +
    "        <div>{buyerPhone}</div>\n" +
    '        <div style="white-space: pre-line;">{buyerAdditionalInfo}</div>\n' +
    "      </div>\n" +
    "    </div>\n" +
    "    <div\n" +
    "      style={styles__sellerWrapper}\n" +
    '      id="seller"\n' +
    "    >\n" +
    "      <div\n" +
    "        style={styles__billedToTitle}\n" +
    "      >\n" +
    "        {sellerTitle}\n" +
    "      </div>\n" +
    "      <div\n" +
    "        style={styles__sellerName}\n" +
    "      >\n" +
    "        <span style={styles__variableSellerName}>{sellerName}</span>\n" +
    "      </div>\n" +
    "\n" +
    "      <div\n" +
    "        style={styles__sellerInfo}\n" +
    "      >\n" +
    "        <div>{sellerAddress}</div>\n" +
    "        <div>{sellerPhone}</div>\n" +
    '        <div style="white-space: pre-line;">{sellerAdditionalInfo}</div>\n' +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div\n" +
    "      style={styles__productTableWrapper}\n" +
    "    >\n" +
    "      <table\n" +
    "        style={styles__productTable}\n" +
    '      id="products_table"\n' +
    "      >\n" +
    "        <thead>\n" +
    "          <tr>\n" +
    "            <th\n" +
    "              style={styles__itemDescriptionHeaderCell}\n" +
    "            >\n" +
    "              {descriptionHeader}\n" +
    "            </th>\n" +
    "            <th\n" +
    "              style={styles__itemUnitCostHeaderCell}\n" +
    "            >\n" +
    "              {unitCostHeader}\n" +
    "            </th>\n" +
    "            <th\n" +
    "              style={styles__itemQtyHeaderCell}\n" +
    "            >\n" +
    "              {qtyHeader}\n" +
    "            </th>\n" +
    "            <th\n" +
    "              style={styles__itemAmountHeaderCell}\n" +
    "            >\n" +
    "              {amountHeader}\n" +
    "            </th>\n" +
    "            <th\n" +
    "                style={styles__itemTaxHeaderCell}\n" +
    '                class="hide-for-zero-tax-rate"\n' +
    "            >\n" +
    "              {taxHeader}\n" +
    "            </th>\n" +
    "            <th\n" +
    "                style={styles__itemAmountWithTaxHeaderCell}\n" +
    '                class="hide-for-zero-tax-rate"\n' +
    "            >\n" +
    "              {amountWTaxHeader}\n" +
    "            </th>\n" +
    "          </tr>\n" +
    "        </thead>\n" +
    "        <tbody>\n" +
    "            {invoiceItems}\n" +
    "        </tbody>\n" +
    "      </table>\n" +
    "      {addItemButton}\n<div style={styles__additionalInfo}>{variables__additionalInfo}</div>" +
    "      <div\n" +
    "        style={styles__bottomInfoWrapper}\n" +
    "      >\n" +
    "        <div\n" +
    "          style={styles__bottomLeftInfo}\n" +
    "        >\n" +
    "          <div\n" +
    "            style={styles__bottomLeftInfoTitle}\n" +
    "          >\n" +
    "            {invoiceTotalTitle}\n" +
    "          </div>\n" +
    "          <div\n" +
    "            style={styles__bottomLeftInfoContent}\n" +
    "          >\n" +
    "            {invoiceTotal}\n" +
    "          </div>\n" +
    "          <div style={styles__invoiceTotalInOtherCurrency}>{invoiceTotalInOtherCurrency}</div>" +
    "        </div>\n" +
    "\n" +
    "        <div\n" +
    "          style={styles__bottomRightInfo}\n" +
    "        >\n" +
    "          <div\n" +
    "            style={styles__bottomRightInfoTitle}\n" +
    "          >\n" +
    "            {invoiceSubtotalTitle}:\n" +
    "            <span\n" +
    "              style={styles__bottomRightInfoContent}\n" +
    "            >\n" +
    "              {invoiceSubtotal}\n" +
    "            </span>\n" +
    "          </div>\n" +
    "          <div\n" +
    "            style={styles__bottomRightInfoTitle}\n" +
    "            >\n" +
    "            {invoiceTaxRateTitle}:\n" +
    "            <span\n" +
    "              style={styles__bottomRightInfoContent}\n" +
    "              >\n" +
    "              {invoiceTaxRate}%\n" +
    "            </span>\n" +
    "          </div>\n" +
    "          <div\n" +
    "            style={styles__bottomRightInfoTitle}\n" +
    "            >\n" +
    "            {invoiceTaxTitle}:\n" +
    "            <span\n" +
    "              style={styles__bottomRightInfoContent}\n" +
    "              >\n" +
    "              {invoiceTax}\n" +
    "            </span>\n" +
    "          </div>\n" +
    "          <div\n" +
    "            style={styles__bottomRightInfoTitle}\n" +
    "            >\n" +
    "            {invoiceTotalTitle}:\n" +
    "            <span\n" +
    "              style={styles__bottomRightInfoContent}\n" +
    "              >\n" +
    "              {invoiceTotal}\n" +
    "              <div style={styles__invoiceTotalInOtherCurrencySmall}>{invoiceTotalInOtherCurrency}</div>" +
    "            </span>\n" +
    "          </div>\n" +
    "          <div\n" +
    "            style={styles__bottomRightInfoTitleExchangeRate}\n" +
    "            >\n" +
    "            {exchangeRateTitle}:\n" +
    "            <span\n" +
    "              style={styles__bottomRightInfoContent}\n" +
    "              >\n" +
    "              {exchangeRateString}\n" +
    "            </span>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>" +
    "   <div style={styles__footer}>{footer}</div>",
  itemRow:
    "<tr\n" +
    "style={styles__itemRow}\n" +
    ">\n" +
    "    <td style={styles__itemRowCell}>\n" +
    "    {itemName}\n" +
    "    </td>\n" +
    "    <td style={styles__itemRowCell}>\n" +
    "    {itemUnitCost}\n" +
    "    </td>\n" +
    "    <td style={styles__itemRowCell}>\n" +
    "    {itemQuantity}\n" +
    "    </td>\n" +
    "    <td style={styles__itemRowCell}>\n" +
    "    {itemTotalWithoutTax}\n" +
    "    </td>\n" +
    "    <td\n" +
    "        style={styles__itemRowCell}\n" +
    '        class="hide-for-zero-tax-rate"\n' +
    "    >\n" +
    "    {itemTax}\n" +
    "    </td>\n" +
    "    <td\n" +
    "        style={styles__itemRowCell}\n" +
    '        class="hide-for-zero-tax-rate"\n' +
    "    >\n" +
    "    {itemTotalWithTax}\n" +
    "    </td>\n" +
    "</tr>\n",
};

export default template;
