import colors from "./colors";

const componentColors = {
  controlButton: '#30336b',
  controlButtonText: '#ffffff',
  controlButtonHover: '#10ac84',
  controlButtonBorder: '#130f40',
  controlButtonDanger: '#ee5253',
}

export default componentColors;
