import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import { signOut as firebaseSignOut } from "firebase/auth";
import "./App.scss";
import Editor from "./screens/editor/Editor";
import Navbar from "./components/navbar/Navbar";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import getFirebase, { auth } from "./firebase";
import React, { useState, useEffect } from "react";
import Login from "./screens/login/Login";
import { useAuthState } from "react-firebase-hooks/auth";
import Home from "./screens/home/Home";
import { bPostIdTokenToSessionLogin } from "./backend/auth";
import { getCookie } from "./lib/utils";
import { bGetProfile } from "./backend/profile";
import { AnimatePresence, motion } from "framer-motion";
import { LoaderWithWrapper } from "./components/loader/Loader";
import i18n from "i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  setProfile,
  clearProfile,
  setSubscription,
  setInvoicesLastMonth,
  profileSelectors,
} from "./redux/profileSlice";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { darkTheme, lightTheme } from "./theme";
import { getThemeFromStorage, saveThemeToStorage } from "./backend/storage";
import { setTheme } from "./redux/settingsSlice";
import { bGetSubscription } from "./backend/subscription";
import { identify } from "./lib/analytics";

const GlobalStyles = createGlobalStyle`
  a {
    color: #0099ff;
  }
`;

const App = () => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showLoginFromEditor, setShowLoginFromEditor] = useState(false);
  const [user, loading] = useAuthState(auth);
  const [showedToast, setShowedToast] = useState(false);
  const [retrievingProfile, setRetrievingProfile] = useState(true);
  const [retrievedProfile, setRetrievedProfile] = useState(false);
  const dispatch = useDispatch();

  const signOut = () => {
    firebaseSignOut(auth).then(() => {
      dispatch(clearProfile());
    });
  };

  const location = useLocation();
  const history = useHistory();
  const profile = useSelector((state) => state.profile.profile);

  const theme = useSelector((state) => state.settings.theme);

  useEffect(() => {
    const storageTheme = getThemeFromStorage();
    if (storageTheme) {
      dispatch(setTheme(storageTheme));
    }
  }, []);

  useEffect(() => {
    saveThemeToStorage(theme);
  }, [theme]);

  useEffect(() => {
    if (profile) {
      bGetSubscription().then(({ subscription, invoicesLastMonth }) => {
        dispatch(setSubscription(subscription));
        dispatch(setInvoicesLastMonth(invoicesLastMonth || 0));
      });
    }
  }, [profile]);

  // getFirebase()
  //   .auth()
  //   .onAuthStateChanged((u) => {
  //     console.log(u);
  //     setUser(u);
  //   });

  useEffect(() => {
    if (!user && !loading && !showedToast) {
      // toast(
      //   "Please log in if your want your data to be saved for future invoices."
      // );
      // console.log("settingtofalse1");
      // setRetrievingProfile(false);
      // setShowedToast(true);
    }
  }, [user, loading, showedToast]);

  useEffect(() => {
    if (user) {
      i18n.changeLanguage(profile ? profile.language : "en");

      user
        .getIdToken()
        .then((idToken) => {
          const csrfToken = getCookie("csrfToken");
          bPostIdTokenToSessionLogin(idToken, csrfToken).then((result) => {
            setRetrievingProfile(true);
            bGetProfile()
              .then((retrievedProfile) => {
                if (retrievedProfile !== "Invalid token.") {
                  i18n.changeLanguage(retrievedProfile.language);
                  dispatch(setProfile(retrievedProfile));
                  identify(user.uid);
                  setRetrievedProfile(true);
                  setRetrievingProfile(false);
                } else {
                  setRetrievingProfile(false);
                }
              })
              .catch((e) => {
                // setRetrievingProfile(false);
              });
          });
        })
        .catch((e) => {
          console.log(e);
          signOut();
        });
    }
  }, [user]);

  return (
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      <GlobalStyles isDarkTheme={theme === "dark"} />
      <ToastContainer
        position={"top-center"}
        transition={Slide}
        theme={"dark"}
        pauseOnFocusLoss={false}
      />

      <AnimatePresence>
        {loading && (
          <motion.div
            key="loader-with-wrapper"
            className={"loading-auth-wrapper"}
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <LoaderWithWrapper />a
          </motion.div>
        )}
      </AnimatePresence>

      <Navbar
        user={user}
        onLogin={(showLoginFromEditor) => {
          setShowLoginFromEditor(showLoginFromEditor || false);
          setShowLoginModal(true);
        }}
      />

      <SimpleBar
        style={{
          height: "calc(100vh - 36px)",
          position: "fixed",
          top: "56px",
          left: "0",
          bottom: "0",
          width: "100vw",
          zIndex: "111",
        }}
      >
        {/*<TransitionGroup>*/}
        {/*  <CSSTransition key={location.key} classNames="fade" timeout={300}>*/}
        <Switch location={location}>
          <Route
            path="/home"
            render={({ location }) => (
              <Home
                onLogin={() => {
                  setShowLoginFromEditor(false);
                  setShowLoginModal(true);
                }}
                retrievedProfile={retrievedProfile}
                retrievingProfile={retrievingProfile}
              />
            )}
          />
          <Route
            path="/editor"
            render={({ location }) => (
              <Editor
                retrievedProfile={retrievedProfile}
                onLogin={() => {
                  setShowLoginFromEditor(true);
                  setShowLoginModal(true);
                }}
              />
            )}
          />
          <Route path="/">
            <Redirect
              to={{ pathname: "/home", search: location.search }}
              retrievedProfile={retrievedProfile}
            />
          </Route>
        </Switch>
        {/*  </CSSTransition>*/}
        {/*</TransitionGroup>*/}
      </SimpleBar>

      <>
        <Login
          onClose={(userActioned) => {
            setShowLoginModal(false);
            if (userActioned && location.pathname !== "/editor") {
              history.push("/editor");
            }
          }}
          isVisible={showLoginModal}
          inEditor={showLoginFromEditor}
          user={user}
        />
      </>
    </ThemeProvider>
  );
};

export default App;
