import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getRemoteConfig } from "firebase/remote-config";

let firebaseInstance;

export const firebaseConfig = {
  apiKey: "AIzaSyB416JJRnC7O-udDDNRzMsQHSzYlXZEHFQ",
  authDomain: "visual-invoicer.firebaseapp.com",
  projectId: "visual-invoicer",
  storageBucket: "visual-invoicer.appspot.com",
  messagingSenderId: "358254877206",
  appId: "1:358254877206:web:828cbcf3a3008ca83cf337",
  measurementId: "G-YCLCLDPNN4",
};

firebaseInstance = initializeApp(firebaseConfig);

export default function getFirebase() {
  if (typeof window !== "undefined") {
    if (firebaseInstance) return firebaseInstance;
    firebaseInstance = initializeApp(firebaseConfig);
    return firebaseInstance;
  }

  return null;
}

const remoteConfig = getRemoteConfig(firebaseInstance);
const auth = getAuth(firebaseInstance);
// setPersistence(auth, browserSessionPersistence);

const googleProvider = new GoogleAuthProvider();

export { auth, googleProvider, remoteConfig };
