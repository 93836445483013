import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Button,
  FlexCol,
  Row,
  ScreenHeading,
  ScreenWrapper,
  Section,
  Separator,
  DangerButton,
  SecondaryButton,
} from "../../../lib/components";
import { useTranslation } from "react-i18next";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../firebase";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../components/primitives/Spinner";
import Tabs from "../../../components/primitives/Tabs";
import {
  bDeleteInventoryCustomer,
  bDeleteInventoryProduct,
  bGetInventoryCustomers,
  bGetInventoryProducts,
  bUpdateInventoryProduct,
} from "../../../backend/inventory";
import {
  Table,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableWrapper,
} from "../../editor/components";
import { toast } from "react-toastify";
import { AiOutlineEdit } from "react-icons/ai";
import {
  setCustomers,
  setCustomersLoaded,
  setProducts,
  setProductsLoaded,
} from "../../../redux/inventoriesSlice";

const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  font-size: 14px;
`;

const Inventories = () => {
  const [user] = useAuthState(auth);
  const dispatch = useDispatch();

  const customers = useSelector((state) => state.inventories.customers);
  const products = useSelector((state) => state.inventories.products);
  const customersLoaded = useSelector(
    (state) => state.inventories.customersLoaded
  );
  const productsLoaded = useSelector(
    (state) => state.inventories.productsLoaded
  );

  const [updatingBarcode, setUpdatingBarcode] = useState(-1);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const profile = useSelector((state) => state.profile.profile);
  const referralInfo = useSelector(
    (state) => state.profile.profile.referralCode
  );
  const { t } = useTranslation();
  const history = useHistory();

  const loadProducts = () => {
    bGetInventoryProducts()
      .then(({ products: pList, success }) => {
        if (success) {
          dispatch(setProducts(pList));
        } else {
          dispatch(setProductsLoaded(true));
        }
      })
      .catch(() => {
        dispatch(setProductsLoaded(true));
      });
  };

  const loadCustomers = () => {
    bGetInventoryCustomers()
      .then(({ customers: cList, success }) => {
        if (success) {
          dispatch(setCustomers(cList));
        } else {
          dispatch(setCustomersLoaded(true));
        }
      })
      .finally(() => {
        dispatch(setCustomersLoaded(true));
      });
  };

  useEffect(() => {
    if (selectedTab === 0) {
      if (productsLoaded) {
        return;
      }

      loadProducts();
    }

    if (selectedTab === 1) {
      if (customersLoaded) {
        return;
      }

      loadCustomers();
    }
  }, [selectedTab, customersLoaded, productsLoaded]);

  const setBarcode = (k, defaultCode) => {
    const code = prompt("Enter the product barcode:", defaultCode || "");
    if (code === defaultCode) {
      return;
    }

    if (code) {
      setUpdatingBarcode(k);
      bUpdateInventoryProduct(products[k].id, { barcode: code })
        .then(({ success }) => {
          setUpdatingBarcode(-1);
          if (success) {
            toast("Barcode set");

            const newProducts = JSON.parse(JSON.stringify(products));
            newProducts[k].barcode = code;
            dispatch(setProducts(newProducts));
          }
        })
        .catch(() => {
          setUpdatingBarcode(-1);
        });
    }
  };

  const deleteProduct = (k) => {
    const id = products[k].id;
    setIsLoading(true);
    bDeleteInventoryProduct(products[k].id).then(() => {
      setIsLoading(false);
      toast("Product deleted");
      dispatch(setProducts(products.filter((p) => p.id !== id)));
    });
  };

  const deleteCustomer = (k) => {
    const id = customers[k].id;
    setIsLoading(true);
    bDeleteInventoryCustomer(customers[k].id).then(() => {
      setIsLoading(false);
      toast("Customer deleted");
      dispatch(setCustomers(customers.filter((c) => c.id !== id)));
    });
  };

  return (
    <ScreenWrapper>
      <Row>
        <FlexCol flex={1}>
          <ScreenHeading>{t("catalogues_title")}</ScreenHeading>
        </FlexCol>
      </Row>
      <Separator size={16} />

      <Tabs
        tabs={[{ label: t("products_title") }, { label: t("customers_title") }]}
        selected={selectedTab}
        onSelect={setSelectedTab}
      />
      <Separator size={16} />
      <Section>
        {selectedTab === 0 && (
          <>
            {!productsLoaded && (
              <EmptyState>
                <Spinner />
              </EmptyState>
            )}
            {productsLoaded && products.length === 0 && (
              <EmptyState>{t("no_products_yet")}</EmptyState>
            )}
            {productsLoaded && products.length > 0 && (
              <TableWrapper>
                <Table>
                  <thead>
                    <TableHeader>
                      <TableHeaderCell width="60%" mobileWidth="60%">
                        {t("inventory_name")}
                      </TableHeaderCell>
                      <TableHeaderCell width="15%" mobileWidth="15%">
                        {t("inventory_barcode")}
                      </TableHeaderCell>
                      <TableHeaderCell width="15%" mobileWidth="15%">
                        {t("inventory_price")}
                      </TableHeaderCell>
                      <TableHeaderCell width="10%" mobileWidth="10%" />
                    </TableHeader>
                  </thead>
                  <tbody>
                    {products.map((p, k) => (
                      <TableRow key={`product-${k}`}>
                        <TableCell width="60%">{p.name}</TableCell>
                        <TableCell width="15%">
                          {p.barcode ? (
                            <SecondaryButton
                              small
                              style={{ width: "auto" }}
                              onClick={
                                updatingBarcode === k
                                  ? () => {}
                                  : () => setBarcode(k, p.barcode)
                              }
                            >
                              {updatingBarcode === k ? (
                                <Spinner />
                              ) : (
                                <>
                                  {p.barcode} <AiOutlineEdit />
                                </>
                              )}
                            </SecondaryButton>
                          ) : (
                            <Button
                              small
                              style={{ width: "auto" }}
                              onClick={
                                updatingBarcode === k
                                  ? () => {}
                                  : () => setBarcode(k)
                              }
                            >
                              {updatingBarcode === k ? (
                                <Spinner />
                              ) : (
                                t("set_barcode")
                              )}
                            </Button>
                          )}
                        </TableCell>
                        <TableCell width="15%">{p.info.unitCost}</TableCell>
                        <TableCell width="10%">
                          {isLoading ? (
                            <Spinner />
                          ) : (
                            <DangerButton
                              small
                              style={{ width: "auto" }}
                              onClick={() => deleteProduct(k)}
                            >
                              {t("delete")}
                            </DangerButton>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </tbody>
                </Table>
              </TableWrapper>
            )}
          </>
        )}

        {selectedTab === 1 && (
          <>
            {!customersLoaded && (
              <EmptyState>
                <Spinner />
              </EmptyState>
            )}
            {customersLoaded && customers.length === 0 && (
              <EmptyState>{t("no_customers_yet")}</EmptyState>
            )}
            {customersLoaded && customers.length > 0 && (
              <TableWrapper>
                <Table>
                  <thead>
                    <TableHeader>
                      <TableHeaderCell width="10%" mobileWidth="10%">
                        #
                      </TableHeaderCell>
                      <TableHeaderCell width="80%" mobileWidth="80%">
                        {t("inventory_name")}
                      </TableHeaderCell>
                      <TableHeaderCell width="10%" mobileWidth="10%" />
                    </TableHeader>
                  </thead>
                  <tbody>
                    {customers.map((p, k) => (
                      <TableRow key={`customer-${k}`}>
                        <TableCell width="10%">{k + 1}</TableCell>
                        <TableCell width="80%">{p.name}</TableCell>

                        <TableCell width="10%">
                          {isLoading ? (
                            <Spinner />
                          ) : (
                            <DangerButton
                              small
                              style={{ width: "auto" }}
                              onClick={() => deleteCustomer(k)}
                            >
                              {t("inventory_delete")}
                            </DangerButton>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </tbody>
                </Table>
              </TableWrapper>
            )}
          </>
        )}
      </Section>
    </ScreenWrapper>
  );
};

export default Inventories;
