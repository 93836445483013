const size = {
  small: 480,
  medium: 768,
  large: 1200,
  xLarge: 1800,
};

export const mediaQuery = {
  belowSmall: `(max-width: ${size.small}px)`,
  belowMedium: `(max-width: ${size.medium}px)`,
  belowLarge: `(max-width: ${size.large}px)`,
  belowXLarge: `(max-width: ${size.xLarge}px)`,
  small: `(min-width: ${size.small + 1}px)`,
  medium: `(min-width: ${size.medium + 1}px)`,
  large: `(min-width: ${size.large + 1}px)`,
  xLarge: `(min-width: ${size.xLarge + 1}px)`,
};

export const media = {
  belowSmall: `@media (max-width: ${size.small}px)`,
  belowMedium: `@media (max-width: ${size.medium}px)`,
  belowLarge: `@media (max-width: ${size.large}px)`,
  belowXLarge: `@media (max-width: ${size.xLarge}px)`,
  small: `@media (min-width: ${size.small + 1}px)`,
  medium: `@media (min-width: ${size.medium + 1}px)`,
  large: `@media (min-width: ${size.large + 1}px)`,
  xLarge: `@media (min-width: ${size.xLarge + 1}px)`,
};
