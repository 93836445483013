import React, { useEffect, useMemo, useRef, useState } from "react";
import { HexColorPicker } from "react-colorful";
import { saveAs } from "file-saver";

import { v4 as uuidv4 } from "uuid";
import { motion, AnimatePresence } from "framer-motion";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import "react-day-picker/lib/style.css";
import "moment/locale/ro";
import "moment/locale/en-gb";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  faArrowCircleLeft,
  faFileExport,
  faDesktop,
  faCheckCircle,
  faTable,
  faCogs,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "../../components/modal/Modal";

import simpleTemplate from "../../lib/templates/simple-template";
import elegant from "../../lib/templates/elegant";
import modern from "../../lib/templates/modern";
import neat from "../../lib/templates/neat";

import simplePreview from "../../assets/simple.png";
import elegantPreview from "../../assets/elegant.png";
import modernPreview from "../../assets/modern.png";
import neatPreview from "../../assets/neat.png";

import "./Editor.scss";
import {
  Button,
  CenteredCol,
  CenteredRow,
  Col,
  CollapsibleCol,
  Explanation,
  FlexCol,
  FlexRow,
  Input,
  Row,
  SecondaryButton,
  SectionTitle,
  Select,
  Separator,
  Slider,
  Textarea,
  TextButton,
  InfoBar,
  InfoBarDanger,
  DangerButton,
} from "../../lib/components";
import { invoiceData as dummyInvoiceData } from "../../lib/dummy";
import {
  getFormattedAmount,
  isObjectEmpty,
  reorder,
  watermarkHtml,
} from "../../lib/utils";
import DayPickerInput from "react-day-picker/DayPickerInput";
import currencies from "../../lib/currencies";
import {
  ControlButton,
  DisabledControlButton,
  ExportDescription,
  ExportGrid,
  ExportPrice,
  ExportType,
  Page,
  Table,
  TableCell,
  TableDiv,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableWrapper,
  TemplateCheck,
  TemplateContainer,
  TemplateGrid,
  TemplateImage,
  TemplateName,
  Watermark,
} from "./components";
import { useAuthState } from "react-firebase-hooks/auth";
import getFirebase, { auth } from "../../firebase";
import { toast } from "react-toastify";
import {
  bDownloadPdf,
  bGeneratePdf,
  bUpsertInvoice,
} from "../../backend/invoice";
import { Redirect, useLocation } from "react-router-dom";
import NavigatePrompt from "./NavigatePrompt";
import { Trans, useTranslation } from "react-i18next";
import i18next from "i18next";
import { useInvoiceOutlet } from "../../context/invoice";
import { buildHTML } from "../../lib/templates";
import styled, { createGlobalStyle } from "styled-components";

import roFlag from "../../assets/ro.png";
import ukFlag from "../../assets/uk.png";
import deFlag from "../../assets/de.png";
import frFlag from "../../assets/fr.png";
import jaFlag from "../../assets/ja.png";
import { useDispatch, useSelector } from "react-redux";
import { LoaderWithWrapper } from "../../components/loader/Loader";
import featureFlags from "../../lib/featureFlags";
import { SUBSCRIPTION_TYPE } from "../../lib/constants";
import Spinner from "../../components/primitives/Spinner";
import { Centered } from "../home/screens/subscription/Subscription";
import { HideBelowMedium } from "../../components/primitives/Utils";
import { media } from "../../components/mixins";
import { bUpdateProfile } from "../../backend/profile";
import { updateProfileProp } from "../../redux/profileSlice";
import { setInvoiceName, setIsInvoiceEdited } from "../../redux/editorSlice";
import { BsFillExclamationCircleFill } from "react-icons/bs";
import Tooltip from "../../components/primitives/Tooltip";
import ForcedTooltip from "../../components/primitives/ForcedTooltip";
import {
  bGetInventoryCustomers,
  bGetInventoryProducts,
} from "../../backend/inventory";
import {
  setCustomers,
  setCustomersLoaded,
  setProducts,
  setProductsLoaded,
} from "../../redux/inventoriesSlice";
import useHasPaidSubscription from "../../hooks/useHasPaidSubscription";
import { usePrevious } from "../../lib/hooks";

const langAssets = {
  ro: roFlag,
  en: ukFlag,
  de: deFlag,
  fr: frFlag,
  ja: jaFlag,
};

const templates = [
  {
    name: "Neat",
    src: neat,
    preview: neatPreview,
  },
  {
    name: "Simple",
    src: simpleTemplate,
    preview: simplePreview,
  },
  {
    name: "Modern",
    src: modern,
    preview: modernPreview,
  },
  {
    name: "Elegant",
    src: elegant,
    preview: elegantPreview,
  },
];

const FloatingDebugMenu = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 16px;
  border: solid 4px #cccccc;
  background-color: #f5f6fa77;
  border-radius: 8px;
  z-index: 111111;
  display: flex;
  flex-direction: column;
  gap: 8px;

  h4 {
    margin-top: 0;
  }
`;

const LanguageButton = styled.img`
  width: 28px;
  height: 28px;
  margin-right: 16px;
  cursor: pointer;

  border: solid 1px #ffffff;
  border-radius: 50%;
  outline: ${({ selected }) =>
    selected ? "solid 2px #30336b" : "solid 2px #ffffff"};

  :hover {
    filter: brightness(0.7) !important;
  }
`;

const VariableTitle = styled.div`
  color: ${({ theme }) => theme.colors.controlButtonIcon};
  padding-bottom: 8px;
  border-bottom: solid 1px ${({ theme }) => theme.colors.controlButton};
  border-radius: 0 !important;
  margin-bottom: 8px;
  text-transform: capitalize;
  font-size: 14px;
`;

const VariableContainer = styled.div`
  border-radius: 8px !important;
  padding: 8px;
  background-color: ${({ theme }) => theme.colors.controlButton};
  font-size: 12px;
  color: ${({ theme }) => theme.colors.controlButtonIcon};

  .var-type {
    color: #888888;
    font-size: 11px;
  }
`;

const VariableName = styled.div`
  margin-bottom: 8px;
  height: 18px;
`;

const ExportingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #30336b;
  z-index: 111;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #ffffff;
`;

const ExchangeCol = styled(FlexCol)`
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  align-items: center;
`;

const InvoiceReadOnly = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: solid 1px ${({ theme }) => theme.colors.gray};
  position: fixed;
  top: 56px;
  left: 0;
  right: 0;
  padding: 16px 8px;
  text-align: center;
  z-index: 1;
  font-size: 13px;
`;

const VariableDiv = styled(motion.div)`
  overflow: hidden;
`;

// background-color: ${({ isDarkTheme }) =>
// isDarkTheme ? "#1f1f47" : "#d4dee7"};
// background-color: ${({ isDarkTheme }) =>
// isDarkTheme ? "#1f1f47" : "#f5f6fa"};
const GlobalStyles = createGlobalStyle`
  body {
    transition: background-color 0.05s linear;
  }
  
  .DayPickerInput input {
    width: 100%;
    border: solid 1px ${({ theme }) => theme.colors.inputBorder};
    font-size: 14px;
    height: 36px;
    border-radius: 4px;
    box-sizing: border-box;
    transition: all 0.1s ease-in-out;
    background-color: ${({ theme }) => theme.colors.inputBg};
    color: ${({ theme }) => theme.colors.inputFg};

    &:focus-visible {
      outline: 0 !important;
      border-color: ${({ theme }) => theme.colors.inputFocusBorder};
      background-color: ${({ theme }) => theme.colors.inputBg};
      color: ${({ theme }) => theme.colors.inputFg};
    }
  }

  .DayPickerInput-Overlay {
    background-color: ${({ theme }) => theme.colors.inputBg};
    color: ${({ theme }) => theme.colors.inputFg};
  }

  .react-joyride__tooltip {
    background-color: ${({ theme }) => theme.colors.modalBg} !important;
    color: ${({ theme }) => theme.colors.modalFg} !important;
    
    button:hover {
      filter: brightness(0.9);
    }
    button[data-action="back"] {
      color: ${({ theme }) => theme.colors.modalFg} !important;
    }
  }
  
  .__floater__arrow {
    polygon {
      fill: ${({ theme }) => theme.colors.modalBg} !important;
    }
  }
`;

const grid = 8;

const getItemStyle = (isDragging, draggableStyle, index) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  // margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  // backgroundColor: isDragging ? "#f5f6fa" : 'none', // index % 2 === 1 ? "#f5f6fa" : "#ffffff",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#718093" : "#ffffff",
  // padding: grid,
  width: "100%",
});

const TemplatedPage = styled(Page)`
  ${({ template }) => (template ? template.globalStyles : "")}
  margin-bottom: 140px;

  ${({ isDarkTheme }) =>
    isDarkTheme && `// background-color: #30336b;color: #ffffff !important;`}

  ${({ editable }) =>
    editable &&
    `
    #invoice_series,
    #invoice_number,
    #date_of_issue,
    #due_date,
    #seller,
    #products_table,
    #billed_to {
      box-sizing: border-box;
      //border: solid 1px #00000000;
      border-radius: 4px;
      border: dashed 1px #777777;
      background-color: #f5f6fa33;
      margin: -1px;

      &:before {
        filter: opacity(0);
        transition: all 0.1s ease-in;
        content: "Edit";
        position: absolute;
        transform: translateX(-100%);
        font-size: 12px;
        border: solid 1px #ffffff;
        border-radius: 8px;
        background-color: #42468f;
        color: #f5f6fa;
        padding: 4px 8px;
      }

      &:hover {
        border: solid 1px #00b894;
        border-radius: 4px;
        background-color: #f5f6fa77;
        cursor: pointer;
        margin: -1px;

        &:before {
          filter: opacity(1);
        }
      }
    }`}
`;

const BottomControls = styled(motion.div)`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  height: 80px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  //overflow: hidden;

  button:first-child {
    border-radius: 8px 0 0 8px;
  }

  button:last-child {
    border-radius: 0 8px 8px 0;
  }

  ${media.belowMedium} {
    top: unset !important;
    bottom: 0;
    left: 0;
    right: 0;
    transform: none;
    border-radius: 0;
    height: 60px;

    ${ControlButton} {
      padding: 0 !important;
      min-width: 0;
      border-radius: 0 !important;
      div {
        display: none;
      }
    }
  }
`;

const VariablesControl = styled(motion.div)`
  position: fixed;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  width: 220px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.controlButtonBorder};
  height: auto;
  padding: 8px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);

  button {
    height: 20px !important;
  }

  & > div {
    margin-bottom: 8px;
  }

  :last-child {
    margin-bottom: 0;
  }

  ${media.belowMedium} {
    top: unset;
    bottom: 80px;
    left: 50% !important;
    right: unset;
    transform: translateX(-50%);
  }
`;

const InvoiceSetupWrapper = styled.div`
  min-width: 500px;
  width: 70vw;

  ${media.belowMedium} {
    min-width: 0;
    width: 100%;
  }
`;

const Editor = (props) => {
  const pageRef = useRef();

  const { onLogin } = props;
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [user] = useAuthState(auth);

  const { invoice, setInvoice } = useInvoiceOutlet();

  const [invoiceData, setInvoiceData] = useState(
    invoice ? invoice.data : dummyInvoiceData
  );

  const [shouldApplyProfile, setShouldApplyProfile] = useState(false);
  const [template, setTemplate] = useState(modern);
  const [pageHeight, setPageHeight] = useState(1000);
  const [showConfigModal, setShowConfigModal] = useState(false);
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [showCustomerInventoryModal, setShowCustomerInventoryModal] =
    useState(false);
  const [showProductInventoryModal, setShowProductInventoryModal] =
    useState(false);
  const [showResetModal, setShowResetModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [showConfirmExportModal, setShowConfirmExportModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showChangeSellerNameModal, setShowChangeSellerNameModal] =
    useState(false);
  const [highlightedItem, setHighlightedItem] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(
    invoiceData.config.template || "Neat"
  );
  const [isExporting, setIsExporting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [isEdited, setIsEdited] = useState(false);
  const [invoiceHtml, setInvoiceHtml] = useState("");
  const [tempLanguage, setTempLanguage] = useState();
  const [variables, setVariables] = useState(false);
  const [expandedVariable, setExpandedVariable] = useState(null);
  const [templateModalOpacity, setTemplateModalOpacity] = useState(1);
  const [tempCurrency, setTempCurrency] = useState(null);
  const [tempOtherCurrency, setTempOtherCurrency] = useState(null);
  const [tempSellerName, setTempSellerName] = useState("");
  const [usingBarcode, setUsingBarcode] = useState(false);

  const buyerNameInputRef = useRef();
  const buyerPhoneInputRef = useRef();
  const buyerAddressInputRef = useRef();
  const buyerInfoInputRef = useRef();
  const scannerInputRef = useRef();

  const invoiceReadOnly = useMemo(
    () => invoice && invoice.permalink && invoice.permalink.template,
    [invoice]
  );

  const editModalRef = useRef();
  const editFormRef = useRef();
  const addItemRef = useRef();
  const descriptionRef = useRef();

  const hasPaidSubscription = useHasPaidSubscription();

  const theme = useSelector((state) => state.settings.theme);
  const subscriptionType = useSelector(
    (state) => state.profile.subscriptionType
  );
  const profile = useSelector((state) => state.profile.profile);
  const canUpdateName = useSelector(
    (state) => state.profile.profile?.canUpdateName
  );

  const dispatch = useDispatch();

  const handleScanner = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const data = new FormData(e.target);
    const object = {};
    data.forEach((value, key) => (object[key] = value));

    scannerInputRef.current.value = "";

    const itemToBeAdded = inventoryProducts.find(
      (p) => p.barcode === object.barcode
    );

    if (!itemToBeAdded) {
      toast("Item not found in catalogue.");
      return;
    }

    setInvoiceData({
      ...invoiceData,
      items: [
        ...invoiceData.items,
        {
          id: uuidv4(),
          unitCost: itemToBeAdded.info.unitCost,
          name: itemToBeAdded.name,
          qty: 1,
        },
      ],
    });
  };

  const loadProducts = () => {
    bGetInventoryProducts()
      .then(({ products: pList, success }) => {
        if (success) {
          dispatch(setProducts(pList));
        } else {
          dispatch(setProductsLoaded(true));
        }
      })
      .catch(() => {
        dispatch(setProductsLoaded(true));
      });
  };

  const loadCustomers = () => {
    bGetInventoryCustomers()
      .then(({ customers: cList, success }) => {
        if (success) {
          dispatch(setCustomers(cList));
        } else {
          dispatch(setCustomersLoaded(true));
        }
      })
      .finally(() => {
        dispatch(setCustomersLoaded(true));
      });
  };

  const customersLoaded = useSelector(
    (state) => state.inventories.customersLoaded
  );
  const productsLoaded = useSelector(
    (state) => state.inventories.productsLoaded
  );
  const inventoryCustomers = useSelector(
    (state) => state.inventories.customers
  );
  const inventoryProducts = useSelector((state) => state.inventories.products);

  useEffect(() => {
    if (invoice && !invoice.id) {
      // toast(t("we_used_data_from_previous_invoice"));
    }

    if (!customersLoaded) {
      loadCustomers();
    }

    if (!productsLoaded) {
      loadProducts();
    }
  }, [customersLoaded, productsLoaded]);

  useEffect(() => {
    if (isEdited) {
      window.onbeforeunload = function (e) {
        return "Are you sure you want to leave? You are in the middle of something.";
      };
    }

    dispatch(setIsInvoiceEdited(isEdited));

    return () => {
      window.onbeforeunload = null;
    };
  }, [isEdited]);

  useEffect(() => {
    dispatch(
      setInvoiceName(`${invoiceData.config.series}${invoiceData.config.number}`)
    );
  }, [invoiceData.config.series, invoiceData.config.number]);

  useEffect(() => {
    const t = templates.find((t) => t.name === (selectedTemplate || "Neat"));
    setTemplate(t.src);
    setVariables(t.src.variables || []);
  }, [selectedTemplate]);

  const previousUser = usePrevious(user);

  useEffect(() => {
    if (!previousUser && user) {
      // user logged in. set seller from branding profile
      setTimeout(() => {
        setShouldApplyProfile(true);
      }, 1);

      // setTimeout(() => {
      //   toast(
      //     "You already had a seller profile defined, so we applied it to this invoice."
      //   );
      // }, 2000);
    }
  }, [previousUser, user]);

  useEffect(() => {
    if (shouldApplyProfile && profile) {
      setShouldApplyProfile(false);
      if (profile && !isObjectEmpty(profile.branding.seller)) {
        const newInvData = {
          ...invoiceData,
          content: { ...invoiceData.content, seller: profile.branding.seller },
        };
        setInvoiceData(newInvData);
      }
    }
  }, [shouldApplyProfile, profile]);

  const addItem = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const data = new FormData(e.target);
    const object = {};
    data.forEach((value, key) => (object[key] = value));

    if (
      object.d.trim() === "" ||
      object.q.trim() === "" ||
      object.p.trim() === ""
    ) {
      toast("At least one of the required fields is missing. ", {
        type: "warning",
      });
      return;
    }

    setInvoiceData({
      ...invoiceData,
      items: [
        ...invoiceData.items,
        {
          id: uuidv4(),
          unitCost: object.p,
          name: object.d,
          qty: object.q,
        },
      ],
    });

    addItemRef.current?.reset();
    descriptionRef.current?.focus();

    setIsEdited(true);
  };

  const removeItem = (idx) => {
    const newItems = invoiceData.items;
    newItems.splice(idx, 1);
    setInvoiceData({
      ...invoiceData,
      items: newItems,
    });

    setIsEdited(true);
  };

  const updateVariable = (varName, varValue) => {
    setInvoiceData({
      ...invoiceData,
      config: {
        ...invoiceData.config,
        userVariables: {
          ...invoiceData.config.userVariables,
          [varName]: varValue,
        },
      },
    });

    setIsEdited(true);
  };

  const updateInvoiceConfig = () => {
    const data = new FormData(editFormRef.current);
    const object = {};
    data.forEach((value, key) => (object[key] = value));

    const newInvData = {
      ...invoiceData,
      config: {
        ...invoiceData.config,
        series: object.series,
        number: object.number,
        footer: object.footer,
        date: object.date,
        dueDate: object.dueDate,
        taxRate: parseFloat(object.taxRate),
        currency: object.currency,
        otherCurrency: object.otherCurrency,
        exchangeRate: object.exchangeRate,
        language: tempLanguage,
        userVariables: {
          ...invoiceData.config.userVariables,
          displayTotalInOtherCurrency:
            object.currency !== object.otherCurrency &&
            object.otherCurrency &&
            object.otherCurrency !== ""
              ? "block"
              : "none",
        },
      },
      content: {
        ...invoiceData.content,
        seller: {
          ...invoiceData.content.seller,
          address: object["seller.address"],
          phone: object["seller.phone"],
          additionalInfo: object["seller.additionalInfo"],

          ...(!user ? { name: object["seller.name"] } : {}),
        },
        buyer: {
          ...invoiceData.content.buyer,
          name: object["buyer.name"],
          address: object["buyer.address"],
          phone: object["buyer.phone"],
          additionalInfo: object["buyer.additionalInfo"],
        },
      },
    };

    setInvoiceData(newInvData);

    setIsEdited(true);
  };

  const updateBuyer = (buyer) => {
    buyerNameInputRef.current.value = buyer.name;
    buyerAddressInputRef.current.value = buyer.address;
    buyerPhoneInputRef.current.value = buyer.phone;
    buyerInfoInputRef.current.value = buyer.additionalInfo;
  };

  const handleExport = () => {
    if (subscriptionType !== SUBSCRIPTION_TYPE.PREMIUM) {
      if (invoiceReadOnly) {
        // invoice exported already, offer the user the choice to upgrade subscription
        setShowExportModal(true);
      } else {
        setShowConfirmExportModal(true);
      }
    } else {
      if (!invoiceReadOnly) {
        setShowConfirmExportModal(true);
      } else {
        // premium subscription, go directly to endpoint
        exportPdf();
      }
    }
  };

  const exportPdf = () => {
    if (!user) {
      toast(t("you_need_to_be_authenticated_to_export"), {
        type: "warning",
      });
      onLogin();
      return;
    }

    bUpsertInvoice(invoiceData).then((i) => {
      toast(t("exporting_invoice"));
      setInvoiceData({ ...invoiceData, id: i.id });
      setIsEdited(false);

      bGeneratePdf(i.id).then((response) => {
        if (response && response.url) {
          const name =
            invoiceData.config.series + invoiceData.config.number ||
            "invoice.pdf";
          saveAs(response.url, name);
          setInvoice({
            ...invoice,
            permalink: {
              url: response.url,
            },
          });

          setIsExporting(false);
        } else {
          toast("export_error_occurred");
        }
      });
    });
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      invoiceData.items,
      result.source.index,
      result.destination.index
    );

    setInvoiceData({ ...invoiceData, items: items });
  };

  const onSaveInvoice = () => {
    if (!user) {
      toast(t("you_need_to_be_authenticated_to_save"), {
        type: "warning",
      });
      onLogin();
      return;
    }

    setIsSaving(true);
    bUpsertInvoice(invoiceData)
      .then((i) => {
        toast(t("invoice_saved"));
        setInvoiceData({ ...invoiceData, id: i.id });
        setIsEdited(false);
        setIsSaving(false);
      })
      .catch((e) => setIsSaving(false));
  };

  const addListeners = () => {
    if (!document.getElementById("seller")) {
      return;
    }

    (document.getElementById("seller") || {}).onclick = () => {
      setTimeout(() => setHighlightedItem("seller"), 500);
      setTimeout(() => setHighlightedItem(null), 1500);
      setShowConfigModal(true);
    };

    (document.getElementById("products_table") || {}).onclick = () => {
      setShowEditModal(true);
    };

    (document.getElementById("add_item_button") || {}).onclick = () => {
      setShowEditModal(true);
    };

    (document.getElementById("invoice_series") || {}).onclick = () => {
      setTimeout(() => setHighlightedItem("invoice_number"), 500);
      setTimeout(() => setHighlightedItem(null), 1500);
      setShowConfigModal(true);
    };

    (document.getElementById("invoice_number") || {}).onclick = () => {
      setTimeout(() => setHighlightedItem("invoice_number"), 500);
      setTimeout(() => setHighlightedItem(null), 1500);
      setShowConfigModal(true);
    };

    (document.getElementById("date_of_issue") || {}).onclick = () => {
      setTimeout(() => setHighlightedItem("date_of_issue"), 500);
      setTimeout(() => setHighlightedItem(null), 1500);
      setShowConfigModal(true);
    };

    (document.getElementById("due_date") || {}).onclick = () => {
      setTimeout(() => setHighlightedItem("due_date"), 500);
      setTimeout(() => setHighlightedItem(null), 1500);
      setShowConfigModal(true);
    };

    (document.getElementById("billed_to") || {}).onclick = () => {
      setTimeout(() => setHighlightedItem("buyer"), 500);
      setTimeout(() => setHighlightedItem(null), 1500);
      setShowConfigModal(true);
    };
  };

  useEffect(() => {
    addListeners();
  });

  useEffect(() => {
    if (!pageRef.current) {
      return;
    }

    setPageHeight(Math.floor((pageRef.current.offsetWidth / 21) * 29.7));
    setZoom(pageRef.current.offsetWidth / 1000);
    setTimeout(() => {
      addListeners();
    }, 100);

    window.addEventListener("resize", () => {
      if (!pageRef.current) {
        return;
      }
      setPageHeight(Math.floor((pageRef.current.offsetWidth / 21) * 29.7));
      setZoom(pageRef.current.offsetWidth / 1000);
      setTimeout(() => {
        addListeners();
      }, 100);
    });
  }, [pageRef]);

  const editorComponents = useMemo(() => {
    return invoiceReadOnly
      ? {}
      : {
          addItemButton: `<div class="add-item-wrapper"><button class="add-item-button" id="add_item_button">${t(
            "add_item_lowercase"
          )}</button></div>`,
        };
  }, [invoiceReadOnly, i18n.language]);

  useEffect(() => {
    setInvoiceHtml(buildHTML(invoiceData, template, zoom, editorComponents));
    setTimeout(() => {
      addListeners();
    }, 100);
  }, [zoom, invoiceData, template, editorComponents]);

  useEffect(() => {
    if (invoiceData.config.template) {
      setSelectedTemplate(invoiceData.config.template || "Neat");
    }
  }, [invoiceData]);

  useEffect(() => {
    if (!document.getElementById("seller")) {
      return;
    }
    setTimeout(() => {
      addListeners();
    }, 100);
  }, [invoiceHtml]);

  const getVariableValue = (varName) => {
    if (
      !invoiceData ||
      !invoiceData.config ||
      !invoiceData.config.userVariables
    ) {
      return null;
    }

    if (!invoiceData.config.userVariables.hasOwnProperty(varName)) {
      return null;
    }

    return invoiceData.config.userVariables[varName];
  };

  const changeSellerName = () => {
    if (!canUpdateName) {
      return;
    }

    if (!user) {
      toast(
        "You need to create a profile in order to set up your seller profile. 🔑",
        {
          type: "warning",
        }
      );
      onLogin();
      return;
    }
    setShowChangeSellerNameModal(true);
  };

  const doUpdateSellerName = () => {
    const newSellerName = tempSellerName.trim();
    if (!newSellerName) {
      toast(t("invalid_seller_name"), { type: "warning" });
      return;
    }

    const updatedProfile = JSON.parse(JSON.stringify(profile));
    if (!updatedProfile.branding.seller) {
      updatedProfile.branding.seller = {};
    }

    updatedProfile.branding.seller.name = newSellerName;

    bUpdateProfile({ branding: updatedProfile.branding })
      .then(({ disableNameUpdate }) => {
        setShowChangeSellerNameModal(false);
        setIsEdited(true);
        dispatch(
          updateProfileProp({ key: "branding", value: updatedProfile.branding })
        );

        if (disableNameUpdate) {
          dispatch(updateProfileProp({ key: "canUpdateName", value: false }));
        }

        setInvoiceData({
          ...invoiceData,
          content: {
            ...invoiceData.content,
            seller: { ...invoiceData.content.seller, name: newSellerName },
          },
        });
        toast(t("seller_name_successfully_saved"));
      })
      .catch((error) => {
        setShowChangeSellerNameModal(false);
        const code = error.response?.data?.error?.code || "unknown_error";
        toast(code, { type: "warning" });
      });
  };

  useEffect(() => {
    if (usingBarcode) {
      scannerInputRef.current.focus();
    }
  }, [usingBarcode]);

  // if (!user || !invoice) {
  //   return <Redirect to={"/home"} />;
  // }
  //
  // if (!invoice) {
  //   return <Redirect to={"/home"} />;
  // }

  return (
    <>
      <GlobalStyles isDarkTheme={theme === "dark"} />
      {invoiceReadOnly && (
        <InvoiceReadOnly>{t("invoice_is_read_only")}</InvoiceReadOnly>
      )}
      <NavigatePrompt
        when={isEdited}
        title={t("leave_this_page")}
        onOK={() => true}
        onCancel={() => false}
      />

      <TemplatedPage
        template={template}
        editable={!invoiceReadOnly}
        pageHeight={pageHeight}
        ref={pageRef}
        id="page-id"
        dangerouslySetInnerHTML={{
          __html: invoiceHtml,
        }}
        className={
          parseInt(invoiceData.config.taxRate)
            ? "show-children-with-zero-tax-rate"
            : "hide-children-with-zero-tax-rate"
        }
        isDarkTheme={theme === "dark"}
      />

      <AnimatePresence>
        <BottomControls
          key="bottom-controls"
          className="bottom-controls"
          initial={{ opacity: 0, top: "100%" }}
          animate={{ opacity: 1, top: "calc(100% - 100px)" }}
          exit={{ opacity: 0, top: 0 }}
        >
          {!invoiceReadOnly && (
            /*
            <ForcedTooltip
              text={"start here by configuring some basic information about your invoice"}
            >
*/
            <ControlButton
              onClick={() => {
                setTempCurrency(invoiceData.config.currency);
                setTempOtherCurrency(invoiceData.config.otherCurrency);
                setTempLanguage(invoiceData.config.language);
                setShowConfigModal(true);
              }}
              id="invoice-setup"
            >
              <FontAwesomeIcon icon={faCogs} />
              <div>{t("invoice_setup")}</div>
            </ControlButton>
            // </ForcedTooltip>
          )}
          {!invoiceReadOnly && (
            <ControlButton
              onClick={() => setShowEditModal(true)}
              id="edit-items"
            >
              <FontAwesomeIcon icon={faTable} />
              <div>{t("edit_items")}</div>
            </ControlButton>
          )}
          <ControlButton
            onClick={() => setShowTemplateModal(true)}
            id="select-template"
          >
            <FontAwesomeIcon icon={faDesktop} />
            <div>{t("template")}</div>
          </ControlButton>
          {isExporting ? (
            <DisabledControlButton>
              <Spinner />
            </DisabledControlButton>
          ) : (
            <ControlButton
              onClick={isExporting ? () => {} : handleExport}
              id="export-invoice"
            >
              <FontAwesomeIcon icon={faFileExport} />
              <div>{t("export")}</div>
            </ControlButton>
          )}
          {!invoiceReadOnly &&
            (isSaving ? (
              <DisabledControlButton>
                <Spinner />
              </DisabledControlButton>
            ) : (
              <ControlButton
                hover={"info"}
                onClick={() => onSaveInvoice()}
                id="save-invoice"
              >
                <FontAwesomeIcon icon={faSave} />
                {isEdited && (
                  <div
                    className="star"
                    style={{
                      position: "absolute",
                      top: "calc(50% - 20px)",
                      left: "calc(50% + 16px)",
                      opacity: 1,
                      transform: "unset",
                    }}
                  >
                    <BsFillExclamationCircleFill color="#f1c40f" />
                  </div>
                )}
                <div>{t("save")}</div>
              </ControlButton>
            ))}
          {!invoiceReadOnly && (
            <ControlButton
              hover={"danger"}
              onClick={() => setShowResetModal(true)}
              id="reset-invoice"
            >
              <FontAwesomeIcon icon={faArrowCircleLeft} />
              <div>{t("reset")}</div>
            </ControlButton>
          )}
        </BottomControls>
      </AnimatePresence>

      <AnimatePresence>
        {variables.length > 0 && !invoiceReadOnly && (
          <VariablesControl
            key="variables-control"
            className="variables-control"
            initial={{ opacity: 0, left: "100vw" }}
            animate={{ opacity: 1, left: "calc(100vw - 260px)" }}
            exit={{ opacity: 0, left: "100vw" }}
          >
            <VariableTitle>{t("variables")}</VariableTitle>

            {template.variables.map((v) => {
              let content = null;
              switch (v.type) {
                case "color":
                  content = (
                    <HexColorPicker
                      key={v.name}
                      color={getVariableValue(v.name) || v.default}
                      onChange={(c) => updateVariable(v.name, c)}
                      style={{
                        height: "120px",
                        width: "100%",
                      }}
                    />
                  );
                  break;
                case "text":
                  content = (
                    <Textarea
                      key={v.name}
                      value={getVariableValue(v.name) || v.default}
                      onChange={(e) => updateVariable(v.name, e.target.value)}
                      onFocus={() => {
                        if (v.name === "additionalInfo") {
                          const p = document.getElementsByClassName(
                            "simplebar-content-wrapper"
                          );
                          if (p && p[0]) {
                            p[0].scroll({
                              top: p[0].scrollHeight,
                              behavior: "smooth",
                            });
                          }
                        }
                      }}
                    />
                  );
                  break;
                case "slider":
                  content = (
                    <Slider
                      key={v.name}
                      min={v.min}
                      max={v.max}
                      value={getVariableValue(v.name) || v.default}
                      onChange={(e) =>
                        updateVariable(v.name, parseFloat(e.target.value))
                      }
                    />
                  );
                  break;
                default:
                  content = <Input />;
              }

              return (
                <VariableContainer>
                  <>
                    <VariableName style={{ marginBottom: 0 }}>
                      <TextButton
                        // dark={theme !== 'dark'}
                        onClick={() =>
                          setExpandedVariable(
                            expandedVariable === v.name ? null : v.name
                          )
                        }
                        small
                        block
                      >
                        <CenteredRow>
                          <Col>
                            {t(v.title || v.name)}{" "}
                            <span className="var-type">({t(v.type)})</span>
                          </Col>
                          <CollapsibleCol>▼</CollapsibleCol>
                        </CenteredRow>
                      </TextButton>
                    </VariableName>
                  </>
                  <AnimatePresence>
                    {expandedVariable === v.name && (
                      <VariableDiv
                        key={"variable-div-" + v.name}
                        initial={{ maxHeight: 0 }}
                        animate={{ maxHeight: 200 }}
                        exit={{ maxHeight: 0 }}
                      >
                        <Separator />
                        {content}
                      </VariableDiv>
                    )}
                  </AnimatePresence>
                </VariableContainer>
              );
            })}
          </VariablesControl>
        )}
      </AnimatePresence>

      <Modal
        isVisible={showTemplateModal}
        title={t("select_template")}
        footer={"save"}
        onClose={() => setShowTemplateModal(false)}
        size={"large"}
        style={{
          filter: `opacity(${templateModalOpacity})`,
          // ...(showTemplateModal ? { transition: "all 0.15s ease-in" } : {}),
        }}
      >
        <div>{t("select_your_invoice_template")}</div>
        <TemplateGrid responsive>
          {templates.map((t, key) => (
            <TemplateContainer
              key={t.name}
              onClick={() => {
                setSelectedTemplate(t.name);
                setInvoiceData({
                  ...invoiceData,
                  config: { ...invoiceData.config, template: t.name },
                });
              }}
            >
              {selectedTemplate === t.name && (
                <TemplateCheck>
                  <FontAwesomeIcon icon={faCheckCircle} />
                </TemplateCheck>
              )}
              <TemplateImage src={t.preview} />
              <TemplateName>{t.name}</TemplateName>
            </TemplateContainer>
          ))}
        </TemplateGrid>

        <HideBelowMedium>
          <Separator size={20} />
          <CenteredRow>
            <CenteredCol>
              <SecondaryButton
                onMouseDown={() => setTemplateModalOpacity(0.05)}
                onMouseOut={() => setTemplateModalOpacity(1)}
                onMouseUp={() => setTemplateModalOpacity(1)}
              >
                {t("hold_click_to_preview")}
              </SecondaryButton>
            </CenteredCol>
          </CenteredRow>
        </HideBelowMedium>
      </Modal>

      <Modal
        isVisible={showConfirmExportModal}
        title={t("export_invoice_title")}
        onCancel={() => {
          setShowConfirmExportModal(false);
          setIsExporting(false);
        }}
        onConfirm={() => {
          setShowConfirmExportModal(false);
          if (subscriptionType !== SUBSCRIPTION_TYPE.PREMIUM) {
            setShowExportModal(true);
          } else {
            exportPdf();
          }
        }}
        confirmLabel={t("confirm_export")}
        size={"large"}
      >
        <div>{t("exported_invoice_becomes_readonly")}</div>
        <Separator size={16} />
        <div>{t("you_can_still_change_template")}</div>
      </Modal>

      <Modal
        isVisible={showExportModal}
        title={t("export_invoice_title")}
        onClose={() => {
          setIsExporting(false);
          setShowExportModal(false);
        }}
        size={"large"}
      >
        <div>{t("choose_export")}</div>
        <ExportGrid>
          <ExportType>
            <h2>{t("free_invoice")}</h2>
            <ExportDescription>
              {t("free_invoice_explanation")}
            </ExportDescription>
            <ExportPrice>{t("free")}</ExportPrice>
            <Button onClick={exportPdf}>{t("download_for_free")}</Button>
          </ExportType>
          {featureFlags.enableSinglePurchase && (
            <ExportType>
              <h2>{t("one_time_pay")}</h2>
              <ExportDescription>
                {t("one_time_pay_explanation")}
              </ExportDescription>
              <ExportPrice>1.99$</ExportPrice>
              <Button>{t("pay_and_download")}</Button>
            </ExportType>
          )}
          <ExportType>
            <h2>{t("subscribe_title")}</h2>
            <ExportDescription>{t("subscribe_explanation")}</ExportDescription>
            <ExportPrice>$9.90 {t("per_month")}</ExportPrice>
            <Button>{t("subscribe")}</Button>
          </ExportType>
        </ExportGrid>
      </Modal>

      <Modal
        isVisible={!invoiceReadOnly && showConfigModal}
        title={t("invoice_setup")}
        onCancel={() => setShowConfigModal(false)}
        onSave={() => {
          setShowConfigModal(false);
          updateInvoiceConfig();
        }}
        size={"large"}
      >
        <InvoiceSetupWrapper>
          <form ref={editFormRef}>
            <FlexRow gap={40}>
              <FlexCol
                flex={1}
                dir={"column"}
                style={{ alignItems: "inherit" }}
              >
                <SectionTitle>{t("general_information")}</SectionTitle>
                <div
                  className={
                    highlightedItem === "invoice_number"
                      ? "highlightableRow highlightedItem"
                      : "highlightableRow"
                  }
                >
                  <Row>
                    <FlexCol flex={3}>{t("invoice_language")}</FlexCol>
                    <FlexCol flex={7}>
                      {template.locales.map((l) => {
                        return (
                          <LanguageButton
                            key={"locale-button-" + l}
                            src={langAssets[l]}
                            selected={tempLanguage === l}
                            onClick={() => {
                              setTempLanguage(l);
                            }}
                          />
                        );
                      })}
                    </FlexCol>
                  </Row>
                  <Separator />
                  <Row>
                    <FlexCol flex={3}>{t("invoice_series")}</FlexCol>
                    <FlexCol flex={7}>
                      <Input
                        name={"series"}
                        defaultValue={invoiceData.config.series}
                      />
                    </FlexCol>
                  </Row>
                  <Separator />
                  <Row>
                    <FlexCol flex={3}>{t("invoice_number")}</FlexCol>
                    <FlexCol flex={7}>
                      <Input
                        name={"number"}
                        defaultValue={invoiceData.config.number}
                      />
                    </FlexCol>
                  </Row>
                </div>
                <Separator />
                <Row>
                  <FlexCol flex={3}>{t("tax_rate_percent")}</FlexCol>
                  <FlexCol flex={7}>
                    <Input
                      type={"number"}
                      name={"taxRate"}
                      width={200}
                      defaultValue={invoiceData.config.taxRate}
                    />
                  </FlexCol>
                </Row>
                <Separator />
                <Row>
                  <FlexCol flex={3}>{t("currency")}</FlexCol>
                  <FlexCol flex={7}>
                    <Select
                      name={"currency"}
                      width={200}
                      defaultValue={invoiceData.config.currency}
                    >
                      {Object.keys(currencies).map((currency) => (
                        <option key={currency} value={currency}>
                          {currencies[currency].code} (
                          {currencies[currency].name})
                        </option>
                      ))}
                    </Select>
                  </FlexCol>
                </Row>
                <Separator />
                <Row>
                  <FlexCol flex={3}>
                    {t("include_total_in_other_currency")}
                  </FlexCol>
                  <FlexCol flex={7}>
                    <Select
                      name={"otherCurrency"}
                      width={200}
                      value={tempOtherCurrency || ""}
                      onChange={(e) => setTempOtherCurrency(e.target.value)}
                    >
                      <option value={""}>-- {t("no_other_currency")}</option>
                      {Object.keys(currencies).map((currency) => (
                        <option key={"c-" + currency} value={currency}>
                          {currencies[currency].code} (
                          {currencies[currency].name})
                        </option>
                      ))}
                    </Select>
                  </FlexCol>
                </Row>
                {tempCurrency !== tempOtherCurrency && tempOtherCurrency && (
                  <>
                    <Separator />
                    <Row>
                      <FlexCol flex={3}>{t("exchange_rate")}</FlexCol>
                      <FlexCol flex={7}>
                        <Row>
                          <ExchangeCol flex="0">
                            1 {tempCurrency} =&nbsp;
                          </ExchangeCol>
                          <ExchangeCol>
                            <Input
                              name={"exchangeRate"}
                              defaultValue={invoiceData.config.exchangeRate}
                            />
                          </ExchangeCol>
                          <ExchangeCol>&nbsp;{tempOtherCurrency}</ExchangeCol>
                        </Row>
                      </FlexCol>
                    </Row>
                  </>
                )}
                <Explanation>{t("other_currency_explained")}</Explanation>
                <Separator />
                <Row
                  className={
                    highlightedItem === "date_of_issue"
                      ? "highlightableRow highlightedItem"
                      : "highlightableRow"
                  }
                >
                  <FlexCol flex={3}>{t("invoice_issue_date")}</FlexCol>
                  <FlexCol flex={7}>
                    <DayPickerInput
                      value={invoiceData.config.date}
                      inputProps={{
                        name: "date",
                      }}
                      dayPickerProps={{
                        locale: i18next.language,
                        localeUtils: MomentLocaleUtils,
                      }}
                      className={"input"}
                      format={"YYYY-MM-DD"}
                      formatDate={formatDate}
                      parseDate={parseDate}
                      placeholder={"YYYY-MM-DD"}
                    />
                  </FlexCol>
                </Row>
                <Separator />
                <div
                  className={
                    highlightedItem === "due_date"
                      ? "highlightableRow highlightedItem"
                      : "highlightableRow"
                  }
                >
                  <Row>
                    <FlexCol flex={3}>{t("invoice_due_date")}</FlexCol>
                    <FlexCol flex={7}>
                      <DayPickerInput
                        value={invoiceData.config.dueDate}
                        inputProps={{
                          name: "dueDate",
                        }}
                        dayPickerProps={{
                          locale: i18next.language,
                          localeUtils: MomentLocaleUtils,
                        }}
                        className={"input"}
                        format={"YYYY-MM-DD"}
                        formatDate={formatDate}
                        parseDate={parseDate}
                        placeholder={"YYYY-MM-DD"}
                      />
                    </FlexCol>
                  </Row>
                </div>
              </FlexCol>
              <FlexCol
                flex={1}
                dir={"column"}
                style={{ alignItems: "inherit" }}
              >
                <SectionTitle>{t("footer_information")}</SectionTitle>
                <Row>
                  <FlexCol flex={3}>{t("footer_content")}</FlexCol>
                  <FlexCol flex={7}>
                    <Textarea
                      name={"footer"}
                      defaultValue={invoiceData.config.footer}
                    />
                  </FlexCol>
                </Row>
                <Separator />
                <InfoBar>{t("footer_infobar")}</InfoBar>
              </FlexCol>
            </FlexRow>
            <Separator />

            <FlexRow gap={40}>
              <FlexCol
                flex={1}
                dir={"column"}
                style={{ alignItems: "inherit" }}
                className={
                  highlightedItem === "buyer"
                    ? "highlightableRow highlightedItem"
                    : "highlightableRow"
                }
              >
                <SectionTitle style={{ width: "100%" }}>
                  <Row gap={16}>
                    {t("buyer")}
                    {inventoryCustomers.length > 0 && (
                      <Button
                        type="button"
                        small
                        onClick={() => setShowCustomerInventoryModal(true)}
                      >
                        pick from catalogue
                      </Button>
                    )}
                  </Row>
                </SectionTitle>

                <Row>
                  <FlexCol flex={3}>{t("name")}</FlexCol>
                  <FlexCol flex={7}>
                    <Input
                      name={"buyer.name"}
                      defaultValue={invoiceData.content.buyer.name}
                      ref={buyerNameInputRef}
                    />
                  </FlexCol>
                </Row>
                <Separator />
                <Row>
                  <FlexCol flex={3}>{t("address")}</FlexCol>
                  <FlexCol flex={7}>
                    <Textarea
                      name={"buyer.address"}
                      defaultValue={invoiceData.content.buyer.address}
                      ref={buyerAddressInputRef}
                    />
                  </FlexCol>
                </Row>
                <Separator />
                <Row>
                  <FlexCol flex={3}>{t("phone_number")}</FlexCol>
                  <FlexCol flex={7}>
                    <Input
                      name={"buyer.phone"}
                      defaultValue={invoiceData.content.buyer.phone}
                      ref={buyerPhoneInputRef}
                    />
                  </FlexCol>
                </Row>
                <Separator />
                <Row>
                  <FlexCol flex={3}>{t("additional_info")}</FlexCol>
                  <FlexCol flex={7}>
                    <Textarea
                      name={"buyer.additionalInfo"}
                      defaultValue={invoiceData.content.buyer.additionalInfo}
                      ref={buyerInfoInputRef}
                    />
                  </FlexCol>
                </Row>
              </FlexCol>
              <FlexCol
                flex={1}
                dir={"column"}
                style={{ alignItems: "inherit" }}
                className={
                  highlightedItem === "seller"
                    ? "highlightableRow highlightedItem"
                    : "highlightableRow"
                }
              >
                <SectionTitle>{t("seller")}</SectionTitle>
                <Row>
                  <FlexCol flex={3}>{t("name")}</FlexCol>
                  {!!user && (
                    <FlexCol flex={7}>
                      {invoiceData.content.seller.name}
                    </FlexCol>
                  )}
                  {!user && (
                    <FlexCol flex={7}>
                      <Input
                        name={"seller.name"}
                        defaultValue={invoiceData.content.seller.name}
                      />
                    </FlexCol>
                  )}
                </Row>
                <Separator size={4} />
                {canUpdateName && (
                  <Row>
                    <FlexCol flex={3} />
                    <FlexCol flex={7}>
                      <Button small onClick={changeSellerName} type={"button"}>
                        change seller name
                      </Button>
                    </FlexCol>
                  </Row>
                )}
                <Separator />
                <Row>
                  <FlexCol flex={3}>{t("address")}</FlexCol>
                  <FlexCol flex={7}>
                    <Textarea
                      name={"seller.address"}
                      defaultValue={invoiceData.content.seller.address}
                    />
                  </FlexCol>
                </Row>
                <Separator />
                <Row>
                  <FlexCol flex={3}>{t("phone_number")}</FlexCol>
                  <FlexCol flex={7}>
                    <Input
                      name={"seller.phone"}
                      defaultValue={invoiceData.content.seller.phone}
                    />
                  </FlexCol>
                </Row>
                <Separator />
                <Row>
                  <FlexCol flex={3}>{t("additional_info")}</FlexCol>
                  <FlexCol flex={7}>
                    <Textarea
                      name={"seller.additionalInfo"}
                      defaultValue={invoiceData.content.seller.additionalInfo}
                    />
                  </FlexCol>
                </Row>
              </FlexCol>
            </FlexRow>
            <Separator />
          </form>
        </InvoiceSetupWrapper>
      </Modal>

      <Modal
        isVisible={!invoiceReadOnly && showEditModal}
        title={t("edit_invoice")}
        onClose={() => setShowEditModal(false)}
        ref={editModalRef}
        size={"large"}
      >
        <InvoiceSetupWrapper>
          <InfoBar>{t("feel_free_to_drag_and_drop")}</InfoBar>
          <Separator size={16} />

          <TableDiv>
            <Row className={"header"} style={{ fontWeight: "bold" }}>
              <FlexCol flex={4}>{t("description")}</FlexCol>
              <FlexCol flex={2}>{t("price")}</FlexCol>
              <FlexCol flex={2}>{t("quantity")}</FlexCol>
              <FlexCol flex={3}>{t("total")}</FlexCol>
              <FlexCol flex={2}>{t("actions")}</FlexCol>
            </Row>

            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {invoiceData.items.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => {
                          if (snapshot.isDragging) {
                            provided.draggableProps.style.left =
                              provided.draggableProps.style.offsetLeft;
                            provided.draggableProps.style.top =
                              provided.draggableProps.style.offsetTop;
                          }
                          return (
                            <Row
                              className={"row"}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style,
                                index
                              )}
                            >
                              <FlexCol flex={4}>{item.name}</FlexCol>
                              <FlexCol flex={2}>
                                {getFormattedAmount(
                                  item.unitCost,
                                  invoiceData.config.currency
                                )}
                              </FlexCol>
                              <FlexCol flex={2}>{item.qty}</FlexCol>
                              <FlexCol flex={3}>
                                {parseFloat(item.qty) > 0
                                  ? getFormattedAmount(
                                      item.unitCost * item.qty,
                                      invoiceData.config.currency
                                    )
                                  : getFormattedAmount(
                                      item.unitCost,
                                      invoiceData.config.currency
                                    )}
                              </FlexCol>
                              <FlexCol flex={2}>
                                <SecondaryButton
                                  small
                                  onClick={() => removeItem(index)}
                                >
                                  {t("remove")}
                                </SecondaryButton>
                              </FlexCol>
                            </Row>
                          );
                        }}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <form onSubmit={addItem} ref={addItemRef}>
              <FlexRow gap={12} className={"row"}>
                <FlexCol flex={4}>
                  <Input
                    type="text"
                    name="d"
                    placeholder={t("description")}
                    ref={descriptionRef}
                  />
                </FlexCol>
                <FlexCol flex={2}>
                  <Input
                    type="number"
                    step="any"
                    name="p"
                    placeholder={t("price")}
                  />
                </FlexCol>
                <FlexCol flex={2}>
                  <Input name="q" placeholder={t("quantity")} />
                </FlexCol>
                <FlexCol flex={3}>
                  <Centered>
                    <Button small>{t("add_item_lowercase")}</Button>
                  </Centered>
                </FlexCol>
                <FlexCol flex={2} />
              </FlexRow>
            </form>
          </TableDiv>
          {usingBarcode && (
            <>
              <Separator size={16} />
              <FlexRow gap={16}>
                <FlexCol flex={1}>
                  <form onSubmit={handleScanner} style={{ width: "100%" }}>
                    <Input
                      name="barcode"
                      ref={scannerInputRef}
                      placeholder="Keep this input focused when scanning"
                      onBlur={() => setUsingBarcode(false)}
                    />
                  </form>
                </FlexCol>
                <FlexCol flex={3}>
                  <DangerButton block onClick={() => setUsingBarcode(false)}>
                    stop scanning
                  </DangerButton>
                </FlexCol>
              </FlexRow>
            </>
          )}
          {hasPaidSubscription &&
            inventoryProducts.length > 0 &&
            !usingBarcode && (
              <>
                <Separator size={16} />
                <FlexRow gap={8}>
                  <DangerButton block onClick={() => setUsingBarcode(true)}>
                    use a barcode scanner
                  </DangerButton>

                  <SecondaryButton
                    block
                    onClick={() => setShowProductInventoryModal(true)}
                  >
                    or add from catalogue
                  </SecondaryButton>
                </FlexRow>
              </>
            )}
        </InvoiceSetupWrapper>
      </Modal>
      <Modal
        isVisible={showChangeSellerNameModal}
        title={t("change_seller_name")}
        onCancel={() => {
          setTempSellerName("");
          setShowChangeSellerNameModal(false);
        }}
        onSave={() => {
          doUpdateSellerName();
        }}
        overlayIndex={122}
      >
        <InfoBar>
          <Trans
            i18nKey={"you_can_only_change_name_once_every_60_days"}
            components={{
              contactLink: (
                <a
                  href="https://www.visualinvoicer.com/contact"
                  target="_blank"
                />
              ),
            }}
          />
        </InfoBar>
        <Separator size={16} />
        <Row>
          <FlexCol flex={3}>{t("new_seller_name")}</FlexCol>
          <FlexCol flex={7}>
            <Input
              defaultValue={invoiceData.content.seller.name}
              onChange={(e) => setTempSellerName(e.target.value)}
            />
          </FlexCol>
        </Row>
      </Modal>

      <Modal
        title={t("reset_invoice_title")}
        content={t("reset_invoice_content")}
        footer={"save"}
        isVisible={showResetModal}
        onConfirm={() => setShowResetModal(false)}
        onCancel={() => setShowResetModal(false)}
        danger
      />

      <Modal
        title={"Customer catalogue"}
        style={{ width: "900px", maxWidth: "98vw" }}
        content={
          <>
            <TableWrapper>
              <Table>
                <thead>
                  <TableHeader>
                    <TableHeaderCell width="10%" mobileWidth="10%">
                      #
                    </TableHeaderCell>
                    <TableHeaderCell width="80%" mobileWidth="80%">
                      name
                    </TableHeaderCell>
                    <TableHeaderCell />
                  </TableHeader>
                </thead>
                <tbody>
                  {inventoryCustomers.map((p, k) => (
                    <TableRow key={`customer-${k}`}>
                      <TableCell width="10%">{k + 1}</TableCell>
                      <TableCell width="80%">{p.name}</TableCell>

                      <TableCell>
                        <Button
                          small
                          style={{ width: "auto" }}
                          onClick={() => {
                            updateBuyer({ name: p.name, ...p.info });
                            setShowCustomerInventoryModal(false);
                          }}
                        >
                          use customer
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </tbody>
              </Table>
            </TableWrapper>
          </>
        }
        isVisible={showCustomerInventoryModal}
        onClose={() => setShowCustomerInventoryModal(false)}
        danger
        overlayIndex={1111}
        modalIndex={1112}
      />

      <Modal
        title={"Product catalogue"}
        style={{ width: "900px", maxWidth: "98vw" }}
        content={
          <>
            <TableWrapper>
              <Table>
                <thead>
                  <TableHeader>
                    <TableHeaderCell width="60%" mobileWidth="60%">
                      product
                    </TableHeaderCell>
                    <TableHeaderCell width="25%" mobileWidth="25%">
                      price
                    </TableHeaderCell>
                    <TableHeaderCell />
                  </TableHeader>
                </thead>
                <tbody>
                  {inventoryProducts.map((p, k) => (
                    <TableRow key={`product-${k}`}>
                      <TableCell width="60%">{p.name}</TableCell>
                      <TableCell width="25%">{p.info.unitCost}</TableCell>

                      <TableCell>
                        <Button
                          small
                          style={{ width: "auto" }}
                          onClick={() => {
                            const count = prompt("Enter the quantity", 0);
                            if (!count || !parseInt(count, 10)) {
                              return;
                            }

                            setInvoiceData({
                              ...invoiceData,
                              items: [
                                ...invoiceData.items,
                                {
                                  id: uuidv4(),
                                  unitCost: p.info.unitCost,
                                  name: p.name,
                                  qty: count,
                                },
                              ],
                            });
                          }}
                        >
                          add product
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </tbody>
              </Table>
            </TableWrapper>
          </>
        }
        isVisible={showProductInventoryModal}
        onClose={() => setShowProductInventoryModal(false)}
        danger
        overlayIndex={1111}
        modalIndex={1112}
      />
    </>
  );
};

export default Editor;
