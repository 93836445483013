let AUTH_SERVER_URL;
let VENDOR_ID;
let PRODUCTS;

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  // dev code
  AUTH_SERVER_URL = "http://localhost:3000/api";
  VENDOR_ID = 7039;
  PRODUCTS = {
    subscriptionId: 47192,
    singlePurchaseId: 47193,
  };
} else {
  // production code
  AUTH_SERVER_URL = "https://api.visualinvoicer.com/api";
  VENDOR_ID = 151078;
  PRODUCTS = {
    subscriptionId: 833519,
    singlePurchaseId: 47193,
  };
}

export { AUTH_SERVER_URL, PRODUCTS, VENDOR_ID };
