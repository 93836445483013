import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  products: [],
  customers: [],
  productsLoaded: false,
  customersLoaded: false,
};

export const inventoriesSlice = createSlice({
  name: "inventories",
  initialState,
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload;
      state.productsLoaded = true;
    },
    setCustomers: (state, action) => {
      state.customers = action.payload;
      state.customersLoaded = true;
    },
    setProductsLoaded: (state) => {
      state.productsLoaded = true;
    },
    setCustomersLoaded: (state) => {
      state.customersLoaded = true;
    },
  },
});

export const {
  setProducts,
  setCustomers,
  setProductsLoaded,
  setCustomersLoaded,
} = inventoriesSlice.actions;

export default inventoriesSlice.reducer;
