import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import Lottie from "react-lottie-player";
import {
  InfoBarPrimary,
  FlexRow,
  Row,
  SecondaryButton,
  Separator,
  Button,
  TextButton,
} from "../../lib/components";
import { useAuthState } from "react-firebase-hooks/auth";
import getFirebase, { auth } from "../../firebase";
import { LoaderWithWrapper } from "../../components/loader/Loader";
import Footer from "../../components/footer/Footer";

import { AiOutlineApi } from "react-icons/ai";
import { BiHome, BiLinkExternal, BiPalette, BiSupport } from "react-icons/bi";
import { BsFiles, BsStars } from "react-icons/bs";
import { FiMoon, FiSun } from "react-icons/fi";
import { IoPersonOutline } from "react-icons/io5";
import {
  MdOutlineInventory,
  MdOutlineInventory2,
  MdSwitchAccount,
} from "react-icons/md";

import SimpleBar from "simplebar-react";
import Dashboard from "./screens/Dashboard";
import Branding from "./screens/Branding";
import Modal from "../../components/modal/Modal";
import { useInvoiceOutlet } from "../../context/invoice";
import i18n from "i18next";
import { useDispatch, useSelector } from "react-redux";
import Account from "./screens/Account";
import Subscription, { Centered } from "./screens/subscription/Subscription";
import { isObjectEmpty } from "../../lib/utils";
import { setTheme } from "../../redux/settingsSlice";
import featureFlags from "../../lib/featureFlags";
import { Trans, useTranslation } from "react-i18next";
import { media } from "../../components/mixins";
import { AnimatePresence, motion } from "framer-motion";
import Refer from "./screens/Refer";
import Invoices from "./screens/Invoices";
import useIsFreeAccountLimitReached from "../../hooks/useIsFreeAccountLimitReached";
import {
  createFlag,
  profileSelectors,
  setInvoicesLastMonth,
  setSubscription,
} from "../../redux/profileSlice";
import { bCreateFlag } from "../../backend/profile";
import lottieWelcome from "../../assets/animations/welcome3.json";
import { bGetSubscription } from "../../backend/subscription";
import Inventories from "./screens/Inventories";
import useHasPaidSubscription from "../../hooks/useHasPaidSubscription";

const Wrapper = styled.div`
  height: calc(100vh - 60px);
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.dashboardBg};
  color: ${({ theme }) => theme.colors.dashboardFg};

  ${media.belowMedium} {
    height: calc(var(--doc-height) - 116px);
  }
`;

const Container = styled.div`
  width: 80vw;
  max-width: 1400px;
  margin: 0 auto;
  padding: 40px;

  ${media.belowMedium} {
    width: 100vw;
    padding: 10px;
    margin-bottom: 60px;
  }
`;

const MenuCol = styled.div`
  min-width: 200px;
  background-color: ${({ theme }) => theme.colors.menuBg};
  display: flex;
  flex-direction: column;
  padding: 40px 16px;
  border-right: solid 1px ${({ theme }) => theme.colors.menuBorder};
  gap: 8px;

  button {
    background-color: ${({ theme }) => theme.colors.menuBg};
    color: ${({ theme }) => theme.colors.menuFg};
  }

  a {
    text-decoration: none;
    width: 100%;
  }

  ${media.belowMedium} {
    display: none;
  }
`;

const MobileMenu = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.menuBg};
  border-top: solid 1px ${({ theme }) => theme.colors.menuBorder};

  overflow-x: auto;

  button {
    background-color: ${({ theme }) => theme.colors.menuBg};
    color: ${({ theme }) => theme.colors.menuFg};
    height: 100%;
  }

  ${media.medium} {
    display: none;
  }

  a {
    text-decoration: none;
  }
`;

const MobileMenuMore = styled(motion.div)`
  height: 60px;
  width: 40px;
  background-image: linear-gradient(
    ${({ right }) => (right ? "90deg" : "270deg")},
    transparent 0%,
    #aaaaaa 50%,
    #aaaaaa 100%
  );
  position: fixed;
  z-index: 1111;
  bottom: 0;
  ${({ right }) => (right ? "right: 0" : "left: 0")};
  pointer-events: none;
`;

const MobileMenuButton = styled(SecondaryButton)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  font-size: 10px;
  border-radius: 0;
  ${({ selected, theme }) =>
    selected
      ? `
    background-color: #14143233;
    color: ${theme.colors.primary};
    font-weight: bold;
  `
      : ""}

  span {
    display: flex;
    font-size: 15px;
    line-height: 15px;
  }
`;

const noTabStyle = { tabIndex: "-1" };

const NavButton = styled(SecondaryButton)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;

  svg {
    transition: all 0.1s linear;
  }
  ${({ selected, theme }) =>
    selected
      ? `
    background-color: #14143233;
    color: ${theme.colors.primary};
    font-weight: bold;
    // border-left: solid 2px #000000;
    // border-radius: 0px 4px 4px 0;

    svg {
      fill: ${theme.colors.primary};
      stroke: ${theme.colors.primary};
      transform: scale(1.4);
    }
  `
      : ""}

  span {
    display: flex;
    font-size: 15px;
    line-height: 15px;
  }
`;

const ReferralButton = styled(NavButton)`
  border: 0; //solid 2px transparent;
  //border-radius: 8px;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(
      101deg,
      ${({ theme }) => `${theme.colors.link}, ${theme.colors.primaryDark}`}
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #ffffffaa inset;
  color: #000000 !important;
  transition: all 0.1s linear;

  svg {
    fill: #000000 !important;
    stroke: #000000 !important;
    //transform: scale(1.4);
  }

  :hover,
  :focus {
    box-shadow: none;
    color: #ffffff !important;
    svg {
      fill: #000000 !important;
      stroke: #000000 !important;
      //transform: scale(1.4);
    }
  }
`;

const TopBanner = styled.div`
  position: absolute;
  z-index: 1111111111;
  top: 0;
  left: 0;
  right: 0;
  background-color: #d35400;
  color: #ffffff;
  padding: 8px;
  text-align: center;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;

  div {
    flex: 1;
  }

  a {
    text-decoration: underline;
    color: #ffffff;
  }
`;

const HighlightedNavButton = styled(NavButton)`
  /*
  background-color: ${({ theme }) => theme.colors.danger} !important;
  color: #ffffff !important;
  font-weight: bold;
*/
  svg {
    transition: none;
  }

  :hover,
  :focus {
    filter: none !important;
    color: #ffffff !important;
    background-color: ${({ theme }) => theme.colors.danger} !important;
  }
`;

const Soon = styled.div`
  :before {
    content: "SOON";
    color: #ffffff;
    font-size: 9px;
    font-weight: bold;
    background-color: #1f1f47;
    border-radius: 4px;
    padding: 2px;
    position: absolute;
    top: 0;
    right: -12px;
  }
`;

const NavSeparator = styled.div`
  flex: 1;
`;

const NavSeparatorBorder = styled.div`
  border-bottom: solid 1px #14143233;
  margin: 8px 0;
`;

const ContentCol = styled.div`
  flex: 1;
`;

const SubscriptionLabel = styled.div`
  margin: 0 auto;
  font-size: 12px;

  background-color: ${({ theme }) => theme.colors.dashboardBg};
  color: ${({ theme }) => theme.colors.dashboardFg};
  border-radius: 16px;
  padding: 4px 16px;
`;

const Home = ({ onLogin, retrievingProfile = { retrievingProfile } }) => {
  const [user, loading, error] = useAuthState(auth);
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [showIntegrationModal, setShowIntegrationModal] = useState(false);
  const history = useHistory();
  const nav = (path) => history.push(path);
  const [showOnboarding, setShowOnboarding] = useState(true);
  const { setInvoice } = useInvoiceOutlet();
  const dispatch = useDispatch();
  const [hasMoreLeft, setHasMoreLeft] = useState(false);
  const [hasMoreRight, setHasMoreRight] = useState(false);
  const [showTopBanner, setShowTopBanner] = useState(true);

  const profile = useSelector((state) => state.profile.profile);
  const retrievedProfile = !!profile;
  const theme = useSelector((state) => state.settings.theme);
  const subscriptionType = useSelector(
    (state) => state.profile.subscriptionType
  );
  const flags = useSelector(profileSelectors.getFlags);

  useEffect(() => {
    if (profile) {
      bGetSubscription().then(({ subscription, invoicesLastMonth }) => {
        dispatch(setSubscription(subscription));
        dispatch(setInvoicesLastMonth(invoicesLastMonth || 0));
      });
    }
  }, [profile]);

  useEffect(() => {
    if (flags && flags.userOnboarded) {
      setShowOnboarding(false);
    }
  }, [flags]);

  const isCreateDisabled = useIsFreeAccountLimitReached();
  const hasPaidSubscription = useHasPaidSubscription();

  const onMobileMenuScroll = useCallback((e) => {
    setHasMoreLeft(e.target.scrollLeft > 10);
    setHasMoreRight(
      e.target.scrollLeft + e.target.offsetWidth < e.target.scrollWidth - 10
    );
  }, []);

  const closeOnboarding = () => {
    dispatch(createFlag("userOnboarded"));
    bCreateFlag("userOnboarded");
    setShowOnboarding(false);
  };

  useEffect(() => {
    if (!retrievingProfile && !retrievedProfile) {
      onLogin();
      return;
    }

    if (!loading && !user) {
      onLogin();
    }
  }, [retrievingProfile, retrievedProfile, loading, user]);

  if (!retrievingProfile && !retrievedProfile) {
    return (
      <>
        <LoaderWithWrapper />
      </>
    );
  }

  if (!retrievedProfile || (loading && !user)) {
    return (
      <>
        <LoaderWithWrapper />
      </>
    );
  }

  if (!loading && !user) {
    return <>No auth</>;
  }

  return (
    <>
      {isCreateDisabled && showTopBanner && (
        <TopBanner>
          <div>
            {t("you_have_reached_the_limit")}{" "}
            <Trans
              i18nKey={"click_here_to_upgrade"}
              components={{
                link: (
                  <Link
                    to="/home/subscription"
                    onClick={() => setShowTopBanner(false)}
                  >
                    Click here
                  </Link>
                ),
              }}
            />{" "}
          </div>
          <TextButton onClick={() => setShowTopBanner(false)}>
            {t("close")}
          </TextButton>
        </TopBanner>
      )}

      <Wrapper>
        <Row style={{ height: "100%" }}>
          <MenuCol>
            <Link to="/home" {...noTabStyle}>
              <NavButton selected={pathname === "/home"}>
                <span>
                  <BiHome />
                </span>
                {t("new_invoice")}
              </NavButton>
            </Link>
            <Link to="/home/invoices" {...noTabStyle}>
              <NavButton selected={pathname === "/home/invoices"}>
                <span>
                  <BsFiles />
                </span>
                {t("invoices")}
              </NavButton>
            </Link>
            <Link to="/home/branding" {...noTabStyle}>
              <NavButton selected={pathname === "/home/branding"}>
                <span>
                  <BiPalette />
                </span>
                {t("branding_menu")}
              </NavButton>
            </Link>
            {hasPaidSubscription && (
              <Link to="/home/inventories" {...noTabStyle}>
                <NavButton selected={pathname === "/home/inventories"}>
                  <span>
                    <MdOutlineInventory2 />
                  </span>
                  {t("catalogues")}
                </NavButton>
              </Link>
            )}
            <NavButton
              onClick={() => setShowIntegrationModal(true)}
              style={{ position: "relative" }}
            >
              <span>
                <AiOutlineApi />
              </span>
              {t("api_integrations")}
              <Soon />
            </NavButton>

            <NavSeparator />
            <SubscriptionLabel>
              {t("subscription_label", { type: subscriptionType })}
            </SubscriptionLabel>

            <NavSeparatorBorder />

            {featureFlags.enableCardSubscription && (
              <Link to="/home/subscription" {...noTabStyle}>
                <NavButton selected={pathname === "/home/subscription"}>
                  <span>
                    <MdSwitchAccount />
                  </span>
                  {t("subscription")}
                </NavButton>
              </Link>
            )}

            <a
              href="https://www.visualinvoicer.com/contact"
              target="_blank"
              {...noTabStyle}
            >
              <NavButton>
                <span>
                  <BiSupport />
                </span>
                {t("support")}
                <span>
                  <BiLinkExternal />
                </span>
              </NavButton>
            </a>
            <Link to="/home/account" {...noTabStyle}>
              <NavButton selected={pathname === "/home/account"}>
                <span>
                  <IoPersonOutline />
                </span>
                {t("account_settings")}
              </NavButton>
            </Link>
            {theme === "light" ? (
              <NavButton onClick={() => dispatch(setTheme("dark"))}>
                <span>
                  <FiMoon />
                </span>
                {t("dark_mode")}
              </NavButton>
            ) : (
              <NavButton onClick={() => dispatch(setTheme("light"))}>
                <span>
                  <FiSun />
                </span>
                {t("light_mode")}
              </NavButton>
            )}

            {featureFlags.enableReferrals && (
              <Link to="/home/refer" {...noTabStyle}>
                <ReferralButton selected={pathname === "/home/refer"}>
                  <span>
                    <BsStars />
                  </span>
                  {t("refer_a_friend")}
                </ReferralButton>
              </Link>
            )}
          </MenuCol>
          <ContentCol>
            <SimpleBar className="simplebar-container">
              <Container>
                <Switch>
                  <Route path="/home/invoices" render={() => <Invoices />} />
                  <Route path="/home/branding" render={() => <Branding />} />
                  <Route path="/home/account" render={() => <Account />} />
                  <Route path="/home/refer" render={() => <Refer />} />
                  {hasPaidSubscription && (
                    <Route
                      path="/home/inventories"
                      render={() => <Inventories />}
                    />
                  )}
                  <Route
                    path="/home/subscription"
                    render={() => <Subscription />}
                  />
                  <Route path="/home" render={() => <Dashboard />} />
                </Switch>
              </Container>
            </SimpleBar>
          </ContentCol>
        </Row>
      </Wrapper>
      <MobileMenu onScroll={onMobileMenuScroll}>
        <AnimatePresence>
          {hasMoreLeft && (
            <MobileMenuMore
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              key="has-more-left"
            />
          )}
        </AnimatePresence>
        <Link to="/home" {...noTabStyle}>
          <MobileMenuButton selected={pathname === "/home"}>
            <span>
              <BiHome />
            </span>
            {t("new_invoice")}
          </MobileMenuButton>
        </Link>
        <Link to="/home/invoices" {...noTabStyle}>
          <MobileMenuButton selected={pathname === "/home/invoices"}>
            <span>
              <BsFiles />
            </span>
            {t("invoices")}
          </MobileMenuButton>
        </Link>
        <Link to="/home/branding" {...noTabStyle}>
          <MobileMenuButton selected={pathname === "/home/branding"}>
            <span>
              <BiPalette />
            </span>
            {t("branding_menu")}
          </MobileMenuButton>
        </Link>
        <MobileMenuButton onClick={() => setShowIntegrationModal(true)}>
          <span>
            <AiOutlineApi />
          </span>
          {t("api_integrations")}
        </MobileMenuButton>

        {featureFlags.enableCardSubscription && (
          <Link to="/home/subscription" {...noTabStyle}>
            <MobileMenuButton>
              <span>
                <MdSwitchAccount />
              </span>
              {t("subscription")}
            </MobileMenuButton>
          </Link>
        )}
        <a href="https://www.visualinvoicer.com/contact" target="_blank">
          <MobileMenuButton>
            <span>
              <BiSupport />
            </span>
            {t("support")}
          </MobileMenuButton>
        </a>
        <Link to="/home/account" {...noTabStyle}>
          <MobileMenuButton>
            <span>
              <IoPersonOutline />
            </span>
            {t("account_settings")}
          </MobileMenuButton>
        </Link>
        {theme === "light" ? (
          <MobileMenuButton onClick={() => dispatch(setTheme("dark"))}>
            <span>
              <FiMoon />
            </span>
            {t("dark_mode")}
          </MobileMenuButton>
        ) : (
          <MobileMenuButton onClick={() => dispatch(setTheme("light"))}>
            <span>
              <FiSun />
            </span>
            {t("light_mode")}
          </MobileMenuButton>
        )}
        <AnimatePresence>
          {hasMoreRight && (
            <MobileMenuMore
              key={"has-more-right"}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              right
            />
          )}
        </AnimatePresence>
      </MobileMenu>
      <Modal
        title={t("api_integrations")}
        content={
          <>
            <InfoBarPrimary>{t("coming_soon")}</InfoBarPrimary>
            <Separator size={16} />
            <div>{t("api_integrations_p1")}</div>
            <Separator />
            {t("api_integrations_p2")}

            <Separator />
            {t("api_integrations_p3")}
          </>
        }
        isVisible={showIntegrationModal}
        onClose={() => setShowIntegrationModal(false)}
        danger
      />

      <Modal
        content={
          <>
            <Separator size={24} />
            <Centered>
              <Lottie
                loop={false}
                animationData={lottieWelcome}
                play
                style={{ width: 280, height: 100 }}
              />
            </Centered>
            <h3>{t("welcome_p1")}</h3>
            <Separator />
            {t("welcome_p2")}
            <Separator />
            {t("welcome_p3")}
            <Separator size={24} />
            <Centered>
              <Row gap={8}>
                <Link to={"/home/branding"} onClick={closeOnboarding}>
                  <Button {...noTabStyle}>{t("go_to_branding")}</Button>
                </Link>
                <SecondaryButton onClick={closeOnboarding}>
                  {t("create_your_first_invoice")}
                </SecondaryButton>
              </Row>
            </Centered>
          </>
        }
        isVisible={showOnboarding}
        onClose={closeOnboarding}
        hideHeader
        secondary
      />

      <Footer />
    </>
  );
};

export default Home;
