import {
  getFormattedAmount,
  getInvoiceSubTotal,
  getInvoiceTax,
  getInvoiceTotal,
} from "./utils";

import simpleImg from "../assets/simple.png";
import elegantImg from "../assets/elegant.png";
import modernImg from "../assets/modern.png";
import neatImg from "../assets/neat.png";
import simpleSmallImg from "../assets/simple-small.jpg";
import elegantSmallImg from "../assets/elegant-small.jpg";
import modernSmallImg from "../assets/modern-small.jpg";
import neatSmallImg from "../assets/neat-small.jpg";

import format from "string-template";

const sizeKeys = [
  "fontSize",
  "paddingTop",
  "paddingBottom",
  "paddingLeft",
  "paddingRight",
  "marginTop",
  "marginBottom",
  "marginLeft",
  "marginRight",
  "borderWidth",
  "borderBottomWidth",
  "borderTopWidth",
  "top",
  "left",
  "right",
  "bottom",
  "letterSpacing",
  "maxWidth",
  "marginTop",
  "width",
  "height",
  "minHeight",
  "lineHeight",
];

export const buildStylesWithZoom = (
  stylesObj,
  zoom,
  variables,
  hasTax,
  invoiceData
) => {
  const data = { ...stylesObj };
  for (let key in data) {
    if (!data.hasOwnProperty(key)) {
      continue;
    }

    if (typeof data[key] === "object") {
      data[key] = buildStylesWithZoom(
        data[key],
        zoom,
        variables,
        hasTax,
        invoiceData
      );
    } else if (key === "displayCondition") {
      data["display"] = data["displayCondition"](invoiceData);
      delete data["displayCondition"];
    } else if (key === "widthWithTax" || key === "widthWithoutTax") {
      if (hasTax) {
        data["width"] = data["widthWithTax"];
      } else {
        data["width"] = data["widthWithoutTax"];
      }
      delete data["widthWithTax"];
      delete data["widthWithoutTax"];
    } else if (
      sizeKeys.indexOf(key) !== -1 &&
      (typeof data[key] === "number" ||
        (typeof data[key] === "string" &&
          data[key].substr(data[key].length - 1, 1) !== "%"))
    ) {
      if (data[key].substring && data[key].substring(0, 10) === "{variables") {
        // value is variable
        const variable = data[key].substring(1, data[key].indexOf("}"));
        data[key] = (variables[variable] * zoom).toFixed(2) + "px";
      } else {
        data[key] = (data[key] * zoom).toFixed(2) + "px";
      }
    }
  }

  return data;
};

export const buildCssStylesFromStylesObj = (obj) => {
  // Object.entries(obj)
  //   .map(([k, v]) => `${k}:${v}`)
  //   .join(";")
  //   .replace(/[A-Z]/g, (match) => `-${match.toLowerCase()}`);

  return Object.entries(obj)
    .map(
      ([k, v]) =>
        `${k.replace(/[A-Z]/g, (match) => `-${match.toLowerCase()}`)}:${v}`
    )
    .join(";");
};

export const buildHTML = (invoiceData, templateObj, zoom, editorComponents) => {
  const {
    strings: allStrings,
    locales,
    styles,
    template,
    itemRow,
    variables,
  } = templateObj;
  let strings = allStrings["en"];

  if (
    invoiceData.config.language &&
    locales.indexOf(invoiceData.config.language) > -1
  ) {
    strings = allStrings[invoiceData.config.language];
  }

  const variablesObj = {};
  for (let i = 0; i < variables.length; i++) {
    variablesObj["variables__" + variables[i].name] = variables[i].default;
  }

  const userVariables = invoiceData.config.userVariables || {};

  for (const userVar in userVariables) {
    if (userVariables.hasOwnProperty(userVar) && userVariables[userVar]) {
      variablesObj["variables__" + userVar] = userVariables[userVar];
    }
  }

  const zoomedStyles = buildStylesWithZoom(
    styles,
    zoom,
    variablesObj,
    invoiceData.config.taxRate > 0,
    invoiceData
  );

  const stringifiedStyles = {};

  for (let key in zoomedStyles) {
    stringifiedStyles["styles__" + key] = format(
      buildCssStylesFromStylesObj(zoomedStyles[key]),
      { ...variablesObj }
    );
  }

  const invoiceDataKeys = {
    invoiceSeries: invoiceData.config.series,
    invoiceNumber: invoiceData.config.number,
    invoiceSeriesAndNumber:
      invoiceData.config.series + invoiceData.config.number,
    invoiceDate: invoiceData.config.date,
    invoiceDueDate: invoiceData.config.dueDate,
    footer: invoiceData.config.footer,
    buyerName: invoiceData.content.buyer.name,
    buyerAddress: invoiceData.content.buyer.address,
    buyerPhone: invoiceData.content.buyer.phone,
    buyerAdditionalInfo: invoiceData.content.buyer.additionalInfo,
    sellerName: invoiceData.content.seller.name,
    sellerAddress: invoiceData.content.seller.address,
    sellerPhone: invoiceData.content.seller.phone,
    sellerAdditionalInfo: invoiceData.content.seller.additionalInfo,
    invoiceTotal: getFormattedAmount(
      getInvoiceTotal(invoiceData),
      invoiceData.config.currency
    ),
    invoiceTotalInOtherCurrency:
      invoiceData.config.otherCurrency && invoiceData.config.exchangeRate
        ? getFormattedAmount(
            getInvoiceTotal(invoiceData) * invoiceData.config.exchangeRate,
            invoiceData.config.otherCurrency
          )
        : getFormattedAmount(
            getInvoiceTotal(invoiceData),
            invoiceData.config.currency
          ),
    invoiceSubtotal: getFormattedAmount(
      getInvoiceSubTotal(invoiceData),
      invoiceData.config.currency
    ),
    invoiceTaxRate: invoiceData.config.taxRate,
    invoiceTax: getFormattedAmount(
      getInvoiceTax(invoiceData),
      invoiceData.config.currency
    ),
    exchangeRateString: `${getFormattedAmount(
      1,
      invoiceData.config.currency
    )} = ${invoiceData.config.otherCurrency} ${
      invoiceData.config.exchangeRate
    }`,
  };

  let itemsString = "";
  for (let i = 0; i < invoiceData.items.length; i++) {
    const item = invoiceData.items[i];

    itemsString += format(itemRow, {
      ...stringifiedStyles,
      itemName: item.name,
      itemUnitCost: getFormattedAmount(
        item.unitCost,
        invoiceData.config.currency
      ),
      itemQuantity: item.qty,
      itemTotalWithoutTax:
        parseFloat(item.qty) > 0
          ? getFormattedAmount(
              item.unitCost * item.qty,
              invoiceData.config.currency
            )
          : getFormattedAmount(item.unitCost, invoiceData.config.currency),
      itemTax:
        parseFloat(item.qty) > 0
          ? getFormattedAmount(
              (item.unitCost * item.qty * invoiceData.config.taxRate) / 100,
              invoiceData.config.currency
            )
          : getFormattedAmount(
              (item.unitCost * invoiceData.config.taxRate) / 100,
              invoiceData.config.currency
            ),
      itemTotalWithTax:
        parseFloat(item.qty) > 0
          ? getFormattedAmount(
              item.unitCost * item.qty * (1 + invoiceData.config.taxRate / 100),
              invoiceData.config.currency
            )
          : getFormattedAmount(
              item.unitCost * (1 + invoiceData.config.taxRate / 100),
              invoiceData.config.currency
            ),
    });
  }

  const htmlWithReplacedKeys = format(template, {
    ...stringifiedStyles,
    ...strings,
    ...invoiceDataKeys,
    ...editorComponents,
    ...variablesObj,
    invoiceItems: itemsString,
  });

  return htmlWithReplacedKeys;
};

export const templatesList = [
  {
    name: "Neat",
    preview: neatImg,
    thumbnail: neatSmallImg,
  },
  {
    name: "Simple",
    preview: simpleImg,
    thumbnail: simpleSmallImg,
  },
  {
    name: "Modern",
    preview: modernImg,
    thumbnail: modernSmallImg,
  },
  {
    name: "Elegant",
    preview: elegantImg,
    thumbnail: elegantSmallImg,
  },
];
