import { useSelector } from "react-redux";

const useHasPaidSubscription = () => {
  const subscriptionType = useSelector(
    (state) => state.profile.subscriptionType
  );
  const subscriptionLoaded = useSelector(
    (state) => state.profile.subscriptionLoaded
  );

  return subscriptionLoaded && subscriptionType !== "free";
};

export default useHasPaidSubscription;
