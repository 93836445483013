import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { FaCheck } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { useAuthState } from "react-firebase-hooks/auth";
import Lottie from "react-lottie-player";

import lottieSuccessful from "./successful.json";

import {
  DangerButton,
  InfoBarPrimary,
  CheckoutSection,
  SecondaryButton,
  SecondaryHeading,
  InfoBar,
  SectionText,
  SectionInfo,
  DashboardSectionTitle,
} from "../../../../lib/components";
import { HOMEPAGE, PRICE, subscriptionStatus } from "../../../../lib/constants";
import featureFlags from "../../../../lib/featureFlags";
import getFirebase, { auth } from "../../../../firebase";
import {
  Button,
  Col,
  Row,
  FlexRow,
  Section,
  Separator,
} from "../../../../lib/components";
import Modal from "../../../../components/modal/Modal";

import visaLogo from "./logos/visa@2x.png";
import mastercardLogo from "./logos/mastercard@2x.png";
import americanLogo from "./logos/american-express@2x.png";
import dinersLogo from "./logos/diners.png";
import UnionPay_logoLogo from "./logos/UnionPay_logo.png";
import discoverLogo from "./logos/discover@2x.png";
import paypalLogo from "./logos/paypal.jpeg";
import { useDispatch, useSelector } from "react-redux";
import { formatDateString, getFormattedAmount } from "../../../../lib/utils";
import {
  LastTableCell,
  Table,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableWrapper,
} from "../../../editor/components";
import { useTranslation, Trans } from "react-i18next";
import coupons, { COUPON_NAMES } from "../../../../lib/coupons";
import { toast } from "react-toastify";
import { setCouponData } from "../../../../redux/checkoutSlice";
import { useHistory } from "react-router-dom";
import { PRODUCTS, VENDOR_ID } from "../../../../backend/config";

const logos = [
  visaLogo,
  mastercardLogo,
  americanLogo,
  dinersLogo,
  UnionPay_logoLogo,
  discoverLogo,
  paypalLogo,
];

export const Container = styled.div`
  padding: 10px;
`;

export const Centered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Paid = styled.div`
  color: #ffffff;
  background-color: #218c74;
  border-radius: 50%;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 12px;
`;

const NotPaid = styled(Paid)`
  background-color: #e74c3c;
`;

const Status = styled.div`
  color: #ffffff;
  background-color: #218c74;
  border-radius: 12px;
  display: inline-block;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  padding: 4px 12px;
  text-transform: lowercase;
`;

const OverdueStatus = styled(Status)`
  background-color: #e74c3c;
`;

const CancelledStatus = styled(Status)`
  background-color: #e74c3c;
  color: #fff;
`;

const ReferredInfo = styled.div`
  color: #ffffff;
  border-radius: 8px;
  padding: 8px;
  background-image: linear-gradient(to right, #6a11cb 0%, #2575fc 100%);
  font-size: 14px;
  text-align: center;
  top: 4px;
  left: 4px;
  right: 4px;
  z-index: 111111;
`;

const tabs = [
  {
    label: "subscription information",
  },
  // {
  //   label: "your invoices",
  // },
];

const FeaturesList = styled.ul`
  list-style-type: none;

  li {
    margin: 8px;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.dashboardFg};
  }
`;

const Price = styled.div`
  font-size: 32px;
  font-weight: bold;

  ${({ slashed }) =>
    slashed && `font-size: 16px; text-decoration: line-through;`}
  ${({ discounted, theme }) =>
    discounted && `color: ${theme.colors.discountedPrice};`}
`;

const TermsLink = styled.a`
  color: ${({ theme }) => theme.colors.link};
  font-weight: bold;
  text-decoration: none;
`;

const CardLogo = styled.img`
  height: 24px;
  border-radius: 4px;
  border: solid 1px ${({ theme }) => theme.colors.sectionBg};
`;

const SmallButtonText = styled.div`
  font-weight: 400;
  font-size: 12px;
  white-space: normal;
`;

const SubscriptionCardInfoText = styled.div`
  height: 46px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: bold;
`;

const SubscriptionCardInfoPrice = styled.span`
  color: ${({ isDiscounted, theme }) =>
    isDiscounted ? theme.colors.discountedPrice : theme.colors.price};
  padding: 0 4px;
`;

const SubscriptionCardInfoSlashedPrice = styled.span`
  color: ${({ theme }) => theme.colors.price};
  text-decoration: line-through;
  font-weight: 400;
  padding: 0 4px;
`;

const PriceExplanation = styled.li`
  margin: 8px;
`;

const Coupon = styled.div`
  margin-bottom: 16px;
  color: #ffffff;
  padding: 16px;
  text-align: center;
  background-image: linear-gradient(
    to top,
    #a7a6cb 0%,
    #8989ba 52%,
    #8989ba 100%
  );
`;

const AfterDiscountedPrice = styled.div`
  text-align: center;
  font-size: 13px;
  margin: 8px;
`;

const getInvoicePeriod = (invoice, language) => {
  return (
    formatDateString(invoice.data.paymentDate, language) +
    " - " +
    formatDateString(invoice.data.nextBillDate, language)
  );
};

const Subscription = () => {
  const subscriptionData = useSelector((state) => state.profile.subscription);
  const isReferred = useSelector((state) => !!state.profile.profile.referredBy);
  const referredBy = useSelector((state) => state.profile.profile.referredBy);
  const [user] = useAuthState(auth);
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const [selectedTab, setSelectedTab] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [showCancelSubscription, setShowCancelSubscription] = useState(false);
  const [isRenewing, setIsRenewing] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [price, setPrice] = useState(PRICE.PREMIUM.price + PRICE.PREMIUM.unit);
  const couponData = useSelector((state) => state.checkout.couponData);
  const nextPayment = useMemo(() => {
    if (!subscriptionData) {
      return null;
    }
    if (
      !subscriptionData.upcomingPayments ||
      !subscriptionData.upcomingPayments.length
    ) {
      return null;
    }

    const upcomingPayment = subscriptionData.upcomingPayments[0];

    return {
      amount: getFormattedAmount(
        upcomingPayment.amount,
        upcomingPayment.currency
      ),
      date: upcomingPayment.payout_date,
      originalAmount: PRICE.PREMIUM.formattedPrice,
      isPriceSlashed:
        parseFloat(upcomingPayment.amount) < parseFloat(PRICE.PREMIUM.price),
    };
  }, [subscriptionData]);

  const isCancelled = subscriptionData?.status === subscriptionStatus.deleted;

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (isReferred) {
      const cData = coupons[COUPON_NAMES.REFERRAL_DISCOUNT];
      dispatch(setCouponData(cData));
    }
  }, [isReferred]);

  useEffect(() => {
    return () => dispatch(setCouponData(null));
  }, []);

  const checkoutEventCallback = (e) => {
    if (e.event === "Checkout.Coupon.Remove") {
      dispatch(setCouponData(null));
      toast("Coupon removed.");
    }

    if (e.event === "Checkout.Coupon.Applied") {
      const data = e.eventData.coupon;
      if (!data) {
        return;
      }

      const couponCode = data.coupon_code;
      const cData = coupons[couponCode];
      if (!cData) {
        return;
      }

      toast("Coupon added! 🎉");

      dispatch(setCouponData(cData));
    }
  };

  useEffect(() => {
    Paddle.Setup({
      vendor: VENDOR_ID,
      eventCallback: checkoutEventCallback,
    });
  }, []);

  const manageSubscription = () => {
    window.open(subscriptionData.otherInfo.updateUrl, "_blank");
  };

  const renewSubscription = () => {
    setIsRenewing(true);
  };

  const goToCancellationPage = () => {
    window.open(subscriptionData.otherInfo.cancelUrl, "_self");
  };

  const goToContactPage = () => {
    window.open(`${HOMEPAGE}/contact`, "_blank");
  };

  const getSubscriptionStatus = useCallback(
    (status) => {
      if (!subscriptionData) {
        return;
      }
      switch (status.toLowerCase()) {
        case subscriptionStatus.active:
          return (
            <Section>
              <Status>{t("active_no_payment_overdue")}</Status>
            </Section>
          );
        case subscriptionStatus.deleted:
          return (
            <Section>
              <CancelledStatus>{t("cancelled")}</CancelledStatus>
              <Separator />
              <div>
                {t("you_can_still_use_subscription", {
                  dateUntil: formatDateString(
                    subscriptionData.cancellationDate,
                    language
                  ),
                })}
              </div>
            </Section>
          );
        case subscriptionStatus.past_due:
          return (
            <Section>
              <OverdueStatus>{t("payment_overdue")}</OverdueStatus>
              <div>
                {t("payment_overdue_info", {
                  manageButton: (
                    <Button
                      status="primary"
                      small
                      onClick={manageSubscription}
                      loading={isLoading}
                      disabled={isLoading}
                    >
                      {t("click_here")}
                    </Button>
                  ),
                })}
              </div>
            </Section>
          );
        default:
          return (
            <Container>
              <Status>{status}</Status>
            </Container>
          );
      }
    },
    [isLoading, subscriptionData]
  );

  const openCheckout = () => {
    setTermsAgreed(true);

    window.Paddle.Checkout.open({
      method: "inline",
      product: PRODUCTS.subscriptionId, // Replace with your Product or Plan ID
      email: user?.email,
      allowQuantity: false,
      disableLogout: true,
      frameTarget: "checkout-container", // The className of your checkout <div>
      frameInitialHeight: 416,
      frameStyle:
        "width:100%; min-width:320px; background-color: transparent; border: none;", // Please ensure the minimum width is kept at or above 286px with checkout padding disabled, or 312px with checkout padding enabled. See "General" section under "Branded Inline Checkout" below for more information on checkout padding.
      passthrough: JSON.stringify({
        userId: user.uid,
        referredBy: referredBy,
      }),
      locale: i18n.language,
      ...(isReferred ? { coupon: COUPON_NAMES.REFERRAL_DISCOUNT } : {}),
      successCallback: () => setShowSuccessModal(true),
    });
  };

  useEffect(() => {
    if (!showSuccessModal) {
      return;
    }

    setTimeout(() => window.location.reload(), 10000);
  }, [showSuccessModal]);

  if (!featureFlags.enableCardSubscription) {
    return <InfoBarPrimary>404 - page not found</InfoBarPrimary>;
  }

  if (!subscriptionData) {
    return (
      <>
        {isReferred && (
          <ReferredInfo>
            You were referred by a friend! A coupon with a 20% discount on your
            first subscription payment will be applied automatically.
          </ReferredInfo>
        )}
        <Separator size={20} />
        <FlexRow gap={16}>
          <Col flex={1.5}>
            <Section>
              <SecondaryHeading>{t("premium_features")}</SecondaryHeading>
              <FeaturesList>
                <li>{t("unlimited_invoices")}</li>
                <li>{t("multiple_professional_templates")}</li>
                <li>{t("export_to_PDF")}</li>
                <li>{t("branding_options")}</li>
                <li>{t("no_watermark")}</li>
                <li>{t("priority_support")}</li>
              </FeaturesList>
              <Section>
                <Centered>
                  <Price
                    slashed={couponData && couponData.discountedPrice !== null}
                  >
                    {t("price_per_month", { price: price })}
                  </Price>
                  {couponData && couponData.discountedPrice !== null && (
                    <Price discounted>{couponData.discountedPrice}</Price>
                  )}
                  {couponData && couponData.afterDiscountedPrice !== null && (
                    <AfterDiscountedPrice>
                      {couponData.afterDiscountedPrice}
                    </AfterDiscountedPrice>
                  )}
                  <div>{t("cancel_anytime")}</div>
                </Centered>
              </Section>
            </Section>
          </Col>
          <Col flex={2.5}>
            <CheckoutSection style={{ fontSize: 14 }}>
              {couponData && couponData.couponExplanation && (
                <Coupon>{couponData.couponExplanation}</Coupon>
              )}
              <SecondaryHeading>{t("payment_information")}</SecondaryHeading>
              <Separator size={10} />
              <Row gap={8}>
                {logos.map((l, k) => (
                  <CardLogo src={l} key={k} />
                ))}
              </Row>
              {!termsAgreed && (
                <>
                  <Separator size={10} />
                  <Trans
                    i18nKey={"checkout_agree_terms"}
                    components={{
                      termsLink: (
                        <TermsLink
                          href="https://www.visualinvoicer.com/terms-of-use"
                          target="_blank"
                        />
                      ),
                      refundLink: (
                        <TermsLink
                          href="https://www.visualinvoicer.com/refund-policy"
                          target="_blank"
                        >
                          {t("refund_policy")}
                        </TermsLink>
                      ),
                    }}
                  />

                  <Separator size={10} />
                </>
              )}

              <div className="checkout-container" />
              {/*<CardForm />*/}
              {!termsAgreed && (
                <>
                  <Separator />
                  <input
                    type="checkbox"
                    id="terms"
                    value={termsChecked}
                    onChange={(e) => setTermsChecked(e.target.checked)}
                  />
                  <label htmlFor="terms">
                    {t("read_and_agree_with_terms")}
                  </label>
                  <Separator size={32} />
                  <Centered>
                    <Button disabled={!termsChecked} onClick={openCheckout}>
                      <div>{t("proceed_to_checkout")}</div>
                      <SmallButtonText>
                        {t("this_will_just_open_checkout")}
                      </SmallButtonText>
                    </Button>
                  </Centered>
                </>
              )}
            </CheckoutSection>
          </Col>
        </FlexRow>

        <Modal
          title={t("successfully_subscribed_title")}
          isVisible={showSuccessModal}
          showOverlay
        >
          <Centered>
            <Lottie
              loop={false}
              animationData={lottieSuccessful}
              play
              style={{ width: 200, height: 200 }}
            />
          </Centered>
          <div>{t("successfully_subscribed")}</div>
          <Separator />
          <Centered>
            <strong>{t("page_will_refresh", { seconds: 10 })}</strong>
          </Centered>
        </Modal>
      </>
    );
  }

  return (
    <>
      <Section>
        <FlexRow gap={16} block>
          <Col flex={1}>
            <Section style={{ height: "100%" }}>
              <Centered
                style={{ height: "100%", justifyContent: "flex-start" }}
              >
                <DashboardSectionTitle>
                  {t("subscription_started_at")}
                </DashboardSectionTitle>
                <SubscriptionCardInfoText>
                  {formatDateString(
                    subscriptionData.otherInfo.createdAt,
                    language
                  )}
                </SubscriptionCardInfoText>
              </Centered>
            </Section>
          </Col>
          <Col flex={1}>
            <Section style={{ height: "100%" }}>
              <Centered
                style={{ height: "100%", justifyContent: "flex-start" }}
              >
                <DashboardSectionTitle>
                  {t("subscription_status")}
                </DashboardSectionTitle>
                {getSubscriptionStatus(subscriptionData.status)}
              </Centered>
            </Section>
          </Col>
          {nextPayment && (
            <Col flex={1}>
              <Section style={{ height: "100%" }}>
                <Centered style={{ height: "100%" }}>
                  <DashboardSectionTitle>
                    {t("next_payment")}
                  </DashboardSectionTitle>
                  <SubscriptionCardInfoText>
                    {nextPayment.isPriceSlashed && (
                      <SubscriptionCardInfoSlashedPrice>
                        {nextPayment.originalAmount}
                      </SubscriptionCardInfoSlashedPrice>
                    )}
                    <SubscriptionCardInfoPrice
                      isDiscounted={nextPayment.isPriceSlashed}
                    >
                      {nextPayment.amount}
                    </SubscriptionCardInfoPrice>
                  </SubscriptionCardInfoText>
                  {nextPayment.isPriceSlashed && (
                    <SectionInfo>all available discounts applied.</SectionInfo>
                  )}
                  <SectionInfo>
                    payment will be processed on{" "}
                    {formatDateString(nextPayment.date)}
                  </SectionInfo>
                </Centered>
              </Section>
            </Col>
          )}
        </FlexRow>

        {isCancelled && (
          <>
            <Separator size={16} />
            <Row gap={16} block>
              <Col flex={1}>
                <Section style={{ height: "100%" }}>
                  <Centered style={{ height: "100%" }}>
                    <h2>{t("subscription_will_be_cancelled_on")}</h2>
                    {formatDateString(
                      subscriptionData.cancellationDate,
                      language
                    )}
                  </Centered>
                </Section>
              </Col>
            </Row>
          </>
        )}
      </Section>
      {featureFlags.enableReferrals && (
        <>
          <Separator size={32} />
          <Section>
            <SecondaryHeading>{t("your_discounts")}</SecondaryHeading>
            <Separator size={16} />
            {(subscriptionData.modifiers || []).length > 0 && (
              <>
                <TableWrapper>
                  <Table>
                    <thead>
                      <TableHeader>
                        <TableHeaderCell width="10%" />
                        <TableHeaderCell width="40%">
                          {t("amount")}
                        </TableHeaderCell>
                        <TableHeaderCell width="50%">
                          {t("description")}
                        </TableHeaderCell>
                      </TableHeader>
                    </thead>
                    <tbody>
                      {(subscriptionData.modifiers || []).map((i, k) => (
                        <TableRow key={i.id}>
                          <TableCell width="10%">{k + 1}</TableCell>
                          <TableCell width="40%">
                            {getFormattedAmount(i.amount, i.currency)}
                          </TableCell>
                          <TableCell width="50%">
                            {i.description || (i.amount < 0 ? "Credit" : "")}
                          </TableCell>
                        </TableRow>
                      ))}
                    </tbody>
                  </Table>
                </TableWrapper>
                <Separator size={16} />
                <SectionInfo>
                  your discounts are applied automatically, only once, on your
                  next payment.
                </SectionInfo>
              </>
            )}

            {(subscriptionData.modifiers || []).length === 0 && (
              <Centered>
                <SectionText>
                  You do not have any discounts at the moment. Start getting
                  subscription discounts by referring your friends.
                </SectionText>
                <Separator />
                <Button onClick={() => history.push("/home/refer")}>
                  send a referral
                </Button>
              </Centered>
            )}
          </Section>
        </>
      )}

      <Separator size={32} />
      <Section>
        <SecondaryHeading>{t("your_receipts")}</SecondaryHeading>
        <Separator size={16} />
        <TableWrapper>
          <Table>
            <thead>
              <TableHeader>
                <TableHeaderCell width="10%">{t("invoice_id")}</TableHeaderCell>
                <TableHeaderCell width="40%">{t("period")}</TableHeaderCell>
                <TableHeaderCell width="30%">{t("created_at")}</TableHeaderCell>
                <TableHeaderCell width="10%">{t("paid")}</TableHeaderCell>
                <TableHeaderCell width="10%" />
              </TableHeader>
            </thead>
            <tbody>
              {(subscriptionData.invoices || []).map((i, k) => (
                <TableRow key={i.id}>
                  <TableCell width="10%">{k + 1}</TableCell>
                  <TableCell width="40%">
                    {getInvoicePeriod(i, language)}
                  </TableCell>
                  <TableCell width="30%">
                    {formatDateString(i.createdAt, language)}
                  </TableCell>
                  <TableCell width="10%">
                    {i.paid ? (
                      <Paid>
                        <FaCheck />
                      </Paid>
                    ) : (
                      <NotPaid>
                        <ImCross />
                      </NotPaid>
                    )}
                  </TableCell>
                  <LastTableCell width="10%">
                    <a href={i.invoiceUrl} target="_blank">
                      <SecondaryButton small>{t("preview")}</SecondaryButton>
                    </a>
                  </LastTableCell>
                </TableRow>
              ))}
            </tbody>
          </Table>
        </TableWrapper>

        {subscriptionData.status !== subscriptionStatus.deleted && (
          <>
            <Separator size={32} />
            <Centered>
              <Row gap={16}>
                <Button onClick={manageSubscription}>
                  {t("update_billing_information")}
                </Button>
                <SecondaryButton
                  onClick={() => setShowCancelSubscription(true)}
                >
                  {t("cancel_subscription")}
                </SecondaryButton>
              </Row>
            </Centered>
          </>
        )}

        {subscriptionData.status === subscriptionStatus.deleted && (
          <>
            <Separator size={32} />
            <Centered>
              <Row gap={16}>
                <Button status="info" onClick={renewSubscription}>
                  {t("renew_subscription")}
                </Button>
              </Row>
            </Centered>
          </>
        )}
      </Section>
      <Modal
        title={t("cancel_subscription_title")}
        isVisible={showCancelSubscription}
        actionLabel={t("yes_go_to_cancellation_page")}
        cancelLabel={t("i_changed_my_mind")}
        showOverlay
        danger
        onConfirm={() => goToCancellationPage()}
        onCancel={() => setShowCancelSubscription(false)}
      >
        <Container>
          {t("confirm_cancellation_you_will_lose_access")}
          <ul>
            <li>{t("unlimited_invoices")}</li>
            <li>{t("multiple_professional_templates")}</li>
            <li>{t("export_to_PDF")}</li>
            <li>{t("branding_options")}</li>
            <li>{t("no_watermark")}</li>
            <li>{t("priority_support")}</li>
          </ul>
        </Container>
      </Modal>

      <Modal
        title={t("renew_subscription")}
        isVisible={isRenewing}
        showOverlay
        showCancelButton
        onCancel={() => setIsRenewing(false)}
        onConfirm={goToContactPage}
        confirmLabel={t("go_to_contact_page")}
      >
        <Container>
          {t("get_in_touch_to_renew", {
            contactUs: <strong>contact us</strong>,
          })}
        </Container>
      </Modal>
    </>
  );
};

export default Subscription;
