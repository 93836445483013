import React, { useContext, useMemo, useState } from "react";

const InvoiceCtx = React.createContext();

export function InvoiceContext({ children }) {
  const [invoice, setInvoice] = useState(false);
  const ctx = useMemo(
    () => ({ invoice: invoice, setInvoice: setInvoice }),
    [invoice]
  );

  return <InvoiceCtx.Provider value={ctx}>{children}</InvoiceCtx.Provider>;
}

export function useInvoiceOutlet() {
  const invoiceCtx = useContext(InvoiceCtx);
  return {
    invoice: invoiceCtx.invoice,
    setInvoice: invoiceCtx.setInvoice,
  };
}
