import { createSlice } from "@reduxjs/toolkit";
import { subscriptionStatus } from "../lib/constants";

const initialState = {
  profile: undefined,
  subscription: undefined,
  subscriptionType: "free",
  subscriptionLoaded: false,
  invoicesLastMonth: -1,
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
    clearProfile: (state) => {
      state.profile = undefined;
      state.subscription = undefined;
      state.subscriptionType = "free";
    },
    updateProfileProp: (state, action) => {
      if (state.profile) {
        state.profile[action.payload.key] = action.payload.value;
      }
    },
    setSubscription: (state, action) => {
      state.subscriptionLoaded = true;
      if (action.payload) {
        state.subscription = action.payload;
        state.subscriptionType = action.payload.isValid ? "premium" : "free";
      } else {
        state.subscriptionType = "free";
      }
    },
    setInvoicesLastMonth: (state, action) => {
      state.invoicesLastMonth = action.payload;
    },
    createFlag: (state, action) => {
      try {
        state.profile.flags[action.flagName] = action.flagValue || true;
      } catch (e) {}
    },
  },
});

export const {
  setProfile,
  clearProfile,
  updateProfileProp,
  setSubscription,
  setInvoicesLastMonth,
  createFlag,
} = profileSlice.actions;

export const profileSelectors = {
  getFlags: (state) => state.profile.profile?.flags,
};

export default profileSlice.reducer;
