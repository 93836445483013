import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { signOut as firebaseSignOut } from "firebase/auth";
import {
  Button,
  CenteredCol,
  CenteredRow,
  Col,
  DangerButton,
  FlexCol,
  Initials,
  RoundButton,
  Row,
  SecondaryButton,
  Section,
  Separator,
  TextButton,
  ThemedButton,
} from "../../lib/components";
import "./Navbar.scss";
import Modal from "../modal/Modal";
import Joyride from "react-joyride";
import getFirebase, { auth } from "../../firebase";
import { Link, useHistory, useLocation } from "react-router-dom";
import { getInitials } from "../../lib/utils";
import { toast } from "react-toastify";
import { bUpdateProfile } from "../../backend/profile";

import logoImg from "../../assets/logo.png";

import roFlag from "../../assets/ro.png";
import ukFlag from "../../assets/uk.png";
import deFlag from "../../assets/de.png";
import frFlag from "../../assets/fr.png";
import jaFlag from "../../assets/ja.png";
import { useDispatch, useSelector } from "react-redux";
import { clearProfile, updateProfileProp } from "../../redux/profileSlice";
import { setTheme } from "../../redux/settingsSlice";
import { FiMenu, FiMoon, FiSun } from "react-icons/fi";
import Spinner from "../primitives/Spinner";
import { HideBelowMedium, ShowBelowMedium } from "../primitives/Utils";
import { useMediaQuery } from "../../lib/hooks";
import { media, mediaQuery } from "../mixins";
import { Centered } from "../../screens/home/screens/subscription/Subscription";
import featureFlags from "../../lib/featureFlags";
import { bGetSignOut } from "../../backend/auth";

const langAssets = {
  ro: roFlag,
  en: ukFlag,
  de: deFlag,
  fr: frFlag,
  ja: jaFlag,
};

const Nav = styled.div`
  position: fixed;
  z-index: 191;
  top: 0;
  left: 0;
  right: 0;
  height: 56px;
  background-color: rgb(20, 20, 50); // #2f3542;
  padding: 8px 16px;
`;

const LogoContainer = styled.button`
  height: 40px;
  background-color: rgba(0, 0, 0, 0);
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 0;
  border-radius: 4px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;

const Logo = styled.img`
  height: 14px;
`;

const LanguageContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 44px;
  top: 2px;

  :hover {
    .dropdown {
      display: flex;
      z-index: 1;
    }
  }
`;

const LanguageImg = styled.img`
  width: 28px;
  height: 28px;
  margin-right: 16px;
  cursor: pointer;

  :hover {
    filter: brightness(0.7) !important;
  }
`;

const LanguageDropdown = styled.div`
  position: absolute;
  top: -8px;
  left: -8px;
  padding: 8px 8px 0 8px;
  border-radius: 16px;
  background-color: #f5f6fa;
  display: none;
  flex-direction: column;
  z-index: 1;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);

  img {
    margin-bottom: 8px;
    margin-right: 0;
  }
`;

const MobileMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const LanguagePicker = styled.div`
  background-color: ${({ theme }) => theme.colors.themedButton};
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 28px;
`;

const EditedInvoiceTitleContainer = styled.div`
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  max-width: 200px;
  overflow: hidden;
  user-select: none;

  ${media.belowLarge} {
    display: none;
  }
`;

const EditedInvoiceTitle = styled.div`
  color: ${({ untitled }) => (untitled ? "#aaaaaa" : "#ffffff")};
  font-size: 17px;
`;

const EditedInvoiceTouched = styled.div`
  color: #ffffff;
  font-weight: bold;
  font-size: 14px;
`;

const noTabStyle = { tabIndex: "-1" };

const Navbar = ({ user, onLogin }) => {
  const location = useLocation();
  const [showLogOutModal, setShowLogOutModal] = useState(false);
  const [runTour, setRunTour] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const { t, i18n } = useTranslation();
  const profile = useSelector((state) => state.profile.profile);
  const theme = useSelector((state) => state.settings.theme);
  const editedInvoice = useSelector((state) => state.editor);
  const dispatch = useDispatch();

  const isMobile = useMediaQuery(mediaQuery.belowMedium);
  useEffect(() => {
    setShowMobileMenu(false);
  }, [isMobile]);

  const changeLanguage = (lang) => {
    if (lang === i18n.language) {
      return;
    }

    i18n.changeLanguage(lang).then(() => {
      toast(t("language_was_changed"));
    });

    bUpdateProfile({ language: lang }).then(() => {
      dispatch(updateProfileProp({ key: "language", value: lang }));
    });
  };

  const tourSteps = [
    {
      content: (
        <>
          <h2>{t("glad_to_have_you")}</h2>
          <div>{t("quick_tour")}</div>
        </>
      ),
      locale: { next: "Get started" },
      placement: "center",
      target: "body",
    },
    {
      target: "#invoice-setup",
      content: t("invoice_setup_step"),
    },
    {
      target: "#edit-items",
      content: t("edit_items_step"),
    },
    {
      target: "#select-template",
      content: t("select_template_step"),
    },
    {
      target: "#export-invoice",
      content: t("export_invoice_step"),
    },
    {
      target: "#save-invoice",
      content: t("save_invoice_step"),
    },
    {
      target: "#reset-invoice",
      content: t("reset_invoice_step"),
    },
  ];

  return (
    <>
      <Joyride
        callback={(props) => {
          if (props.action === "close" || props.action === "reset") {
            setRunTour(false);
          }
        }}
        steps={tourSteps}
        run={runTour}
        styles={{
          buttonNext: {
            fontSize: "14px",
          },
          buttonBack: {
            fontSize: "14px",
          },
          options: {
            arrowColor: "#ffffff",
            backgroundColor: "#ffffff",
            overlayColor: "rgba(0, 0, 0, 0.2)",
            primaryColor: "#30336b",
            textColor: "#30336b",
            textSize: "12px",
            zIndex: 1000,
          },
        }}
        disableScrolling={true}
        continuous={true}
        locale={{
          back: t("back"),
          close: t("close_tour"),
          last: t("Done"),
          next: t("next_step"),
        }}
      />

      <Nav>
        <CenteredRow>
          <CenteredCol>
            <LogoContainer>
              <Link to={"/home"}>
                <Logo src={logoImg} />
              </Link>
            </LogoContainer>
          </CenteredCol>

          <Col />
          {location.pathname === "/editor" && (
            <EditedInvoiceTitleContainer>
              {editedInvoice.invoiceName && (
                <EditedInvoiceTitle>
                  {editedInvoice.invoiceName}
                </EditedInvoiceTitle>
              )}
              {!editedInvoice.invoiceName && (
                <EditedInvoiceTitle untitled>
                  {t("untitled_invoice")}
                </EditedInvoiceTitle>
              )}
              {editedInvoice.isInvoiceEdited && (
                <EditedInvoiceTouched>({t("edited")})</EditedInvoiceTouched>
              )}
            </EditedInvoiceTitleContainer>
          )}

          <ShowBelowMedium>
            <TextButton onClick={() => setShowMobileMenu(!showMobileMenu)}>
              <FiMenu />
            </TextButton>
          </ShowBelowMedium>

          <HideBelowMedium>
            <CenteredCol>
              {user && (
                <Link to={"home"} {...noTabStyle}>
                  <TextButton style={{ textTransform: "lowercase" }}>
                    {t("home")}
                  </TextButton>
                </Link>
              )}
              {location.pathname === "/editor" && (
                <TextButton onClick={() => setRunTour(true)}>
                  {t("take_a_tour")}
                </TextButton>
              )}
              {user && (
                <TextButton
                  onClick={() => setShowLogOutModal(true)}
                  style={{ marginRight: 16 }}
                >
                  {t("log_out")}
                </TextButton>
              )}

              {location.pathname !== "/editor" && (
                <>
                  {theme === "light" ? (
                    <RoundButton
                      onClick={() => dispatch(setTheme("dark"))}
                      style={{ marginRight: 16 }}
                    >
                      <FiMoon />
                    </RoundButton>
                  ) : (
                    <RoundButton
                      onClick={() => dispatch(setTheme("light"))}
                      style={{ marginRight: 16 }}
                    >
                      <FiSun />
                    </RoundButton>
                  )}
                </>
              )}

              {featureFlags.enableLanguageDropdown && (
                <LanguageContainer>
                  <LanguageImg src={langAssets[i18n.language]} />
                  <LanguageDropdown className="dropdown">
                    {Object.keys(langAssets).map((lang) => (
                      <LanguageImg
                        key={"lang-button-" + lang}
                        src={langAssets[lang]}
                        onClick={() => changeLanguage(lang)}
                      />
                    ))}
                  </LanguageDropdown>
                </LanguageContainer>
              )}

              {user ? (
                <Initials>{getInitials(user?.displayName)}</Initials>
              ) : (
                <DangerButton onClick={() => onLogin(true)}>
                  log in
                </DangerButton>
              )}
            </CenteredCol>
          </HideBelowMedium>
        </CenteredRow>
      </Nav>

      <Modal
        isVisible={showMobileMenu}
        onClose={() => setShowMobileMenu(false)}
        danger
        hideFooter
      >
        <MobileMenuWrapper>
          {user && (
            <Link to={"home"} {...noTabStyle}>
              <ThemedButton
                block
                style={{ textTransform: "lowercase" }}
                onClick={() => {
                  setShowMobileMenu(false);
                }}
              >
                {t("home")}
              </ThemedButton>
            </Link>
          )}
          {location.pathname === "/editor" && (
            <ThemedButton
              onClick={() => {
                setRunTour(true);
                setShowMobileMenu(false);
              }}
            >
              {t("take_a_tour")}
            </ThemedButton>
          )}
          {user && (
            <ThemedButton
              onClick={() => {
                setShowMobileMenu(false);
                setShowLogOutModal(true);
              }}
            >
              {t("log_out")}
            </ThemedButton>
          )}

          <Separator />
          {!featureFlags.enableLanguageDropdown && (
            <Centered>
              {theme === "light" ? (
                <RoundButton onClick={() => dispatch(setTheme("dark"))}>
                  <FiMoon />
                </RoundButton>
              ) : (
                <RoundButton onClick={() => dispatch(setTheme("light"))}>
                  <FiSun />
                </RoundButton>
              )}
            </Centered>
          )}
          {featureFlags.enableLanguageDropdown && (
            <Row style={{ justifyContent: "space-between" }}>
              <FlexCol>
                {theme === "light" ? (
                  <RoundButton onClick={() => dispatch(setTheme("dark"))}>
                    <FiMoon />
                  </RoundButton>
                ) : (
                  <RoundButton onClick={() => dispatch(setTheme("light"))}>
                    <FiSun />
                  </RoundButton>
                )}
              </FlexCol>
              <FlexCol>
                <Centered>
                  <LanguagePicker>
                    {Object.keys(langAssets).map((lang) => (
                      <LanguageImg
                        key={"lang-button-" + lang}
                        src={langAssets[lang]}
                        onClick={() => changeLanguage(lang)}
                      />
                    ))}
                  </LanguagePicker>
                </Centered>
              </FlexCol>
            </Row>
          )}
        </MobileMenuWrapper>
      </Modal>

      <Modal
        isVisible={showLogOutModal}
        title={t("log_out_title")}
        content={t("log_out_confirm")}
        onConfirm={() => {
          bGetSignOut()
            .then(() => {
              firebaseSignOut(auth).then((a) => {
                setShowLogOutModal(false);
                dispatch(clearProfile());
                setTimeout(() => {
                  onLogin();
                }, 0);
              });
            })
            .catch(() => {
              firebaseSignOut(auth).then((a) => {
                setShowLogOutModal(false);
                dispatch(clearProfile());
                setTimeout(() => {
                  onLogin();
                }, 0);
              });
            });
        }}
        onCancel={() => setShowLogOutModal(false)}
        danger
      />
    </>
  );
};

export default Navbar;
