export const COUPON_NAMES = {
  REFERRAL_DISCOUNT: "REFERRALDISCOUNT111",
  FIRST_MONTH_FREE: "GETVISUAL",
  PRODUCTHUNTER: "PRODUCTHUNTER",
  BETALIST: "BETALIST",
  VISUALFEEDBACK: "VISUALFEEDBACK",
};

const coupons = {
  [COUPON_NAMES.FIRST_MONTH_FREE]: {
    newPrice: 0,
    discount: 100,
    discountUnit: "percentage",
    discountedPrice: "$0.00 first month",
    afterDiscountedPrice: "$9.90 / month afterwards",
    explanation:
      "You have received a free month of Premium! 🎉 Unless you cancel in the meantime, starting next month you will be charged the regular monthly subscription price.",
  },
  [COUPON_NAMES.PRODUCTHUNTER]: {
    newPrice: 0,
    discount: 100,
    discountUnit: "percentage",
    discountedPrice: "$0.00 first month",
    afterDiscountedPrice: "$9.90 / month afterwards",
    explanation:
      "You have received a free month of Premium! 🎉 Unless you cancel in the meantime, starting next month you will be charged the regular monthly subscription price.",
  },
  [COUPON_NAMES.BETALIST]: {
    newPrice: 0,
    discount: 100,
    discountUnit: "percentage",
    discountedPrice: "$0.00 first month",
    afterDiscountedPrice: "$9.90 / month afterwards",
    explanation:
      "You have received a free month of Premium! 🎉 Unless you cancel in the meantime, starting next month you will be charged the regular monthly subscription price.",
  },
  [COUPON_NAMES.VISUALFEEDBACK]: {
    newPrice: 4.95,
    discount: 50,
    discountUnit: "percentage",
    discountedPrice: "$4.95 first month",
    afterDiscountedPrice: "$9.90 / month afterwards",
    explanation:
      "You have received a 50% discount on your free month of Premium! 🎉 Unless you cancel in the meantime, starting next month you will be charged the regular monthly subscription price.",
  },
  [COUPON_NAMES.REFERRAL_DISCOUNT]: {
    newPrice: 7.92,
    discount: 20,
    discountUnit: "percentage",
    discountedPrice: "$7.92 first month",
    afterDiscountedPrice: "$9.90 / month afterwards",
    explanation:
      "You have received a 20% discount for your first month of Premium! 🎉 Unless you cancel in the meantime, starting next month you will be charged the regular monthly subscription price.",
  },
};

export default coupons;
