import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import deTranslations from "./locales/de/translations.json";
import frTranslations from "./locales/fr/translations.json";
import enTranslations from "./locales/en/translations.json";
import jaTranslations from "./locales/ja/translations.json";
import roTranslations from "./locales/ro/translations.json";

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  lng: "en",
  resources: {
    de: {
      translations: deTranslations,
    },
    fr: {
      translations: frTranslations,
    },
    en: {
      translations: enTranslations,
    },
    ja: {
      translations: jaTranslations,
    },
    ro: {
      translations: roTranslations,
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
});

i18n.languages = ["en", "ro"];

export default i18n;
