import axios from "axios";
import { AUTH_SERVER_URL } from "./config";

export const bGetCustomers = () => {
  return new Promise((resolve, reject) => {
    axios.get(`${AUTH_SERVER_URL}/customer`, { withCredentials: true }).then(
      (response) => {
        return resolve(response.data);
      },
      (error) => {
        reject(error);
      }
    );
  });
};
